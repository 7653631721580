import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  certifications: [],
  name: "",
  tagline: "",
  email: "",
  number: "",
  location: "",
  gender: "",
  aboutYourSelf: "",
  getCover: "",
  getProfile: "",
  getVideo: "",
  coverPhotoPath: null,
  profilePhotoPath: null,
  videoPath: null,
  selectedDistancesRun: [],
  isRun: "",
  selectedDistancesSwim: [],
  isSiwm: "",
  selectedDistancesCycle: [],
  isCycle: "",
  selectedDistancesTriathlon: [],
  isTri: "",
  coaching_since: "",
  athlete_trained: "",
  languages: [],
  athlete_level: [],
};

const setCoachFormSlice = createSlice({
  name: "SetCoachFormData",
  initialState,
  reducers: {
    updateForm: (state, action) => {
      return { ...state, ...action.payload };
    },

    updateCertificates: (state, action) => {
      state.certifications = action.payload;
    },
  },
});

export const { updateForm, updateCertificates } = setCoachFormSlice.actions;

export default setCoachFormSlice.reducer;
