import React, { useEffect, useState } from "react";
import ErrorPopup from "components/popups/ErrorPopup";
import { getlocalStorageData } from "hooks/basic";
import { useDispatch } from "react-redux";
import { addViewAccountData } from "state/ViewAccountState";
import { updateForm } from "state/SetCoachFormData";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function AccountAbout(props) {
  const { isEdit, setEdit, data, type } = props;

  const dispatch = useDispatch();
  // const data = useSelector((state) => state.viewAccount);
  const addDataTo = type === "account" ? addViewAccountData : updateForm;

  const localStorageData = getlocalStorageData();

  const [dob, setDob] = useState("");
  // const [about, setAbout] = useState(addDataTo.about);
  const [aboutYourSelf, setAboutYourSelf] = useState(data.about || "");

  //all  use states
  const [errorPopup, setErrorPopup] = useState(false); // error popup
  const [errorHeader, setErrorHeader] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  // covert M,F,T into male, female, Trans
  // localStorage.setItem("mobile", number);

  const handleChange = (field) => (e) => {
    const value = e.target.value;
    // Update only if the value has changed
    if (data[field] !== value) {
      dispatch(addDataTo({ [field]: value }));
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (data) {
      if (data.gender === "M" || data.gender === "MALE") {
        dispatch(addDataTo({ gender: "Male" }));
      } else if (data.gender === "F" || data.gender === "FEMALE") {
        dispatch(addDataTo({ gender: "Female" }));
      } else if (data.gender === "T" || data.gender === "TRANS") {
        dispatch(addDataTo({ gender: "Trans" }));
      }

      if (data.dob) {
        const dateParts = data.dob.split("-");
        if (dateParts.length === 3) {
          const [d, m, y] = dateParts;
          const new_date = `${d}-${m}-${y}`;
          setDob(new_date);
        } else {
          console.error("Invalid date format");
        }
      }
    }
  }, [data]);

  // date validation => when date is send to backend we are changing the format to dd-mm-yyyy
  function handleDate(e) {
    let date = e.target.value;
    const d = date.split("-")[2];
    const m = date.split("-")[1];
    const y = date.split("-")[0];
    let newDate = `${d}-${m}-${y}`;
    // setDob(newDate);

    dispatch(addDataTo({ dob: newDate }));
  }

  // validation for date input field(can select current date - 18)
  const getMaxDate = () => {
    const today = new Date();
    const maxDate = new Date(today.setFullYear(today.getFullYear() - 18));
    return maxDate.toISOString().split("T")[0];
  };

  // NUMBER VALIDATION
  const NumberValidation = (e) => {
    const input = e.target.value;
    // Ensure input contains only digits and limit to 10 characters
    if (/^\d{0,10}$/.test(input)) {
      dispatch(addDataTo({ mobile: input }));
    }
  };

  // function handleAbout(e, delta, source, editor) {
  //   // setAbout(e);
  //   dispatch(addDataTo({ about: e }));

  //   // Use unprivileged editor methods
  //   const plainText = editor.getText(); // Get plain text (without HTML tags)
  //   const html = editor.getHTML(); // Get full HTML content
  //   const length = editor.getLength(); // Get character count (excluding HTML tags)
  // }

  useEffect(() => {
    if (data.about) {
      setAboutYourSelf(data.about);
    }
  }, [data.about]);

  function handleAbout(e, delta, source, editor) {
    const content = editor.getHTML();
    setAboutYourSelf(content);
    dispatch(addDataTo({ about: content }));
  }

  return (
    <>
      {errorPopup && (
        <>
          <div className="blur"></div>
          <ErrorPopup
            message={{
              heading: errorHeader,
              message: errorMessage,
            }}
            setErrorPopup={setErrorPopup}
          />
        </>
      )}

      <div className=" account_about_box mt-8">
        {/* <div className="profile_box_heading_div">
          <h2></h2> */}

        {/* </div> */}
        <div
          className={`${
            isEdit
              ? " input_info_box_coach input_info_box flex-wrap grid grid-cols-2 lg:grid lg:grid-cols-1"
              : "input_info_box_profile input_info_box flex-wrap grid grid-cols-2"
          }`}
        >
          <div className="input_lable_div  ">
            <label className="lable_tag ">Name</label>
            {isEdit ? (
              <input
                type="text"
                value={data.name || ""}
                onChange={handleChange("name")}
              />
            ) : (
              <p className="lable_value ">{data.name}</p>
            )}
          </div>

          <div className="input_lable_div ">
            <label className="lable_tag">Tagline</label>
            {isEdit ? (
              <input
                type="text"
                value={data.tagline || ""}
                onChange={handleChange("tagline")}
              />
            ) : (
              <p className=" lable_value">
                {data.tagline === undefined ? (
                  <span>&ndash;</span>
                ) : (
                  `${data.tagline}`
                )}{" "}
              </p>
            )}
          </div>

          {/* </div> */}
          {/* <div className="input_info_box_profile input_info_box"> */}
          <div className="input_lable_div ">
            <label className="lable_tag">Phone</label>
            {isEdit ? (
              <input
                type="number"
                pattern="\d{10}"
                maxLength={10}
                value={data.mobile || ""}
                onChange={(e) => {
                  NumberValidation(e);
                  handleChange("mobile");
                }}
              />
            ) : (
              <p className="lable_value ">
                {data.mobile === undefined ? (
                  <span>&ndash;</span>
                ) : (
                  `${data.mobile}`
                )}
              </p>
            )}
          </div>

          <div className="input_lable_div ">
            <label className="lable_tag">Email</label>
            {isEdit ? (
              <input
                type="email"
                disabled={true}
                value={data.email || ""}
                onChange={handleChange("email")}
              />
            ) : (
              <p className="lable_value ">
                {data.location === undefined ? (
                  <span>&ndash;</span>
                ) : (
                  `${data.email}`
                )}
              </p>
            )}
            {/* {isEdit ? (
              <p className="lable_value text-[var(--blue)]">{data.email}</p>
            ) : (
              <p className="lable_value ">
                {data.email === undefined ? (
                  <span>&ndash;</span>
                ) : (
                  `${data.email}`
                )}
              </p>
            )} */}
          </div>
          <div className="input_lable_div ">
            <label className="lable_tag">Location</label>
            {isEdit ? (
              <input
                type="text"
                value={data.location || ""}
                onChange={handleChange("location")}
              />
            ) : (
              <p className="lable_value ">
                {data.location === undefined ? (
                  <span>&ndash;</span>
                ) : (
                  `${data.location}`
                )}
              </p>
            )}
          </div>

          {type === "account" && (
            <div className="input_lable_div ">
              <label className="lable_tag">Birth date</label>

              {isEdit ? (
                <>
                  <input type="date" onChange={handleDate} max={getMaxDate()} />
                </>
              ) : (
                <p className="lable_value ">
                  {data.dob === undefined ? (
                    <span>&ndash;</span>
                  ) : (
                    `${data.dob}`
                  )}
                </p>
              )}
            </div>
          )}

          <div className="input_lable_div ">
            <label className="lable_tag">Athlete Count</label>
            {isEdit ? (
              <input
                type="text"
                value={data.athlete_trained || ""}
                onChange={handleChange("athlete_trained")}
              />
            ) : (
              <p className="lable_value ">
                {data.athlete_trained === undefined ? (
                  <span>&ndash;</span>
                ) : (
                  `${data.athlete_trained}`
                )}
              </p>
            )}
          </div>
          <div className="input_lable_div ">
            <label className="lable_tag">Language</label>
            {isEdit ? (
              <input
                type="text"
                value={data.languages || ""}
                onChange={handleChange("languages")}
              />
            ) : (
              <p className="lable_value ">
                {data.languages === undefined ? (
                  <span>&ndash;</span>
                ) : (
                  `${data.languages}`
                )}
              </p>
            )}
          </div>

          {type === "account" && (
            <div className="input_lable_div col-span-2">
              <label className="lable_tag">Gender</label>
              {isEdit ? (
                <div className=" mydict_viewAccount_gender">
                  <div className="flex flex-wrap  justify-start">
                    <label>
                      <input
                        type="radio"
                        name="radio"
                        value={data.gender}
                        onChange={() =>
                          handleChange("gender")({ target: { value: "Male" } })
                        }
                      />
                      <span
                        className={data.gender === "Male" ? "genderColors" : ""}
                      >
                        Male
                      </span>
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="radio"
                        value={data.gender}
                        onChange={() =>
                          handleChange("gender")({
                            target: { value: "Female" },
                          })
                        }
                      />
                      <span
                        className={
                          data.gender === "Female" ? "genderColors" : ""
                        }
                      >
                        Female
                      </span>
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="radio"
                        value={data.gender}
                        onChange={() =>
                          handleChange("gender")({ target: { value: "Trans" } })
                        }
                      />
                      <span
                        className={
                          data.gender === "Trans" ? "genderColors" : ""
                        }
                      >
                        Trans
                      </span>
                    </label>
                  </div>
                </div>
              ) : (
                <p className="lable_value ">
                  {data.gender === undefined ? (
                    <span>&ndash;</span>
                  ) : (
                    `${data.gender}`
                  )}
                </p>
              )}
            </div>
          )}
          <div className="input_lable_div about_box  col-span-2">
            <label className="lable_tag z-20">About Yourself</label>
            {isEdit ? (
              <>
                <ReactQuill
                  theme="snow"
                  value={aboutYourSelf}
                  onChange={handleAbout}
                  className="!w-[90%]"
                />
              </>
            ) : (
              <div
                className="max-h-[9rem] text-[1rem] overflow-y-auto ql-editor  text-[var(--light-blue)]"
                dangerouslySetInnerHTML={{ __html: aboutYourSelf }}
              ></div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default AccountAbout;
