import React, { useState, useEffect, useContext } from "react";
import Bell from "assets/icons/notifications.svg";
import { getFormData } from "api/CoachFormController";
import { API_URL_CONSTANTS } from "utility/constants/apiUrlConstants";
import LogoutIcon from "assets/icons/logoutIcon2.png";
import { Link } from "react-router-dom";
import { logout } from "api/Signin";
import Notification from "components/profileBox/Notification";
import star from "assets/icons/Star.png";
import { getlocalStorageData } from "hooks/basic";
import { AppProps } from "utility/constants/useContext";
import { useSelector, useDispatch } from "react-redux";
import { CoachFormData } from "state/CoachFormData";
import PhoneNotification from "./PhoneNotification";
import bells from "assets/img/bell-ring.png";
import { getApis } from "hooks/api";
import { clearLocalStorage } from "hooks/basic";
import { getCoachingPlans } from "api/CoachingPlansController";

function ProfileBox(props) {
  const { setDeletePopup } = props;
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.viewAccount);
  const [isCoachingPlan, setCoachingPlan] = useState();
  const [isProfileOpen, setProfileOpen] = useState(false);
  const [profileImgSrc, setProfileImgSrc] = useState("");
  const [notificationBox, setNotificationBox] = useState(false);
  const [isViewAccount, setViewAccount] = useState(false);
  const [bell, setBell] = useState(false);

  const AppData = useContext(AppProps);

  const localStorageData = getlocalStorageData();

  // profile img
  useEffect(() => {
    const fetchData = async () => {
      try {
        // const res = await getFormData();
        // dispatch(CoachFormData({ getCoachFromData: res.data }));

        const resProfilePhoto = await getApis(
          API_URL_CONSTANTS.GET_PROFILE_IMG
        );

        if (resProfilePhoto.data.status_code === 2) {
          setProfileImgSrc("");
        } else {
          setProfileImgSrc(resProfilePhoto.data.message);
        }
      } catch (error) {
        return error;
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getCoachingPlans();

        if (res.data.coaching_plans) {
          if (res.data.coaching_plans.length > 0) {
            setCoachingPlan(true);
          } else {
            setCoachingPlan(false);
          }
        } else {
          setCoachingPlan(false);
        }
      } catch (error) {
        console.error(error);
      }
    };
    if (localStorageData.role === "Coach") {
      fetchData();
    }
  }, []);

  useEffect(() => {
    if (
      AppData.coachSignUpStatus === " Pending Verification" ||
      AppData.coachSignUpStatus === "Need Action" ||
      AppData.isSwitchBtn === true
    ) {
      setNotificationBox(true);
    } else {
      setNotificationBox(false);
    }
  }, [AppData.coachSignUpStatus, AppData.isSwitchBtn]);

  function handleProfileClick() {
    if (bell) {
      setProfileOpen(false);
      setBell(false);
    }

    setProfileOpen(!isProfileOpen);
  }
  function handleProfileClose() {
    setProfileOpen(false);
  }

  const handleLogout = async () => {
    try {
      const res = await logout();
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  return (
    <>
      {/* DELETE POPUP */}

      <PhoneNotification
        bell={bell}
        setBell={setBell}
        setDeletePopup={setDeletePopup}
      />

      <div className="profile_box_dekstop flex items-center">
        {/* <div className="Profile_logo" onClick={handleProfileClick}>
          <span>{userInitial}</span>

          {role === "Coach" && <img src={star} alt="" />}
        </div> */}
        {AppData.coachSignUpStatus === "Pending Verification" ||
        AppData.coachSignUpStatus === "Need Action" ? (
          <div className="bell_div mr-4 ">
            <div className="relative">
              <img
                className="w-6"
                onClick={() => {
                  if (isProfileOpen) {
                    setProfileOpen(false);
                    setBell(true);
                  }

                  setBell(!bell);
                }}
                src={bells}
                alt=""
              />
              <div className="w-2 h-2 rounded-[100%] bg-[var(--green)] absolute top-1 right-0"></div>
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="Profile_logo" onClick={handleProfileClick}>
          {profileImgSrc ? (
            <>
              <img
                src={`${API_URL_CONSTANTS.DOMAIN_NAME}${profileImgSrc}`}
                alt=""
              />
              {localStorageData.role === "Coach" && (
                <img className="coach_logo" src={star} alt="" />
              )}
            </>
          ) : (
            <>
              <span>{AppData.userInitial}</span>
              {localStorageData.role === "Coach" && (
                <img className="coach_logo" src={star} alt="" />
              )}
            </>
          )}
        </div>
        <div
          className={`profile_dropdown ${
            isProfileOpen ? "visible_profile" : ""
          }`}
        >
          {notificationBox && (
            <div className=" text-right m-2 mb-[-1.5rem]">
              <button
                className="text-[var(--light-blue)]"
                onClick={() => handleLogout()}
              >
                Sign out
              </button>
            </div>
          )}

          <div className="flex mt-4">
            <div className=" ml-2 pt-1 w-[30%]">
              <div className=" relative flex justify-center items-center h-[4.5rem] w-[4.5rem] border-solid border-[1px] border-[var(--blue)] rounded-[100%]">
                {profileImgSrc ? (
                  <>
                    <img
                      src={`${API_URL_CONSTANTS.DOMAIN_NAME}${profileImgSrc}`}
                      alt=""
                      className="h-[4.5rem] w-[4.5rem] rounded-[100%]"
                    />
                    {localStorageData.role === "Coach" && (
                      <img
                        className="absolute bottom-[-5%] right-[5%] w-[20px] h-[20px] bg-[var(--orange)] rounded-[50%]"
                        src={star}
                        alt=""
                      />
                    )}
                  </>
                ) : (
                  <>
                    <span className="text-[1.7rem] text-[var(--light-blue)]">
                      {AppData.userInitial}
                    </span>
                    {localStorageData.role === "Coach" && (
                      <img
                        className="absolute bottom-[-5%] right-[5%] w-[20px] h-[20px] bg-[var(--orange)] rounded-[50%]"
                        src={star}
                        alt=""
                      />
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="w-full px-4 z-40">
              <div className="profile_info">
                <div className="mb-4">
                  <h3>{userData.name}</h3>
                  <p>{userData.email}</p>
                </div>

                {/* {!isViewAccount ? ( */}
                <div className="profile_info_links pb-1">
                  {localStorageData.role === "Athlete" && (
                    <Link className="" to="/calendar">
                      Training Calendar
                    </Link>
                  )}
                  <Link className="" to="/viewaccount">
                    View Account
                  </Link>
                  <Link className="" to="/mybilling">
                    My Billing
                  </Link>

                  {localStorageData.role === "Coach" && (
                    <div className="flex items-center mb-[0.2rem]">
                      <Link className="!mb-0" to="/mycoachingplans">
                        My Coaching Plans
                      </Link>
                      {isCoachingPlan === false && (
                        <>
                          <span className="bg-[var(--dark-red)] w-1 h-1 rounded-lg ml-1 "></span>
                        </>
                      )}
                    </div>
                  )}
                  {/* {isSwitchBtn && (
                      <a onClick={() => SwitchAccountBtn()}>
                        Switch Account
                      </a>
                    )} */}
                </div>
                {/* ) : (
                  <></>
                )} */}
              </div>
            </div>
          </div>

          {/* Notification Div */}

          {AppData.coachSignUpStatus === "Pending Verification" ||
          AppData.coachSignUpStatus === "Need Action" ? (
            <>{/*  <Notification setDeletePopup={setDeletePopup} /> */}</>
          ) : (
            <>
              {AppData.isSwitchBtn && (
                <>
                  <div className="nofication_div">
                    <div className="icon_div">
                      <div className=" flex_center_div border-solid h-[2rem] w-[2rem] rounded-[50%] border-[1px] border-[var(--blue)]">
                        <span className="text-[var(--blue)]">
                          {AppData.userInitial}
                        </span>
                        {localStorageData.role === "Coach" && (
                          <img src={star} alt="" />
                        )}
                      </div>
                    </div>
                    <div className="messsage_div">
                      {" "}
                      <span
                        className="cursor-pointer text-[1rem] pl-4 text-[var(--blue)]"
                        onClick={() => {
                          // setProfileOpen(false);
                          AppData.SwitchAccountBtn();
                        }}
                      >
                        Switch Account
                      </span>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
          {!notificationBox && (
            <div className="nofication_div ">
              <div className="icon_div">
                <img className="logout_icon" src={LogoutIcon} alt="" />
              </div>
              <div className="messsage_div">
                <span
                  className="logout_btn_down pl-[1.5rem] cursor-pointer text-[1.1rem] text-[var(--blue)]"
                  onClick={() => handleLogout()}
                >
                  Sign out
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
      {isProfileOpen && (
        <div
          onClick={handleProfileClose}
          className="desktop_popup_profile_background"
        ></div>
      )}
    </>
  );
}

export default ProfileBox;
