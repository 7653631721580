import React from "react";
import { EditCoachAccount } from "api/UserAccountController";
import { SubmitCoachForm } from "api/CoachFormController";
import { getApis } from "hooks/api";
import { addViewAccountData } from "state/ViewAccountState";
import { useDispatch } from "react-redux";
import { API_URL_CONSTANTS } from "utility/constants/apiUrlConstants";
import { updateForm } from "state/SetCoachFormData";
import { getFormData } from "api/CoachFormController";
import BackButton from "assets/icons/back.png";

function EditHeader(props) {
  const dispatch = useDispatch();
  const { isEdit, setEdit, data, type, setBackBtn, setPhoneActivMenu, size } =
    props;

  const TAC = true;
  const selectedDistancesRun = [];
  const selectedDistancesSwim = [];
  const selectedDistancesCycle = [];
  const selectedDistancesTriathlon = [];

  // const data = useSelector((state) => state.viewAccount);

  // get data for view account
  const fetchProfileData = async () => {
    try {
      const res = await getApis(API_URL_CONSTANTS.GET_USER_DETAILS);
      dispatch(addViewAccountData({ viewAccountData: res.data }));
    } catch (error) {
      return error;
    }
  };

  // get data for coach form
  const fetchCoachFormData = async () => {
    const res = await getFormData();

    if (res.data.cover_photo) {
      // setCoverImgSrc(res.data.cover_photo);
    } else {
      const resCoverPhoto = await getApis(API_URL_CONSTANTS.GET_COVER_IMG);
      // setCoverImgSrc(resCoverPhoto.data.message);
    }
    if (res.data.profile_photo) {
      // setProfileImgSrc(res.data.profile_photo);
    } else {
      const resProfilePhoto = await getApis(API_URL_CONSTANTS.GET_PROFILE_IMG);
      // setProfileImgSrc(resProfilePhoto.data.message);
    }
    if (res.data.video) {
      // setVideoUrl(res.data.video);
    }

    // dispatch(CoachFormData({ getCoachFromData: res.data }));
    dispatch(updateForm(res.data));
  };

  // Function  when save is clicked
  async function handleSave() {
    if (type === "account") {
      const res = await EditCoachAccount(
        data.name,
        data.mobile,
        data.email,
        data.gender,
        data.dob,
        data.tagline,
        data.about,
        data.location,
        data.certifications,
        data.profile_photo,
        data.cover_photo,
        data.video,
        data.coaches,
        data.athlete_level,
        data.languages,
        data.athlete_trained
      );
      if (res === true) {
        setEdit(false);
        fetchProfileData();
      } else {
        alert(res.data.message);
      }
    } else if (type === "editForm") {
      const res = await SubmitCoachForm(
        data.name,
        data.tagline,
        data.email,
        data.mobile,
        data.location,
        data.gender,
        data.about,
        selectedDistancesRun,
        selectedDistancesSwim,
        selectedDistancesCycle,
        selectedDistancesTriathlon,
        data.cover_photo,
        data.profile_photo,
        data.video || null,
        data.certifications,
        data.coaching_since,
        data.athlete_trained,
        data.languages,
        data.athlete_level,
        data.coaches,
        TAC
      );
      if (res) {
        setEdit(false);

        fetchCoachFormData();
      } else {
        alert("res.data.message");
      }
    }
  }

  function handleCancel() {
    if (type === "account") {
      // fetchProfileData();
      setEdit(false);
    } else if (type === "editForm") {
      setEdit(false);
    }
  }

  return (
    <div>
      <div className="edit_header ">
        <div>
          {size === "phone" && (
            <div>
              <img
                className="w-4 cursor-pointer"
                src={BackButton}
                onClick={() => {
                  setBackBtn(true);
                  setPhoneActivMenu("");
                }}
              />
            </div>
          )}
        </div>

        {isEdit ? (
          <div className="flex">
            <p
              className="cancelButton"
              onClick={() => {
                handleCancel();
              }}
            >
              Cancel
            </p>
            <p className="saveButton" onClick={() => handleSave()}>
              Save
            </p>
          </div>
        ) : (
          <button
            className="edit_button m-0 ml-[0.3rem]"
            onClick={() => {
              setEdit(true);
            }}
          >
            Edit
          </button>
        )}
      </div>
    </div>
  );
}

export default EditHeader;

// name,
//   tagline,
//   email,
//   number,
//   location,
//   gender,
//   aboutYourSelf,
//   selectedDistancesRun,
//   selectedDistancesSwim,
//   selectedDistancesCycle,
//   selectedDistancesTriathlon,
//   coverPhotoPath,
//   profilePhotoPath,
//   videoPath,
//   certificates,
//   coaching_since,
//   athlete_trained,
//   languages,
//   athlete_level,
//   coaches,
//   TAC;

// window.location.reload(true);
// if (res === true) {
//   const pData = {
//     name: name,
//     mobile: number,
//     gender: gender,
//     dob: dob,
//     athlete_type: athleteType,
//     location: location,
//   };
//   dispatch(addViewAccountData(pData));
//   setName(pData.name);
//   setLocation(pData.location);
//   setEdit(false);
//   setType(false);
// } else {
//   setErrorHeader("Error");
//   setErrorMessage(`${res}`);
//   setErrorPopup(true);
// }
