const DOMAIN_URL = process.env.REACT_APP_URL;

const SERVICE_ENTRYPOINT = process.env.REACT_APP_SERVICE_ENTRY_POINT;
const SERVICE_TOFUI_ENTRYPOINT = process.env.REACT_APP_SERVICE_TOFUI;
const REST_API_ENDPOINT = DOMAIN_URL + SERVICE_ENTRYPOINT;
const REST_UI_API_ENDPOINT = DOMAIN_URL + SERVICE_TOFUI_ENTRYPOINT;

export const API_URL_CONSTANTS = {
  DOMAIN_NAME: process.env.REACT_APP_URL,

  // login & sign up  apis
  REST_API_LOGIN_ENDPOINT: REST_API_ENDPOINT + "/basic/user/login",
  REST_API_VERIFY_EMAIL: REST_API_ENDPOINT + "/basic/user/verify",
  REST_API_VERIFY_OTP: REST_API_ENDPOINT + "/basic/user/verify/otp",
  REST_API_SEND_OTP: REST_API_ENDPOINT + "/basic/user/send/otp",
  REST_API_UPDATE_PASSWORD: REST_API_ENDPOINT + "/basic/user/update/password",
  USER_SIGN_UP: REST_API_ENDPOINT + "/basic/user/signup",
  SWITCH_ACCOUNT: REST_API_ENDPOINT + "/basic/user/switch/",
  // REST_API_USER_DETAILS: REST_API_ENDPOINT + "/basic/user/get/details/ref",

  // Billing apis
  CASHFREE_API: REST_API_ENDPOINT + "/billing/order/create",

  //Coach form apis
  GET_COACH_SIGNUP_STATUS: REST_UI_API_ENDPOINT + "/coach/signup/status/get",
  COACHFORM_FORM_SUBMIT_API: REST_API_ENDPOINT + "/coach/signup",
  COACHFORM_PHOTO_UPLOAD_API: REST_API_ENDPOINT + "/files/upload",
  MY_COACHING_DETAILS: REST_UI_API_ENDPOINT + "/coach/coachings/get",
  GET_VIDEO: REST_UI_API_ENDPOINT + "/files/get/3",
  GET_COVER_IMG: REST_UI_API_ENDPOINT + "/files/get/1",
  GET_PROFILE_IMG: REST_UI_API_ENDPOINT + "/files/get/2",
  GET_COACH_FORM_DATA: REST_UI_API_ENDPOINT + "/coach/signup/details/get",
  DELETE_COACH_FORM: REST_API_ENDPOINT + "/coach/signup/status/set",

  // user details
  GET_USER_DETAILS: REST_UI_API_ENDPOINT + "/basic/user/get/details",
  VIEW_ACCOUNT_EDIT: REST_API_ENDPOINT + "/basic/user/update/details",
  POOL_LENGTH_EDIT: REST_API_ENDPOINT + "/user/workout/settings/save",
  EDIT_PHOTO: REST_API_ENDPOINT + "/files/edit/",
  GARMIN_CONNECTIONS: REST_API_ENDPOINT + "/garmin/connect",

  // Coaching plans
  GET_COACHING_PLANS: REST_UI_API_ENDPOINT + "/coaching/plans/list",
  ADD_COACHING_PLANS: REST_API_ENDPOINT + "/coaching/plans/save",
  UPDATE_COACHING_PLANS: REST_API_ENDPOINT + "/coaching/plans/save",
  DELETE_COACHING_PLANS: REST_API_ENDPOINT + "/coaching/plans/delete/",

  // calendar
  GET_USER_CALENDAR_LIST: REST_UI_API_ENDPOINT + "/v1/user/calendar",
  GET_WORKOUT_STEPS: REST_UI_API_ENDPOINT + "/workoutsteps/get/",
  // coach page
  SEND_QUERY_TO_COACH: REST_API_ENDPOINT + "/coach/query",
  COACHES_LIST: REST_UI_API_ENDPOINT + "/user/coach/list",
  COACHES_DETAILS: REST_UI_API_ENDPOINT + "/coach/details/get",

  // logout
  LOGOUT: REST_API_ENDPOINT + "/basic/user/logout",
};
