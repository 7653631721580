import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const viewAccountData = createSlice({
  name: "ViewAccountData",
  initialState: initialState,
  reducers: {
    UserData: (state, action) => {
      return { ...action.payload.viewAccountData };
    },
    addViewAccountData: (state, action) => {
      // Update existing properties and add new properties from action.payload
      return { ...state, ...action.payload };
    },

    updateCertificatesViewAccount: (state, action) => {
      state.certifications = action.payload;
    },
  },
});

export const { UserData, addViewAccountData, updateCertificatesViewAccount } =
  viewAccountData.actions;
export default viewAccountData.reducer;
