import CoverPhoto from "components/coverPhoto/CoverPhoto";
import Selected_about from "assets/icons/sub_menu_icons/selected/about.svg";
import not_selected_about from "assets/icons/sub_menu_icons/not_selected/about.svg";
import Selected_video from "assets/icons/sub_menu_icons/selected/video.svg";
import not_selected_video from "assets/icons/sub_menu_icons/not_selected/video.svg";
import Selected_youTrain from "assets/icons/sub_menu_icons/selected/youTrain.svg";
import not_selected_youTrain from "assets/icons/sub_menu_icons/not_selected/youTrain.svg";
import Selected_certificate from "assets/icons/sub_menu_icons/selected/certificate.svg";
import not_selected_certificate from "assets/icons/sub_menu_icons/not_selected/certificate.svg";
import Selected_billing from "assets/icons/sub_menu_icons/selected/billing.svg";
import not_selected_billing from "assets/icons/sub_menu_icons/not_selected/billing.svg";
import Selected_setting from "assets/icons/sub_menu_icons/selected/setting.svg";
import not_selected_setting from "assets/icons/sub_menu_icons/not_selected/setting.svg";
import AccountAbout from "components/viewAccount/AccountAbout";
import YouTrainViewAccount from "components/viewAccount/YouTrainViewAccount";
import EditHeader from "components/viewAccount/EditHeader";
import AccountVideo from "components/viewAccount/AccountVideo";
import AccountCertificates from "components/viewAccount/AccountCertificates";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

function PhoneEditCoach(props) {
  const {
    phoneActivMenu,
    setPhoneActivMenu,
    isEdit,
    setEdit,
    data,
    type,
    isCoach,
  } = props;
  const navigate = useNavigate();

  const [backBtn, setBackBtn] = useState(true);
  const coachMenu =
    type === "editForm"
      ? [
          {
            id: "about",
            label: "About",
            selected_img: Selected_about,
            not_selected_img: not_selected_about,
            message:
              "Edit your about section which consists of , Tagline, location,  language. ",
          },
          {
            id: "video",
            label: "Video",
            selected_img: Selected_video,
            not_selected_img: not_selected_video,
            message: "Edit your Video which describes, about yourself.  ",
          },
          {
            id: "youTrain",
            label: "Your Train",
            selected_img: Selected_youTrain,
            not_selected_img: not_selected_youTrain,
            message:
              "Edit your your Train section  which consists of running, swimming, cycling, triathlon.  ",
          },
          {
            id: "certificates",
            label: "Certificates",
            selected_img: Selected_certificate,
            not_selected_img: not_selected_certificate,
            message: "Edit certificates which you have earned.   ",
          },
        ]
      : [
          {
            id: "about",
            label: "About",
            selected_img: Selected_about,
            not_selected_img: not_selected_about,
            message:
              "Edit your about section which consists of , Tagline, location,  language. ",
          },
          {
            id: "video",
            label: "Video",
            selected_img: Selected_video,
            not_selected_img: not_selected_video,
            message: "Edit your Video which describes, about yourself.  ",
          },
          {
            id: "youTrain",
            label: "Your Train",
            selected_img: Selected_youTrain,
            not_selected_img: not_selected_youTrain,
            message:
              "Edit your your Train section  which consists of running, swimming, cycling, triathlon.  ",
          },
          {
            id: "certificates",
            label: "Certificates",
            selected_img: Selected_certificate,
            not_selected_img: not_selected_certificate,
            message: "Edit certificates which you have earned.   ",
          },
          // {
          //   id: "billing",
          //   label: "My Billing",
          //   selected_img: Selected_billing,
          //   not_selected_img: not_selected_billing,
          //   message: "Your can  view your billing information here. ",
          // },
          // {
          //   id: "settings",
          //   label: "Settings",
          //   selected_img: Selected_setting,
          //   not_selected_img: not_selected_setting,
          //   message: "",
          // },
        ];

  if (backBtn) {
    return (
      <div className="mt-[4rem]">
        <CoverPhoto isCoach={isCoach} />
        <div className="flex flex-col mt-[4rem] border-t border-[#f6f6f6]">
          {coachMenu.map((item) => (
            <div
              key={item.id}
              className={`flex py-4 cursor-pointer border-b border-[#f6f6f6] ${
                phoneActivMenu === item.id ? "bg-[#f6f6f6]" : ""
              } `}
              onClick={() => {
                setPhoneActivMenu(item.id);
                setBackBtn(false);
              }}
            >
              <div className="flex justify-start   px-4">
                <div>
                  {item.id === "about" || item.id === "youTrain" ? (
                    <img
                      className="w-[2.2rem]"
                      src={item.selected_img}
                      alt=""
                    />
                  ) : (
                    <>
                      <img
                        className="w-[2rem]"
                        src={item.selected_img}
                        alt=""
                      />
                    </>
                  )}
                </div>

                <div className="ml-2 ">
                  <div>
                    <p className={` text-[1.5rem] text-[var(--blue)] `}>
                      {item.label}
                    </p>
                  </div>
                  <div>
                    <p className="text-[var(--light-blue)]">{item.message}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  if (phoneActivMenu) {
    return (
      <div className="mt-[4rem]">
        <EditHeader
          isEdit={isEdit}
          setEdit={setEdit}
          data={data}
          type={type}
          setBackBtn={setBackBtn}
          setPhoneActivMenu={setPhoneActivMenu}
          size="phone"
        />

        {phoneActivMenu === "about" && (
          <AccountAbout
            isEdit={isEdit}
            setEdit={setEdit}
            data={data}
            type={type}
          />
        )}
        {phoneActivMenu === "video" && (
          <AccountVideo
            isEdit={isEdit}
            setEdit={setEdit}
            data={data}
            type={type}
          />
        )}
        {phoneActivMenu === "certificates" && (
          <AccountCertificates
            isEdit={isEdit}
            setEdit={setEdit}
            data={data}
            type={type}
          />
        )}
        {phoneActivMenu === "youTrain" && (
          <YouTrainViewAccount
            isEdit={isEdit}
            setEdit={setEdit}
            data={data}
            type={type}
          />
        )}
        {phoneActivMenu === "billing" && navigate("/mybilling")}
      </div>
    );
  }
}

export default PhoneEditCoach;
