import React, { useContext, useEffect, useState } from "react";
import RightArrow from "assets/icons/right-arrow.png";
import ErrorPopup from "components/popups/ErrorPopup";
import { AppProps, SubmitCoachFormData } from "utility/constants/useContext";
import SuccessPopup from "components/popups/SuccessPopup";
import { useNavigate } from "react-router-dom";
import FormStep4 from "./FormStep4";
import BackArrow from "assets/icons/back.png";
import { updateForm } from "state/SetCoachFormData";
import FormStep6 from "./FormStep6";

function FormStep5() {
  const Props = useContext(SubmitCoachFormData);

  const [validation, setValidation] = useState(false);
  const [nextClicked, setNextClicked] = useState(false);

  const [backClicked, setBackClicked] = useState(false);
  const [popper, setPopper] = useState(false);

  const navigate = useNavigate();
  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleBackClick = () => {
    setBackClicked(true);
  };

  useEffect(() => {
    if (Props.formState.isTri === "yes" || Props.formState.isTri === "no") {
      setValidation(true);
    } else {
      setValidation(false);
    }
  }, [Props.formState.isTri]);

  const handleCheckboxChange = (value) => {
    // If the value is already selected, remove it from the array
    const updatedSelectedDistancesRun =
      Props.formState.selectedDistancesTriathlon.includes(value)
        ? Props.formState.selectedDistancesTriathlon.filter(
            (item) => item !== value
          )
        : [...Props.formState.selectedDistancesTriathlon, value];

    Props.dispatch(
      updateForm({ selectedDistancesTriathlon: updatedSelectedDistancesRun })
    );
  };

  return (
    <div>
      {backClicked ? (
        <FormStep4 />
      ) : (
        <>
          {nextClicked && validation ? (
            <FormStep6 />
          ) : (
            <>
              <div className="coach_form">
                <div className="form_section">
                  <form action="" method="post" className="mt-16 m-0">
                    <div className="step2_div">
                      <div className="question_1 ">
                        <h4>Do you train athletes for Triathlon ? </h4>
                        <div className="yes_no_div swim">
                          <label htmlFor="yes">
                            <input
                              type="radio"
                              name="radio"
                              id="yes"
                              onChange={() => {
                                Props.dispatch(updateForm({ isTri: "yes" }));
                              }}
                              checked={Props.formState.isTri === "yes"}
                            />
                            <span>Yes</span>
                          </label>

                          <label htmlFor="no">
                            <input
                              type="radio"
                              name="radio"
                              id="no"
                              onChange={() => {
                                Props.dispatch(
                                  updateForm({ selectedDistancesTriathlon: [] })
                                );
                                Props.dispatch(updateForm({ isTri: "no" }));
                              }}
                              checked={Props.formState.isTri === "no"}
                            />
                            <span>No</span>
                          </label>
                        </div>
                      </div>

                      {Props.formState.isTri === "yes" ? (
                        <>
                          <div className="question_2">
                            <h4>Select distances</h4>
                            <div className="yes_no_div swim">
                              <label>
                                <input
                                  type="checkbox"
                                  name="radio"
                                  value="Sprint-Tri"
                                  onChange={() =>
                                    handleCheckboxChange("Sprint-Tri")
                                  }
                                  checked={Props.formState.selectedDistancesTriathlon.includes(
                                    "Sprint-Tri"
                                  )}
                                />
                                <span>Sprint-Tri</span>
                              </label>

                              <label>
                                <input
                                  type="checkbox"
                                  name="radio"
                                  id=""
                                  value="5150-ironman"
                                  onChange={() =>
                                    handleCheckboxChange("5150-ironman")
                                  }
                                  checked={Props.formState.selectedDistancesTriathlon.includes(
                                    "5150-ironman"
                                  )}
                                />
                                <span>5150-ironman</span>
                              </label>

                              <label>
                                <input
                                  type="checkbox"
                                  name="radio"
                                  id=""
                                  value="Ironman-70.3"
                                  onChange={() =>
                                    handleCheckboxChange("Ironman-70.3")
                                  }
                                  checked={Props.formState.selectedDistancesTriathlon.includes(
                                    "Ironman-70.3"
                                  )}
                                />
                                <span>Ironman-70.3</span>
                              </label>

                              <label>
                                <input
                                  type="checkbox"
                                  name="radio"
                                  id=""
                                  value="Ironman"
                                  onChange={() =>
                                    handleCheckboxChange("Ironman")
                                  }
                                  checked={Props.formState.selectedDistancesTriathlon.includes(
                                    "Ironman"
                                  )}
                                />
                                <span>Ironman</span>
                              </label>
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}

                      <div className="flex justify-between statusBar">
                        {popper && (
                          <div className="popper absolute bottom-12 right-1">
                            <p className="text-[var(--red)]">
                              fill up the fileds
                            </p>
                          </div>
                        )}
                        <div
                          className="mr-[10px] mt-[10px] cancelButton"
                          onClick={handleBackClick}
                        >
                          <div className=" flex items-center">
                            <img
                              src={BackArrow}
                              alt=""
                              className="next_img mr-1 ml-[-3px]"
                            />
                            Back
                          </div>
                        </div>
                        <div className="coach_cancel_Div flex">
                          <p
                            className="mr-[10px] mt-[10px] cancelButton"
                            onClick={() => {
                              navigate("/");
                            }}
                          >
                            Cancel
                          </p>
                          <div
                            className="nextButton"
                            onClick={() => {
                              if (validation === true) {
                                setNextClicked(true);
                                setPopper(false);
                              } else {
                                if (validation === false) {
                                  setPopper(true);
                                }
                              }
                            }}
                          >
                            Next{" "}
                            <img
                              src={RightArrow}
                              alt=""
                              className="next_img mt-[6px]"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}
export default FormStep5;
