import React, { useContext, useEffect, useState } from "react";
import { getlocalStorageData } from "hooks/basic";
import { AppProps } from "utility/constants/useContext";
import { HelmetProvider } from "react-helmet-async";
import Alert from "assets/icons/RedAlert.svg";
import coachImg2 from "assets/img/Become_coach_photo.jpeg";
import { useNavigate } from "react-router-dom";

function Coach() {
  const localStorageData = getlocalStorageData();
  const AppData = useContext(AppProps);
  const navigate = useNavigate();

  function navigateTo() {
    navigate("/becomeacoach");
  }

  return (
    <HelmetProvider>
      <div className="coach_div   px-[4rem] " id="coach">
        <div className="flex  justify-between items-center pb-20  lg:flex-col-reverse">
          <div className="join_text w-[50%] px-[2.2rem] lg:mt-4 lg:min-w-[30rem] lg:px-0">
            <div className=" join_text_heading flex flex-col   text-[var(--blue)]  font-light ">
              <h1 className="text-[2rem] text-[var(--blue)] font-semibold">
                Ready to Join TRACKOFIT
              </h1>
              <p className="text-[1.2rem] text-left font-light text-[var(--light-blue)] mb-4">
                Are you eager to use your expertise to train aspiring athletes?
                Join Trackofit as a coach and elevate your coaching journey.
                Here’s what you can achieve as a coach on our platform.
              </p>
              <div>
                <button
                  onClick={navigateTo}
                  className="bg-[var(--blue)] text-white py-2 px-4 text-[1rem] rounded-[5px]"
                >
                  Become a coach
                </button>
              </div>
            </div>
          </div>

          <div>
            <img className="w-[30rem] rounded-[5px]" src={coachImg2} alt="" />
          </div>
        </div>
      </div>
    </HelmetProvider>
  );
}

export default Coach;
