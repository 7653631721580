import React, { useContext, useState } from "react";
import OtpPopUp from "components/otp/SendOtp";
import { verifyEmail } from "api/Signup";
import { sendOtp } from "api/Signup";
import { Button } from "rsuite";
import ForgotPasswordOtp from "components/otp/ForgotPasswordOtp";
import { AppProps } from "utility/constants/useContext";

function ForgorPasswordPopUp(props) {
  const { setForgotPassword } = props;
  const [isSendOtp, setSendOtp] = useState(false);
  const [emailNotFound, setEmailNotFound] = useState(true);
  const [email, setEmail] = useState("");
  const [load, setLoad] = useState(false);
  const AppData = useContext(AppProps);
  function handleCancel() {
    setForgotPassword(false);
    AppData.setOpen(false);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    if (load === true) {
      return;
    }
    setLoad(true);
    const res = await verifyEmail(email);
    if (res) {
      setLoad(false);
      setSendOtp(true);
      await sendOtp(email);
    } else {
      setSendOtp(false);
      setEmailNotFound(false);
      setLoad(false);
    }
  }

  return (
    <>
      {!isSendOtp ? (
        <form onSubmit={handleSubmit}>
          <div className="email_input_box flex flex-col items-center">
            <span onClick={handleCancel} className="popup_cancel_btn">
              Cancel
            </span>
            <h3 className="forgotPassword_heading margin_bottom_8 mt-4">
              Forgot Password
            </h3>

            <input
              id="emailInput"
              className="form_inputs margin_bottom_8"
              type="email"
              name="email"
              autoComplete="email"
              placeholder="Enter your registered email address."
              required
              onChange={(e) => setEmail(e.target.value)}
            />
            {!emailNotFound && (
              <label className="error_message " htmlFor="">
                Email Not Found
              </label>
            )}

            <div className="flex_center_div mt-[.8rem] pb-[.8rem]">
              {load ? (
                <>
                  <div className="orange_btn">
                    <Button
                      loading
                      className="load_btn"
                    ></Button>
                  </div>
                </>
              ) : (
                <button className="orange_btn">Send OTP</button>
              )}
            </div>
          </div>
        </form>
      ) : (
        <ForgotPasswordOtp
          setForgotPassword={setForgotPassword}
          email={email}
        />
      )}
    </>
  );
}

export default ForgorPasswordPopUp;

// if (isCancel) {
//   setOpen(false);
//   setTimeout(() => {
//     setOpen(true);
//   }, 500);
// }
