import React, { useEffect } from "react";
import CategorySelect from "components/inputs/CategorySelect";
import AthleteLevelSelect from "components/inputs/AthleteLevelSelect";

function YouTrainViewAccount(props) {
  const { isEdit, data, type } = props;

  const runningOptions = ["5k", "7k", "10k", "16k", "21k", "42k"];
  const cyclingOptions = ["40k", "90k", "100k", "160k", "200k", "Time Trail"];
  const swimmingOptions = ["1k", "1.5k", "1.9k", "3.8k", "5k"];
  const triathlonOptions = [
    "Sprint-Tri",
    "Ironman-70.3",
    "5150-Ironman",
    "Ironman",
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //  Filter selected options from data.coaches
  const getFilteredOptions = (options, category) => {
    const selectedValues = data.coaches
      .filter((item) => item.category === category)
      .flatMap((item) => item.value.split(", "));

    //  Filter out selected values
    return options.filter((option) => !selectedValues.includes(option));
  };

  return (
    <>
      {data.length === 0 ? null : (
        <div className="setting_box border-none mt-0">
          {!isEdit ? (
            <div className="grid grid-cols-2">
              {data.coaches &&
                data.coaches.map((item, index) => (
                  <div key={index} className="first_half mb-4 px-1">
                    <p className="lable_tag">{item.category}</p>
                    <p className="lable_value">{item.value}</p>
                  </div>
                ))}
              <div className="first_half mb-4 px-1">
                <p className="lable_tag">Athlete Level</p>
                <p className="lable_value">{data.athlete_level}</p>
              </div>
            </div>
          ) : (
            <div className="first_half mb-4 px-1">
              <CategorySelect
                className="mb-4"
                categoryName="Run"
                options={getFilteredOptions(runningOptions, "Run")}
                data={data}
                type={type}
              />
              <CategorySelect
                className="mb-4"
                categoryName="Cycle"
                options={getFilteredOptions(cyclingOptions, "Cycle")}
                data={data}
                type={type}
              />
              <CategorySelect
                className="mb-4"
                categoryName="Swim"
                options={getFilteredOptions(swimmingOptions, "Swim")}
                data={data}
                type={type}
              />
              <CategorySelect
                className="mb-4"
                categoryName="Triathlon"
                options={getFilteredOptions(triathlonOptions, "Triathlon")}
                data={data}
                type={type}
              />
              <AthleteLevelSelect data={data} type={type} />
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default YouTrainViewAccount;
