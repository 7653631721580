import React, { useContext, useEffect, useState } from "react";
import AppPhoto from "assets/img/App_photo_2.png";
import { getlocalStorageData } from "hooks/basic";
import { AppProps } from "utility/constants/useContext";
import { HelmetProvider } from "react-helmet-async";
import { format, parse, differenceInDays } from "date-fns";
import Pricing_img from "assets/img/Become_a_coach_pricing_img.png";
import img10 from "assets/img/Component 27.png";
import SEO from "hooks/Seo";
import { API_URL_CONSTANTS } from "utility/constants/apiUrlConstants";
import { Header } from "api/Signin";
import axios from "axios";
import FooterSection from "components/footer/FooterSection";
import { useNavigate } from "react-router-dom";
import FormBtn from "./FormBtn";
import CoachSteps from "./CoachSteps";

function BecomeCoach() {
  const AppData = useContext(AppProps);
  const localStorageData = getlocalStorageData();
  const [formattedDate, setFormattedDate] = useState("");
  const [isRejected, setRejected] = useState();
  const [formRespones, setFormRespones] = useState({});
  const [pointsIndex, setPointsIndex] = useState(0);
  const [animation, setAnimation] = useState("start");

  const navigate = useNavigate();

  //API call for coach signup status
  //2,6, => view form / null,3,5 => get Stated page / 4=> form editable / 1=> pending verfication
  useEffect(() => {
    async function getoachStatus() {
      const header = Header();
      return axios
        .get(API_URL_CONSTANTS.GET_COACH_SIGNUP_STATUS, {
          headers: header,
        })
        .then((res) => {
          if (res.data.request_submitted === false) {
            AppData.setCoachSignupStatus("null");
            setFormRespones(res.data);
          } else {
            setFormRespones(res.data);

            if (res.data.request_status === 1) {
              AppData.setCoachSignupStatus(res.data.message); // message =  pending verification
            } else if (
              res.data.request_status === 2 &&
              localStorageData.role === "Athlete"
            ) {
              AppData.setCoachSignupStatus("2"); // message = Verified but no coaching plans
            } else if (
              res.data.request_status === 2 &&
              localStorageData.role === "Coach"
            ) {
              AppData.setCoachSignupStatus("2");
            } else if (res.data.request_status === 4) {
              AppData.setCoachSignupStatus("4"); // Awaiting Details
              AppData.setCoachStatusMessage("Awaiting Details");
            } else if (res.data.cs_status === 6) {
              AppData.setCoachSignupStatus("View Profile");
            } else if (res.data.request_status === 3) {
              AppData.setCoachSignupStatus("3"); // Rejected
              AppData.setCoachStatusMessage("Coach Form Rejected");
            } else if (res.data.request_status === 5) {
              AppData.setCoachSignupStatus("5"); // Cancelled
              AppData.setCoachStatusMessage(`Your Coach request was Cancelled`);
            } else if (res.data.request_status === 6) {
              AppData.setCoachSignupStatus("5"); // Verified and have coaching plans
              AppData.setCoachStatusMessage("");
            }
          }
        })
        .catch((error) => {
          return error;
        });
    }

    if (AppData.isSignedIn) {
      getoachStatus();
    }
  }, [AppData.isSignedIn]);

  useEffect(() => {
    if (formRespones && formRespones.updated_on) {
      try {
        // Parse the date only once
        const parsedDate = parse(
          formRespones.updated_on,
          "dd-MM-yyyy HH:mm:ss",
          new Date()
        );

        // Format the date for display
        const date = format(parsedDate, "dd MMM");
        setFormattedDate(date);

        // Calculate the difference in days from the current date
        const currentDate = new Date();
        const daysDifference = differenceInDays(currentDate, parsedDate);

        // Set rejection status based on days difference
        setRejected(daysDifference >= 90);
      } catch (error) {
        console.error("Date parsing error:", error);
      }
    }
  }, [formRespones]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function navigateTo() {
    if (AppData.isSignedIn && localStorageData.role === "Athlete") {
      navigate("/coachform");
    } else if (!AppData.isSignedIn) {
      AppData.setSignInOpen(true);
      AppData.setOpen(true);
    }
  }

  const points = [
    "Tailored approaches to individual needs.",
    "Train anytime, anywhere",
    "Techniques and strategies to improve performance. ",
    "Focus on physical and mental conditioning.",
    "Use of data and analytics for progress tracking.",
  ];

  useEffect(() => {
    setAnimation("start");
    const initialTimeout = setTimeout(() => {
      setAnimation("end");
      setTimeout(() => {
        setPointsIndex((prevIndex) => (prevIndex + 1) % points.length);
      }, 500);
    }, 3000);

    // Repeat animation every 6 seconds
    const interval = setInterval(() => {
      setAnimation("start");

      setTimeout(() => {
        setAnimation("end");

        setTimeout(() => {
          setPointsIndex((prevIndex) => (prevIndex + 1) % points.length);
        }, 500);
      }, 3000);
    }, 4000);

    return () => {
      clearTimeout(initialTimeout);
      clearInterval(interval);
    };
  }, []);

  return (
    <HelmetProvider>
      <div className="coach_div   pt-[4rem]">
        <SEO
          title="Become a coach | Trackofit"
          descriptionName="Coach"
          description="Get expert help every step of the way. Connect with an accredited coach tailored to your needs or explore our coach directory."
          canonicalUrl="https://www.trackofit.com/become-a-coach"
        />
        <div className="become_a_coach_bg overflow-x-hidden">
          <div className=" flex-col w-full text-white justify-between px-20 lg:px-8 lg:py-8">
            <h4 className="mb-4 text-[2rem] md:text-[1.5rem] ">
              Empowering Athletes Through Personalized Training Programs
            </h4>
            <p
              className={`bg-[#ffffff4f] px-4 rounded-[5px] inline-block py-1 animation_points text-[1.2rem] md:text-[1rem] mb-6 ${
                animation === "start"
                  ? "animate-slide-in"
                  : animation === "end"
                  ? "animate-slide-out"
                  : ""
              }`}
            >
              {points[pointsIndex]}
            </p>
          </div>
        </div>

        <div className="flex justify-between  items-center lg:flex-col-reverse lg:pt-20  px-20 lg:px-8 pb-20 ">
          <div className="flex flex-col text-[var(--blue)] w-[50%] lg:w-full pr-8 lg:pr-0 lg:pt-8">
            <h5 className="mb-4 text-[1.5rem]">
              Use the APP to Monitor Atheletes
            </h5>
            <ul className="flex flex-col gap-3  ml-6 text-[1.2rem] text-[var(--light-blue)] list-disc">
              <li>Track and adjust training plans based on performance.</li>
              <li>Overview of race schedules and preparations.</li>
              <li>
                Monitoring heart rate for optimal training intensity to manage
                endurance and workload effectively.
              </li>
              <li>Adjust goals based on athlete progress and feedback.</li>
              <li>
                Seamless oversight of athlete performance metrics using
                real-time updates and notifications.
              </li>
            </ul>
          </div>
          <div className=" z-10 flex  -mt-20 lg:mt-0">
            <img
              className="object-cover  rounded-[5px]"
              src={AppPhoto}
              alt=""
            />
          </div>
        </div>

        <div className="flex lg:flex-col   px-20 lg:px-8 pb-20 justify-between items-center">
          <div>
            <img src={Pricing_img} alt="" />
          </div>
          <div className="flex flex-col text-[var(--blue)]  pl-8 lg:pl-0 lg:pt-8 lg:w-full">
            <h5 className="mb-4 text-[1.5rem]">Coaching Autonomy</h5>

            <ul className="flex flex-col gap-3 ml-6 text-[1.2rem] text-[var(--light-blue)] list-disc">
              <li>
                Authority to create personalised coaching plansCoaches have full
                control to create and manage their own plans.
              </li>
              <li>Adjust fees according to services offered.</li>
              <li>Athletes choose plans that match their goals and budget.</li>
              <li>
                Athletes can select from various plans that meet their goals and
                budget.
              </li>
              <li>Better alignment of training with athlete goals.</li>
            </ul>
          </div>
        </div>

        <div className="flex  justify-between items-center px-20 lg:px-8  pb-20  lg:flex-col-reverse">
          <div className=" flex flex-col lg:pt-8 text-[var(--blue)]  font-light w-[50%] lg:w-full">
            <h5 className="text-[1.5rem] mb-8 text-[var(--blue)] font-semibold">
              Steps to Register as a Coach
            </h5>
            <CoachSteps />
            <FormBtn
              formRespones={formRespones}
              isRejected={isRejected}
              formattedDate={formattedDate}
            />
          </div>

          <div>
            <img className="w-[30rem] rounded-[5px]" src={img10} alt="" />
          </div>
        </div>

        <FooterSection />
      </div>
    </HelmetProvider>
  );
}

export default BecomeCoach;
