import React, { useContext, useState } from "react";
import { Buffer } from "buffer";
import show from "assets/icons/OpenEye.svg";
import nonShow from "assets/icons/CloseEye.svg";
import { updatePassword } from "api/Signup";
import { Button } from "rsuite";
import { AppProps } from "utility/constants/useContext";

function ResetPassword(props) {
  const [showPassword, setShowPassword] = useState(false);
  const { setForgotPassword, email } = props;

  const [password, setPassword] = useState("");
  const [repassword, setRePassword] = useState("");
  const [isMatch, setMatch] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [load, setLoad] = useState(false);
  const AppData = useContext(AppProps);

  function handleCancel() {
    setForgotPassword(false);
    AppData.setOpen(false);
  }

  async function handleSbumit(e) {
    e.preventDefault();
    if (load === true) {
      return;
    }
    setLoad(true);
    if (password === repassword) {
      setMatch(false);
      const buffer = Buffer.from(password);
      const encrPassword = buffer.toString("base64");
      const res = await updatePassword(email, encrPassword);
      if (res === true) {
        setLoad(false);
        setForgotPassword(false);
        AppData.setOpen(true);
        AppData.setSignInOpen(true);
      }
    } else {
      setLoad(false);
      setMatch(true);
    }
  }

  // password validation
  function validatePassword(password) {
    const passwordRegex = /^(?=.*[!@#$])[a-zA-Z0-9!@#$]{6,12}$/;
    if (!passwordRegex.test(password)) {
      setPasswordError(
        "Enter a 6 to 12 character password (a-z, A-Z, 0-9, !@#$) with at least one special character, or password do not match"
      );
    } else {
      setPasswordError("");
    }
  }

  return (
    <form onSubmit={handleSbumit}>
      <div className=" text-center">
        <span onClick={handleCancel} className="popup_cancel_btn">
          Cancel
        </span>
        <h3 className="forgotPassword_heading"> Reset Password</h3>
        <p className="form_guide_text">
          Enter a password 8-14 characters. It must contain letter, number and
          atleast 1 special character (. , - , _ , # , @)
        </p>
        <input
          className="form_inputs margin_bottom_8"
          type={showPassword ? "text" : "password"}
          name="password"
          placeholder="Enter password"
          required
          onChange={(e) => {
            setPassword(e.target.value);
            validatePassword(e.target.value);
          }}
        />
        <div className="relative">
          <input
            className="form_inputs "
            id="password"
            type={showPassword ? "text" : "password"}
            name="password"
            placeholder="Re-Enter password"
            required
            onChange={(e) => {
              setRePassword(e.target.value);
            }}
          />
          <label
            className="show_password"
            htmlFor=""
            onClick={() => setShowPassword(!showPassword)}
          >
            <img src={showPassword ? show : nonShow} alt="" />
          </label>
        </div>
        {isMatch && (
          <div className="error_message text-right">
            <label>Passwords do not Match</label>
          </div>
        )}
        {passwordError && (
          <div className="error_message text-right">{passwordError}</div>
        )}
        <div className="flex_center_div pb-[.8rem] mt-[.8rem]">
          {load ? (
            <Button loading className="button bg-[var(--orange)]"></Button>
          ) : (
            <button className="orange_btn">Reset</button>
          )}
        </div>
      </div>
    </form>
  );
}

export default ResetPassword;
