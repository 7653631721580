import React, { useEffect } from "react";
import "components/popups/success_popup.css";
import check from "assets/icons/check.svg";
import close from "assets/icons/close.svg";

function SuccessPopup(props) {
  const { setIsSignupDone, isSignupDone, message } = props;

  useEffect(() => {
    setTimeout(function () {
      setIsSignupDone(false);
    }, 5000);
  }, [isSignupDone]);
  return (
    <>
      <div
        className={`success_popup ${isSignupDone ? "show_success_popup" : ""}`}
      >
        <div className="popup_body -mr-6">
          <div className="done_logo">
            <img src={check} alt="" />
          </div>
          <div className="content flex">
            <div className="mt-2">
              <h5>{message.heading}</h5>
              <p>{message.message}</p>
            </div>
            <div
              className="close_btn flex justify-end -mt-4"
              onClick={() => setIsSignupDone(false)}
            >
              <img src={close} alt="" className="mt-" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SuccessPopup;
