import React, { useEffect, useState, useRef } from "react";
import { Button } from "rsuite";
import {
  AddCoachingPlans,
  UpdateCoachingPlan,
} from "api/CoachingPlansController";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import ErrorPopup from "components/popups/ErrorPopup";

function AddPopup(props) {
  const {
    setAdd,
    edit,
    setEdit,
    planId,
    name,
    setName,
    tagline,
    setTagline,
    planDetails,
    setPlanDetails,
    planCharge,
    setPlanCharge,
  } = props;

  const [errorPopup, setErrorPopup] = useState(false); // error popup
  const [errorHeader, setErrorHeader] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [load, setLoad] = useState(false);
  const [wordCountName, setWordCountName] = useState(0);
  const [wordCountDetails, setWordCountDetails] = useState(0);
  const [wordCountTagline, setWordCountTagline] = useState(0);
  const [planNameError, setPlanNameError] = useState("");
  const [planTaglineError, setPlanTaglineError] = useState("");
  const [planDesError, setPlanDesError] = useState("");
  const [planPriceError, setPlanPriceError] = useState("");
  const isTax = true;

  // Handle ADD
  async function handleAdd() {
    if (name === "") {
      setPlanNameError("Fill Up");
    } else if (planDetails === "" || planDetails === "<p><br></p>") {
      setPlanDesError("Fill Up");
    } else if (planCharge === "") {
      setPlanPriceError("Fill Up");
    } else {
      if (load === true) {
        return;
      }

      if (name && planCharge && planDetails) {
        setLoad(true);
        const res = await AddCoachingPlans(
          name,
          tagline,
          planDetails,
          planCharge,
          isTax
        );
        if (res === true) {
          setLoad(false);
          setAdd(false);
          setEdit(false);
        } else {
          setErrorHeader("Error");
          setErrorMessage(`${res.message}`);
          setErrorPopup(true);
          setLoad(false);
        }
      }
    }
  }

  // Hande edit

  async function handleEdit() {
    if (name === "") {
      setPlanNameError("Fill Up");
    } else if (planDetails === "" || planDetails === "<p><br></p>") {
      setPlanDesError("Fill Up");
    } else if (planCharge === "") {
      setPlanPriceError("Fill Up");
    } else if (planPriceError) {
      setPlanPriceError("Price should be less then 1,00,000");
    } else {
      if (load === true) {
        return;
      }
      setLoad(true);
      const res = await UpdateCoachingPlan(
        name,
        planDetails,
        planCharge,
        isTax,
        planId
      );
      if (res === true) {
        setLoad(false);
        setAdd(false);
        setEdit(false);
      } else {
        setErrorHeader("Error");
        setErrorMessage(`${res.message}`);
        setErrorPopup(true);
        setLoad(false);
      }
    }
  }

  // word counter
  const countWords = (text) => {
    return text.length;
  };

  const handleNameChange = (e) => {
    const newValue = e.target.value;
    setName(newValue);
    setWordCountName(countWords(newValue));

    if (newValue) {
      setPlanNameError("");
    }
  };

  const handleTaglineChange = (e) => {
    const newValue = e.target.value;
    setTagline(newValue);
    setWordCountTagline(countWords(newValue));

    if (newValue) {
      setPlanTaglineError("");
    }
  };

  const handleDetailsChange = (content, delta, source, editor) => {
    setPlanDetails(content);

    // Use unprivileged editor methods
    const plainText = editor.getText(); // Get plain text (without HTML tags)
    const html = editor.getHTML(); // Get full HTML content
    const length = editor.getLength(); // Get character count (excluding HTML tags)

    setWordCountDetails(length - 1);

    if (content) {
      setPlanDesError("");
    }
  };

  const handlePlanCharges = (e) => {
    setPlanCharge(e.target.value);
    if (e.target.value <= 100000) {
      setPlanPriceError("");
    } else {
      setPlanPriceError("Price should be less then 1,00,000");
    }
  };

  const quillRef = useRef(null);

  const handleEditorFocus = () => {
    if (quillRef.current) {
      const editor = quillRef.current.getEditor();
      editor.format("list", "bullet"); // Set the list format to "bullet" (unordered list)
    }
  };

  return (
    <>
      {errorPopup && (
        <>
          <div className="delete_blur cdcds"></div>
          <ErrorPopup
            message={{
              heading: errorHeader,
              message: errorMessage,
            }}
            setErrorPopup={setErrorPopup}
          />
        </>
      )}
      <div className="popup_box z-[120]">
        <div className=" text-center bg-[var(--light-yellow)] py-[.8rem]">
          {edit ? (
            <h4 className="font-light text-[var(--blue)]">
              Edit Coaching Plan
            </h4>
          ) : (
            <h4 className="font-light text-[var(--blue)]">Add Coaching Plan</h4>
          )}
        </div>
        <div className="inputs_box">
          <div className="input_lable_div relative">
            <label className="absolute top-[-0.6rem] left-3 bg-[var(--white)] px-1">
              Name
            </label>
            <input value={name} type="text" onChange={handleNameChange} />
            {planNameError && (
              <span className="text-[var(--red)] text-[.8rem] mt-1">
                {planNameError}
              </span>
            )}
            {wordCountName > 50 && (
              <label className="name_counter"> ({wordCountName}/100)</label>
            )}
          </div>

          <div className="input_lable_div relative">
            <label className="absolute top-[-0.6rem] left-3 bg-[var(--white)] px-1">
              Tagline
            </label>
            <input value={tagline} type="text" onChange={handleTaglineChange} />
            {planTaglineError && (
              <span className="text-[var(--red)] text-[.8rem] mt-1">
                {planTaglineError}
              </span>
            )}
            {wordCountTagline > 50 && (
              <label className="name_counter"> ({wordCountTagline}/50)</label>
            )}
          </div>

          <div className="input_lable_div  relative">
            <label className="absolute top-[-0.6rem] left-3 bg-[var(--white)] px-1 z-10">
              Plan Details{" "}
            </label>
            <ReactQuill
              ref={quillRef}
              theme="snow"
              value={planDetails}
              onChange={handleDetailsChange}
              onFocus={handleEditorFocus} // Apply bullet list on focus
              modules={{
                toolbar: [
                  [{ list: "bullet" }], // Only show bullet list option in toolbar
                ],
              }}
            />

            {planDesError && (
              <span className="text-[var(--red)] text-[.8rem] mt-1">
                {planDesError}
              </span>
            )}
            {wordCountDetails > 900 && (
              <label className="name_counter"> ({wordCountDetails}/1000)</label>
            )}
          </div>

          <div className="input_lable_div mb-0 relative">
            <label className="absolute top-[-0.6rem] left-3 bg-[var(--white)] px-1">
              Monthly fee (including taxes)
            </label>
            <input
              type="number"
              value={planCharge}
              onChange={handlePlanCharges}
            />
            {planPriceError && (
              <span className="text-[var(--red)] text-[.8rem] mt-1">
                {planPriceError}
              </span>
            )}
          </div>

          {edit ? (
            <div className="add_btn_poup" onClick={handleEdit}>
              <span
                className="text-[var(--black)] bg-[var(--light-grey)] mr-4"
                onClick={() => {
                  setAdd(false);
                  setEdit(false);
                }}
              >
                Cancel
              </span>
              {load ? (
                <Button
                  loading
                  className="load_btn_plan bg-transparent"
                ></Button>
              ) : (
                <span className="plan_action_btn">Save</span>
              )}
            </div>
          ) : (
            <div className="add_btn_poup">
              <span
                className="text-[var(--black)] bg-[var(--light-grey)] mr-4"
                onClick={() => {
                  setAdd(false);
                  setEdit(false);
                }}
              >
                Cancel
              </span>
              {load ? (
                <Button loading className="load_btn"></Button>
              ) : (
                <span className="plan_action_btn" onClick={handleAdd}>
                  Add Plan
                </span>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default AddPopup;
