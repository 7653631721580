import { getApis } from "hooks/api";
import { API_URL_CONSTANTS } from "utility/constants/apiUrlConstants";
import React, { useState, useEffect } from "react";

function ViewCoachingPlan({ refId }) {
  const [planDetails, setPlanDetails] = useState([]);
  const apiUrl = `${API_URL_CONSTANTS.GET_COACHING_PLANS}?usRefId=${refId}`;

  useEffect(() => {
    const fetchCoachPlan = async () => {
      try {
        const res = await getApis(apiUrl);
        if (res && !res.error) {
          setPlanDetails(res.data.coaching_plans);
        }
      } catch (error) {
        console.error("Error fetching coach details:", error);
        setPlanDetails([]);
      }
    };
    fetchCoachPlan();
  }, [apiUrl]);

  return (
    <div className="items-center">
      <p>Coaching Plans</p>
      <div className="my_coaching_plan_section flex justify-center lg:justify-center mb-14">
        <div className="viewCoach">
          {planDetails.length === 4 ? (
            <div className="grid grid-cols-2 gap-4 justify-between">
              {planDetails.map((data) => (
                <div className="card_body w-full md:w-auto" key={data.name}>
                  <div className="card_main">
                    <div className="card_heading">
                      <h4>{data.plan_name}</h4>
                      <div className="card_info">
                        <p
                          className="-mt-3 text-[1.2rem] ql-editor list-disc list-inside"
                          dangerouslySetInnerHTML={{ __html: data.description }}
                        ></p>
                      </div>
                    </div>
                    <div className="card_btn_main">
                      <p>
                        ₹{data.plan_charge}{" "}
                        <span className="text-[.9rem]">per month</span>
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="my_coaching_plan_section ">
              <div className="viewCoach justify-start lg:justify-center xl:justify-center">
                {planDetails.length > 0 ? (
                  planDetails.map((data) => (
                    <div className="card_body" key={data.name}>
                      <div className="card_main">
                        <div className="card_heading">
                          <h4>{data.plan_name}</h4>
                          <div className="card_info">
                            <p
                              className="-mt-3 text-[1.2rem] ql-editor list-disc list-inside"
                              dangerouslySetInnerHTML={{
                                __html: data.description,
                              }}
                            ></p>
                          </div>
                        </div>
                        <div className="card_btn_main">
                          <p>
                            ₹{data.plan_charge}{" "}
                            <span className="text-[.9rem]">per month</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="text-[1.2rem]">Comming Soon!</p>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ViewCoachingPlan;
