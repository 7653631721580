import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { AppProps } from "utility/constants/useContext";
function Notification(props) {
  const { setDeletePopup } = props;

  const AppData = useContext(AppProps);

  // Pending Verification

  return (
    <>
      <div className="nofication_div">
        {AppData.coachSignUpStatus === "Pending Verification" && (
          <div className="messsage_div">
            <p>Your coach account verification is pending</p>
            {/* <p>
              <span className="click_here_btn">
                <Link
                  className="text-[var(--white)] pr-[0.2rem]"
                  to="/viewcoachform"
                >
                  Click here
                </Link>
              </span>
              to view/edit your request.
            </p> */}
            <p>
              <span
                className="click_here_btn bg-[var(--dark-red)] text-[var(--white)]"
                onClick={() => setDeletePopup(true)}
              >
                Delete
              </span>
              Delete your coach account request.
            </p>
          </div>
        )}
        {/* {AppData.coachSignUpStatus === "Need Action" && (
          <div className="messsage_div">
            <p>Coach Request Submitted</p>
            <p>
              <span className="click">
                <Link className="li pr-[0.2rem]" to="/editcoachform">
                  Click here
                </Link>
              </span>
              Update Your Coach Request.
            </p>
          </div>
        )} */}
      </div>
    </>
  );
}

export default Notification;
