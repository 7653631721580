import React, { useEffect, useState } from "react";
import Download from "assets/icons/download.svg";
import Info from "assets/icons/info.svg";
import "rsuite/dist/styles/rsuite-default.css";
import { Button, Popover, Whisper } from "rsuite";
import More from "assets/icons/more.svg";
import { compareDate } from "api/BillingPageController.js";
import { PaynowBox } from "api/BillingPageController";
import { getSessionId } from "api/BillingPageController";
import { load } from "@cashfreepayments/cashfree-js";
import { useNavigate, Link } from "react-router-dom";
import ErrorPopup from "components/popups/ErrorPopup.jsx";

function Billing() {
  const myBilling = true;
  const [errorPopup, setErrorPopup] = useState(false); // error popup
  const [errorHeader, setErrorHeader] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigateToBilling = useNavigate();
  const [isMore, setMore] = useState(false);
  const [isData, setData] = useState(false);
  let dateGap;
  let cashfree;
  const planDate = compareDate();

  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const token =
      localStorage.getItem("token") ?? sessionStorage.getItem("token");

    if (token) {
      return;
    } else {
      navigateToBilling("/");
    }
  }, []);

  // JSON
  const BillingData = [
    {
      id: "TS2425-0011",
      billing_period_from: "08-04-2024",
      billing_period_to: "08-05-2024",
      payment_status_code: 1,
      discount: 0.0,
      taxes: 957.63,
      total: 3000.0,
      status: "Unpaid",
      "paid on": "07-04-2024 10:10:10",
    },
    {
      id: "TS2425-0343",
      billing_period_from: "15-03-2024",
      billing_period_to: "15-04-2024",
      payment_status_code: 1,
      discount: 50.0,
      taxes: 625.0,
      total: 2000.0,
      status: "Paid",
      "paid on": "16-03-2024 09:30:45",
    },
    {
      id: "TS2425-0354",
      billing_period_from: "01-05-2024",
      billing_period_to: "01-06-2024",
      payment_status_code: 2,
      discount: 0.0,
      taxes: 380.0,
      total: 1500.0,
      status: "Paid",
    },
    {
      id: "TS2425-0031",
      billing_period_from: "20-02-2024",
      billing_period_to: "20-03-2024",
      payment_status_code: 3,
      discount: 20.0,
      taxes: 200.0,
      total: 1000.0,
      status: "Paid",
    },
  ];

  //insitialze cashfree res
  let insitialzeSDK = async function () {
    cashfree = await load({
      mode: "sandbox",
    });
  };
  insitialzeSDK();
  //------------------------------------

  // PayNow click fuction
  async function handlePayNow(e) {
    e.preventDefault();
    function checkPopUpBlocker() {
      // Attempt to open a new window
      const popupWindow = window.open("", "_blank", "width=100,height=100");

      // Check if the window was blocked by the browser's pop-up blocker
      if (popupWindow === null || typeof popupWindow === "undefined") {
        setErrorHeader("Pop-up blocker");
        setErrorMessage(
          "Pop-up blocker is enabled. Disable your Pop-up blocker"
        );
        setErrorPopup(true);
        return true;
      } else {
        // Close the window if it was successfully opened
        popupWindow.close();

        return false;
      }
    }

    // Call the function to check for pop-up blocker
    const popUpBlocked = checkPopUpBlocker();

    // cashfree code for order checkout
    try {
      const sessionId = await getSessionId();
      let checkoutOptions = {
        paymentSessionId: sessionId,
        redirectTarget: "_modal",
      };

      cashfree.checkout(checkoutOptions).then((res) => {});
    } catch (error) {
      return error;
    }
  }

  return (
    <>
      {errorPopup && (
        <>
          <div className="blur"></div>
          <ErrorPopup
            message={{
              heading: errorHeader,
              message: errorMessage,
            }}
            setErrorPopup={setErrorPopup}
          />
        </>
      )}

      {isData ? (
        <>
          <div className="min-h-screen bg-[var(--light-grey)] text-[var(--blue)]">
            {/* ----- heading box --------*/}
            <div className="text-[var(--blue)] w-[95%] my-16 mx-auto">
              <h1 className="mb-8 font-thin pt-4 text-[var(--blue)]">
                {" "}
                <Link to="/">&#10094; My Billing</Link>
              </h1>
            </div>
            {/* ---- Billing Details box -----  */}
            <div className="w-[95%] my-0 mx-auto billing_detail_box ">
              <div>
                <p className="m-0 mb-[0.3rem] font1rem text-[var(--darkgrey)] -mt-12">
                  YOUR MEMBERSHIP
                </p>
              </div>
              <div className="bg-white p-[0.7rem]">
                <div className="text-[var(--blue)] font1-2rem mb-4">
                  <p className="m-0 text-[var(--darkgrey)]">Plan</p>
                  <p className="text-[var(--blue)] mt-[-0.09rem]">
                    HALF MARATHON LEVEL 2 <br />
                    5000$/month
                  </p>
                  <p className="text-[var(--darkgrey)] text-[0.8rem] mt-[-1px]">
                    Plan period: {PaynowBox.billing_period_from} To{" "}
                    {PaynowBox.billing_period_to}
                  </p>
                </div>

                <div className="w-[98%] flex flex-row justify-between text-[var(--blue)]">
                  <div>
                    <p className="text-[var(--grey)] text-[1.2rem]">
                      {planDate ? "Due date was" : "Due Date"}
                    </p>
                    <p className="inline-block text-[var(--blue)] text-[1.2rem] !mt-[-1px]">
                      {PaynowBox.billing_period_from}
                    </p>
                    {planDate && (
                      <span className=" text-[.8rem] text-[var(--red)] ml-[0.4rem]">
                        Over due by {planDate} days
                      </span>
                    )}
                  </div>

                  <div className="paynow_div">
                    <button
                      onClick={handlePayNow}
                      className="font-thin font1rem py-[0.7rem] px-[1rem] bg-[var(--dark-yellow)] text-[var(--blue)] rounded-[2px] border-none outline-none pay_btn"
                    >
                      Pay Now
                    </button>
                  </div>
                </div>
              </div>
              {/* ---- Billing Details last para -------*/}
              <div>
                <p className="m-0 mt-[0.3rem] text-[var(--darkgrey)]">
                  Membership fees are billed at the beginning of each period and
                  may take time the billing Details appear on your account
                </p>
              </div>
            </div>
            ;<div className="para_bottom_line hidden"></div>
            {/*-------- Billing History Box desktop =------*/}
            <div className=" w-[95%] my-0 mx-auto mt-6 bg-white billing_history_box ">
              <div className="py-4 px-[0.8rem]">
                <span className="text-[var(--darkgrey)]">View</span>
                <select name="" id="">
                  All Entries
                </select>
              </div>
              <div className="">
                <div className="billing_his_heading">
                  <h4>Invoice id</h4>
                  <h4>Invoice Date </h4>
                  <h4>Due Date</h4>
                  <h4 className="Billing_period">Billing period </h4>
                  <h4>Total </h4>
                  <h4>Status</h4>
                  <h4>Invoice </h4>
                </div>
                {BillingData.map((items) => (
                  <div className="relative grid border-b border-b-[#dcdcdc] custom-grid py-0 px-[0.8rem]">
                    <p className="py-4 px-0">1232324#</p>
                    <p className="py-4 px-0">{items.billing_period_from}</p>
                    <p className="py-4 px-0">{items.billing_period_to}</p>
                    <p className="py-4 px-0">
                      {items.billing_period_from +
                        " To " +
                        items.billing_period_to}
                    </p>
                    <p className="py-4 px-0">₹{items.total}</p>
                    <p
                      className={`py-4 px-0 ${
                        items.status === "Paid"
                          ? "text-[var(--green)]"
                          : "text-[var(--red)]"
                      }`}
                    >
                      &#9679; {items.status}
                    </p>
                    <p className="text-[#0085ff] py-4 px-2">
                      <img src={Download} alt="" />
                    </p>
                    <Whisper
                      followCursor
                      className="ml-20"
                      placement="leftEnd"
                      speaker={
                        // <Popover
                        //   className="shadow-[rgba(0, 0, 0, 0.247) 0px 0px 4px 0px] justify-start fle"
                        // >
                        <div className="text-[var(--blue)] popup_boxes rounded-none p-4">
                          <div className="pb-[0.5rem]">
                            <p className="text-[var(--darkgrey)] ">Plan</p>
                            <p className=" -mt-1">HALF MARATHON LEVEL 2</p>
                          </div>
                          <div className="pb-[0.5rem] ">
                            <p className="text-[var(--darkgrey)]  -mt-1">
                              Paid on
                            </p>
                            <p className="-mt-1">5/3/2024</p>
                          </div>
                          <div className="flex flex-row justify-between border-t-2 border-t-[var(--light-grey)] pt-2">
                            <p className="text-[var(--darkgrey)]">
                              Plan Amount :
                            </p>
                            <p className="mt-[-0.2px]">₹5500</p>
                          </div>
                          <div className="flex flex-row justify-between mt-[-0.25rem] border-b-2 border-b-[var(--light-grey)] pt-1 pb-2">
                            <p className="text-[var(--darkgrey)] ">
                              {" "}
                              Discount :
                            </p>
                            <p className="mt-[-0.2px]">- ₹500</p>
                          </div>
                          <div className="flex flex-row justify-between pt-2">
                            <p className="text-[var(--darkgrey)] ">
                              Amount Paid :
                            </p>
                            <p className="mt-[-0.1px] text-[var(--green)]">
                              ₹5000
                            </p>
                          </div>
                        </div>
                        // </Popover>
                      }
                    >
                      <img
                        onClick={() => setMore(!isMore)}
                        className="py-[1.3rem]"
                        src={More}
                        alt=""
                      />
                    </Whisper>
                  </div>
                ))}
              </div>

              {/* ---- Billing Paging section ---------*/}
              <div className="flex flex-row justify-between py-4 px-[0.8rem]">
                <div>
                  <span className="text-[var(--darkgrey)]">Show</span>
                  <select name="" id="">
                    10
                  </select>
                  <span className="text-[var(--darkgrey)]">entries</span>
                </div>

                <div>
                  <span className="p-2 bg-[#edf7fb]">Previous</span>
                  <span className="text-[#0085ff] p-2 bg-[#97dbfab1] mx-2 my-0">
                    1/9
                  </span>
                  <span className="p-2 bg-[#edf7fb]">Next</span>
                </div>
              </div>
            </div>
            {/* ----- Billing History Card Phone ------ */}
            {BillingData.map((items) => (
              <div className=" billing_history_card">
                {/* Invoice date, due date, Invoice id section */}
                <div className="text-[#0085ff] flex justify-end -pt-12 px-0">
                  <Whisper
                    followCursor
                    placement="leftEnd"
                    speaker={
                      // <Popover
                      //   style={{
                      //     boxShadow: " rgba(0, 0, 0, 0.247) 0px 0px 4px 0px",
                      //   }}
                      // >
                      <div className="text-[var(--blue)] popup_box rounded-none p-4 w-auto">
                        <div className="pb-[0.5rem]">
                          <p className="text-[var(--darkgrey)] ">Plan</p>
                          <p className=" -mt-1">HALF MARATHON LEVEL 2</p>
                        </div>
                        <div className="pb-[0.5rem] ">
                          <p className="text-[var(--darkgrey)]  -mt-1">
                            Paid on
                          </p>
                          <p className="-mt-1">5/3/2024</p>
                        </div>
                        <div className="flex flex-row justify-between border-t-2 border-t-[var(--light-grey)] pt-2">
                          <p className="text-[var(--darkgrey)]">
                            Plan Amount :
                          </p>
                          <p className="mt-[-0.2px]">₹5500</p>
                        </div>
                        <div className="flex flex-row justify-between mt-[-0.25rem] border-b-2 border-b-[var(--light-grey)] pt-1 pb-2">
                          <p className="text-[var(--darkgrey)] "> Discount :</p>
                          <p className="mt-[-0.2px]">- ₹500</p>
                        </div>
                        <div className="flex flex-row justify-between pt-2">
                          <p className="text-[var(--darkgrey)] ">
                            Amount Paid :
                          </p>
                          <p className="mt-[-0.1px] text-[var(--green)]">
                            ₹5000
                          </p>
                        </div>
                      </div>
                      // </Popover>
                    }
                  >
                    <img src={Info} alt="info" />
                  </Whisper>
                </div>

                <div className="flex flex-row justify-between mb-[1.1rem]">
                  <div>
                    <div className="inline-block">
                      <p className="text-[var(--darkgrey)]">Invoice Date</p>
                      <p>{items.billing_period_from}</p>
                    </div>
                    <div className="inline-block">
                      <p className="text-[var(--darkgrey)]">Due Date</p>
                      <p>{items.billing_period_to}</p>
                    </div>
                  </div>
                  <div className="text-center">
                    <p className="text-[var(--darkgrey)] m-0">Invoice Id</p>
                    <p className="m-0">1234#</p>
                  </div>
                </div>
                {/* -- Service period , Status section -- */}
                <div className="flex row-flex justify-between mb-[1.1rem]">
                  <div className="service_period">
                    <p className="text-[var(--darkgrey)] m-0">Servie period</p>
                    <p className="m-0">
                      {items.billing_period_from +
                        " TO " +
                        items.billing_period_to}
                    </p>
                  </div>
                  <div className="text-[var(--green)] text-right">
                    <p className="text-[var(--darkgrey)] m-0">Status</p>
                    <p
                      className={
                        items.status === "Paid"
                          ? "text-[var(--green)] m-0"
                          : "text-[var(--dark-red)] m-0"
                      }
                    >
                      &#9679; {items.status}
                    </p>
                  </div>
                </div>
                {/* -- download, total section -- */}
                <div className="flex flex-row justify-between ">
                  <p className=" underline text-[var(--darkgrey)] m-0">
                    Download Invoice
                  </p>
                  <p className="font1-2rem text-[var(--blue)] m-0">
                    ₹{items.total}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <>
          <div className="relative min-h-screen bg-[var(--light-grey)] mt-16 mx-auto">
            <div className="text-[var(--blue)] w-[95%] my-0 mx-auto">
              <h1 className="m-0 mb-8 font-thin pt-4 ">
                {" "}
                <Link to="/">&#10094; My Billing</Link>
              </h1>
              <h3 className="absolute top-1/2 left-1/2  -translate-x-1/2 -translate-y-1/2 font-thin text-[#9e9e9e]">
                No Billing History
              </h3>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Billing;

// Popup placement
// topStart
// top
// topEnd
// leftStart
// left
// leftEnd
// rightStart
// right
// rightEnd
// bottomStart
// bottom
// bottomEnd
