import React from "react";

function EmailInput(props) {
  const { email, setEmail, validateEmail, isDisable } = props;
  return (
    <>
      <input
        className="form_inputs"
        type="text"
        name="email"
        value={email}
        placeholder="Email Address"
        onChange={(e) => {
          setEmail(e.target.value);
          validateEmail(e.target.value);
        }}
        disabled={isDisable}
      />
    </>
  );
}

export default EmailInput;
