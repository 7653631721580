import React from "react";
import { Link } from "react-router-dom";
function NotFound() {
  return (
    <div>
      {" "}
      <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
        <h1 className="text-6xl font-bold text-[var(--red)] mb-4">404</h1>
        <p className="text-2xl mb-6  text-center">
          Oops! The page you're looking for doesn't exist.
        </p>
        <Link
          to="/"
          className="text-white bg-[var(--blue)] font-semibold py-2 px-4 rounded"
        >
          Go Back Home
        </Link>
      </div>
    </div>
  );
}

export default NotFound;
