import React, { useEffect, useState } from "react";
import { GetMyCoachingDetails } from "api/UserAccountController";

function MyCoaching() {
  const [getData, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await GetMyCoachingDetails();
        if (res) {
          setData(res.data.plans);
        }
      } catch (error) {
        return error;
      }
    };
    fetchData();
  }, []);

  return (
    <>
      {getData.length === 0 ? (
        <></>
      ) : (
        <>
          {" "}
          {getData.map((data) => {
            <div key={data.trainingSince} className="setting_box">
              <div className="profile_box_heading_div">
                <h2>My Coaching</h2>
                <p className="text-[var(--blue)]">
                  {data.plan_name}
                </p>
              </div>
              <div>
                <div className="input_info_box">
                  <div className="first_half">
                    <p className="lable_tag">Training With</p>
                    <p className="lable_value ">{data.coach} </p>
                  </div>
                  <div className="second_half">
                    <p className="lable_tag">Fees</p>
                    <p className="lable_value text-[var(--green)]" >
                      {data.fees}
                    </p>
                  </div>
                </div>
                <div className="input_info_box">
                  <div className="first_half">
                    <p className="lable_tag">Training Since</p>
                    <p className="lable_value ">{data.training_since}</p>
                  </div>
                  <div className="second_half">
                    <p className="lable_tag">Coaching Status</p>
                    <p className="lable_value ">{data.coaching_status}</p>
                  </div>
                </div>
              </div>
            </div>;
          })}
        </>
      )}
    </>
  );
}

export default MyCoaching;
