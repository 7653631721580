import React, { useContext, useEffect, useState } from "react";
import SendOtp from "components/otp/SendOtp";
import ErrorPopup from "components/popups/ErrorPopup";
import EmailInput from "components/inputs/EmailInput";
import { Buffer } from "buffer";
import { Button } from "rsuite";
import { sendOtp, submitDataSignUp, verifyEmail } from "api/Signup";
import check from "assets/icons/checks.png";
// import "rsuite/dist/styles/rsuite-default.min.css";
import SuccessPopup from "components/popups/SuccessPopup";
import { ERROR_CONSTANTS } from "utility/constants/errorConstants";
import PasswordInput from "components/inputs/PasswordInput";
import { AppProps } from "utility/constants/useContext";
import show from "assets/icons/OpenEye.svg";
import nonShow from "assets/icons/CloseEye.svg";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";

function SignUpPopUp(props) {
  const today = dayjs();
  const { setSignUp, isSignUp } = props;
  const AppData = useContext(AppProps);
  const [dateOfBirth, setDateOfBirth] = useState();
  const [load, setLoad] = useState(false);
  const [otpVerify, setOtpVerify] = useState(false);
  const [isSendOtp, setSendOtp] = useState(false);
  const [done, setDone] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // const [dd, setDd] = useState("");
  // const [mm, setMm] = useState("");
  // const [yyyy, setYyyy] = useState("");
  const [emailExist, setEmailExist] = useState(false);
  const [activity, setActivity] = useState("");
  const [gender, setGender] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isBlur, setBlur] = useState(false);
  const [isDisable, setDisable] = useState(true);
  const [isValidEmail, setValidEmail] = useState(false);
  const [below18, setBelow18] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [TAC, setTAC] = useState(false);
  const [ads, setAds] = useState(true);
  const [errorPopup, setErrorPopup] = useState(false); // error popup
  const [errorHeader, setErrorHeader] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isSignupDone, setIsSignupDone] = useState(false); // checks if we have to show the success popup for sign up
  const [clickHere, setClickHere] = useState(false); // checks if click here btn is clicked or not
  const [signupDisable, setSignupDisable] = useState(true);
  const hundredYearsAgo = today.subtract(100, "year");
  const [formattedDate, setFormattedDate] = useState("");

  const eighteenYearsAgo = today.subtract(18, "year");

  // const signupDisable =
  //   !name || !email || !password || !TAC || !gender || !activity;
  const [count, setCount] = useState(1);
  useEffect(() => {
    if (
      !name ||
      !email ||
      !password ||
      !TAC ||
      !gender ||
      !activity ||
      // !dd ||
      // !mm ||
      // !yyyy ||
      isNaN(dateOfBirth) // Change this to check if dateOfBirth is valid
    ) {
      setSignupDisable(true);
    } else {
      setSignupDisable(false);
    }
  }, [name, email, password, TAC, gender, activity, dateOfBirth]);

  useEffect(() => {
    if (!isSignUp) {
      setName("");
      setEmail("");
      setSendOtp(false);
      setOtpVerify(false);
      setDone(false);
      setDisable(true);
      setActivity("");
      setGender("");
      setPassword("");
      // setDd("");
      // setMm("");
      // setYyyy("");
      setAds(ads);
      setTAC(TAC);
    }
  }, [isSignUp]);

  // API call for send otp
  async function handleSendOtp(e) {
    e.preventDefault();

    setLoad(true);
    if (name === "" || email === "") {
      setLoad(false);
      setClickHere(true);
    } else {
      if (load === true) {
        return;
      }
      const req = await verifyEmail(email);
      if (req === true) {
        setLoad(false);
        setEmailExist(true);
        setClickHere(false);
      } else {
        setLoad(false);
        const req = await sendOtp(email);
        if (req === true) {
          if (isValidEmail === true) {
            if (count > 3) {
              setErrorHeader("Try After 10min");
              setErrorMessage("");
              setErrorPopup(true);
              setTimeout(() => {
                setCount(0);
              }, 60000);
            } else {
              setCount(count + 1);
              setSendOtp(true);
              setBlur(true);
              setEmailExist(false);
            }
          } else {
            setErrorHeader("Error");
            setErrorMessage("Enter a valid Email Address");
            setErrorPopup(true);
          }
        } else {
          setErrorHeader(ERROR_CONSTANTS.NetworkError.Heading);
          setErrorMessage(ERROR_CONSTANTS.NetworkError.Message);
          setErrorPopup(true);
        }
      }
    }
  }

  // remove otp popup
  useEffect(() => {
    if (done === true) {
      setSendOtp(false);
    }
  }, [done]);

  // -------------- EMAIL VALIDATION ----------------
  function validateEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setValidEmail(false);
    } else {
      setValidEmail(true);
    }
  }

  // -------------- password VALIDATION ----------------
  function validatePassword(password) {
    const passwordRegex = /^(?=.*[!@#$])[a-zA-Z0-9!@#$]{6,12}$/;
    if (!passwordRegex.test(password)) {
      setPasswordError(
        "Enter a 6 to 12 character password (a-z, A-Z, 0-9, !@#$) with at least one special character."
      );
    } else {
      setPasswordError("");
    }
  }

  // SIGUP POPUP CLOSE
  function handleClose() {
    setSignUp(false);
    AppData.setOpen(false);
  }
  // ------------- Date validation --------------------
  function ageDff(dateOfBirth) {
    const formattedDate = dayjs(dateOfBirth).format("DD-MM-YYYY");

    const [sd, sm, sy] = formattedDate.split("-").map(Number);
    const today = new Date();
    const currentMonth = today.getMonth(); // 0-11
    const currentYear = today.getFullYear();
    const currentDate = today.getDate();

    const userDate = new Date(sy, sm - 1, sd);

    let yearDifference = currentYear - userDate.getFullYear();

    const hasHadBirthdayThisYear =
      currentMonth > userDate.getMonth() ||
      (currentMonth === userDate.getMonth() &&
        currentDate >= userDate.getDate());

    const adjustedYearDifference = hasHadBirthdayThisYear
      ? yearDifference
      : yearDifference - 1;

    if (adjustedYearDifference < 18 && adjustedYearDifference >= 0) {
      setBelow18(true);
    } else {
      setBelow18(false);
    }
  }

  function handleDateChange(newValue) {
    const formattedDate = dayjs(newValue).format("DD-MM-YYYY");
    setFormattedDate(formattedDate);
    setDateOfBirth(newValue);
    ageDff(formattedDate);
  }

  // dd validation
  // function handleDd(e) {
  //   if (e.target.value > 31 || e.target.value < 1) {
  //     e.target.value = e.target.value.slice(0, -1);
  //   } else {
  //     setDd(e.target.value);
  //   }
  //   ageDff(e.target.value, mm, yyyy);
  // }

  // month validation
  // function handleMm(e) {
  //   if (e.target.value > 12 || e.target.value < 0) {
  //     e.target.value = e.target.value.slice(0, -1);
  //   } else {
  //     setMm(e.target.value);
  //   }
  //   ageDff(dd, e.target.value, yyyy);
  // }

  // year validation
  // function handleYyyy(e) {
  //   setYyyy(e.target.value);
  //   ageDff(dd, mm, e.target.value);
  // }

  // ------------------------------- ON SUBMIT ----------------------------
  async function handleSubmit(e) {
    e.preventDefault();
    if (load === true) {
      return;
    }
    setLoad(true);

    if (!dateOfBirth) {
      setLoad(false);
      setErrorHeader("Date of Birth Required");
      setErrorMessage("Please select your date of birth.");
      setErrorPopup(true);
      return;
    }

    const buffer = Buffer.from(password);
    const encrPassword = buffer.toString("base64");
    const res = await submitDataSignUp(
      name,
      email,
      encrPassword,
      activity,
      gender,
      TAC,
      formattedDate
    );
    if (res === true) {
      setLoad(false);
      setIsSignupDone(true);
      setSignUp(false);
    } else {
      if (res === "Given data is not compatible.") {
        setErrorHeader(res);
        setErrorMessage("Can't use number in name field");
        setErrorPopup(true);
      }
      setLoad(false);
    }
  }

  return (
    <>
      {errorPopup && (
        <>
          <div className="blur"></div>
          <ErrorPopup
            message={{
              heading: errorHeader,
              message: errorMessage,
            }}
            setErrorPopup={setErrorPopup}
          />
        </>
      )}
      {isSendOtp && (
        <>
          <SendOtp
            setDone={setDone}
            email={email}
            setBlur={setBlur}
            setDisable={setDisable}
            setSendOtp={setSendOtp}
            setCount={setCount}
            count={count}
            setOtpVerify={setOtpVerify}
            otpVerify={otpVerify}
          />
        </>
      )}

      <>
        <SuccessPopup
          setIsSignupDone={setIsSignupDone}
          isSignupDone={isSignupDone}
          message={{
            heading: "Account created successfully!",
            message: "Sign In to get started.",
          }}
        />
      </>

      <form action="">
        {isBlur && <div className="delete_blur1 "></div>}
        <div className={`signup_box ${isSignUp ? "signup_box_visiable" : ""}`}>
          <div className="signup_heading">
            <h3>Create your Account</h3>
          </div>
          <div className="cancel_btn_signup" onClick={handleClose}>
            <p>Cancel</p>
          </div>
          {/*-------------- Name Email inputs ------------*/}
          <div className="sigup_inputs">
            <div className="mb-[.8rem]">
              <input
                className="form_inputs"
                type="text"
                name="name"
                value={name}
                placeholder="First and last name"
                required
                onChange={(e) => setName(e.target.value)}
              />
              {clickHere === true && name === "" ? (
                <>
                  <p className="error_message text-right">Fill Up</p>
                </>
              ) : (
                <> </>
              )}
            </div>
            <div className="mb=[.8rem]">
              <div className="form_guide_text">
                <p>Your Email address will be your username</p>
              </div>
              <EmailInput
                email={email}
                setEmail={setEmail}
                validateEmail={validateEmail}
                isDisable={done}
              />

              {clickHere === true && email === "" ? (
                <>
                  <p className="error_message text-right">Fill Up</p>
                </>
              ) : (
                <> </>
              )}
              <div className="error_message text-right">
                {emailExist && <p>Email exists</p>}
              </div>
              <div className="form_guide_text mb-[0.8rem]">
                {otpVerify ? (
                  <p className="flex ">
                    <span className="click_here_btn pl-2 pt-[2px] h-4">
                      {" "}
                      <img src={check} className="w-4 h-4 ml-[-3px]" />
                    </span>
                    Your email is verified
                  </p>
                ) : (
                  <p>
                    <span className="click_here_btn" onClick={handleSendOtp}>
                      {load ? (
                        <Button
                          loading
                          className="button bg-[var(--green)]"
                        ></Button>
                      ) : (
                        <span>Click here</span>
                      )}
                    </span>
                    to verify your email address to proceed.
                  </p>
                )}
              </div>
            </div>
          </div>
          {/*-------------- Gender INput -------------------*/}
          <div className="sigup_inputs gender_input_activity">
            <div className="margin_bottom_8">
              <select
                name="Primary Activity"
                id=""
                value={activity}
                disabled={isDisable}
                onChange={(e) => {
                  setActivity(e.target.value);
                }}
              >
                <option
                  className="text-[var(--grey)]"
                  value=""
                  disabled
                  selected
                  hidden
                >
                  Primary Activity
                </option>
                <option value="Runner">Runner</option>
                <option value="Duathlete">Duathlete</option>
                <option value="Triathlete">Triathlete</option>
                <option value="Swimmer">Swimmer</option>
                <option value="Biker">Biker</option>
              </select>
            </div>
            <div className="mb-[0.8rem]">
              <select
                name="Gender"
                id=""
                value={gender}
                disabled={isDisable}
                onChange={(e) => {
                  setGender(e.target.value);
                }}
              >
                <option
                  className="text-[var(--grey)]"
                  value=""
                  disabled
                  selected
                  hidden
                >
                  Gender
                </option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Trans">Don't want to say</option>
              </select>
            </div>
          </div>
          {/*--------- password input -------------*/}
          <div className="sigup_inputs password_box">
            <div className="mb-[.8rem]">
              <div className="form_guide_text">
                <p>Enter a 6 to 12 character password (a-z, A-Z, 0-9, !@#$)</p>
              </div>
              <div className="relative">
                <PasswordInput
                  password={password}
                  setPassword={setPassword}
                  showPassword={showPassword}
                  validatePassword={validatePassword}
                  isDisable={isDisable}
                />
                {/* <input
                  className="form_inputs"
                  value={password}
                  disabled={isDisable}
                  type={showPassword ? "text" : "password"}
                  name="email"
                  placeholder="Enter password"
                  required
                  onChange={(e) => {
                    setPassword(e.target.value);
                    validatePassword(e.target.value);
                  }}
                /> */}
                <label
                  className="show_password"
                  htmlFor=""
                  onClick={() => setShowPassword(!showPassword)}
                >
                  <img src={showPassword ? show : nonShow} alt="" />
                </label>
              </div>
              {passwordError && (
                <div className="error_message">{passwordError}</div>
              )}
            </div>
          </div>
          {/*--------------- Date INPUTS -----------*/}
          <div className="sigup_inputs">
            <div className="mb-[.8rem] ">
              <div className="form_guide_text">
                <p>
                  <span>Date of Birth </span>
                  <br />
                  {below18 && (
                    <span className="error_message">
                      {" "}
                      You need to be 18 years and older to create an account
                    </span>
                  )}
                  {!below18 && (
                    <span>
                      {" "}
                      You need to be 18 years and older to create an account
                    </span>
                  )}
                </p>
              </div>
              <div className="date_input !border-0">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      className="!w-[98%]"
                      minDate={hundredYearsAgo}
                      maxDate={eighteenYearsAgo}
                      views={["year", "month", "day"]}
                      format="DD-MM-YYYY"
                      value={dateOfBirth}
                      onChange={handleDateChange}
                      disabled={isDisable}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
            </div>
          </div>
          <div className="terms_condition_box">
            <input
              type="checkbox"
              id="terms"
              disabled={isDisable}
              checked={ads}
              value="ads"
              onChange={() => setAds(!ads)}
            />
            <label htmlFor="terms">Get Product News & Promotions</label>
          </div>
          <div className="terms_condition_box">
            <input
              type="checkbox"
              id="terms"
              disabled={isDisable}
              value="TAC"
              onChange={() => setTAC(!TAC)}
            />
            <label htmlFor="terms">
              I agree to the Terms of use,{" "}
              <a href="https://www.trackofit.com/privacypolicy.html">
                Privacy Policy{" "}
              </a>
              and <a href="">Refund Policy</a>
            </label>
          </div>

          <div className="signup_submit">
            {load ? (
              <Button
                loading
                className="button"
                style={{
                  backgroundColor: signupDisable ? "" : "#fdc027",
                  padding: "1rem 1.5rem",
                }}
              ></Button>
            ) : (
              <button
                onClick={handleSubmit}
                style={{
                  backgroundColor: signupDisable ? "" : "#fdc027",
                }}
                disabled={signupDisable}
              >
                Sign Up
              </button>
            )}
          </div>
          <div className=" padding_bottom_8 padding_top_8 bg-[#f4f4f4] text-center">
            <p className="text-[var(--blue)]">
              Already have a account?{" "}
              <span
                className="text-[var(--link)] cursor-pointer"
                onClick={() => {
                  setSignUp(false);
                  AppData.setOpen(true);
                }}
              >
                Sign in
              </span>
            </p>
          </div>
        </div>
      </form>
      {isSignUp && (
        <div
          onClick={handleClose}
          className="desktop_popup_background signup_desktop_popup_background"
        ></div>
      )}
    </>
  );
}

export default SignUpPopUp;
