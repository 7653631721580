import React, { useState, useEffect } from "react";
import Certificed from "assets/icons/certified.svg";
import FiveStar from "assets/icons/five_star.svg";
import Arow from "assets/icons/arow_more_details.svg";
import { Link } from "react-router-dom";
import Arows from "assets/icons/Group.svg";
import { getApis } from "hooks/api";
import { API_URL_CONSTANTS } from "utility/constants/apiUrlConstants";
import { lib } from "crypto-js/core";

const ViewCoachCard = ({ selectedActivity }) => {
  const pageNumber = 1;
  const pageSize = 20;
  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [coachList, setCoachList] = useState([]);

  const selectedActivityString =
    selectedActivity && selectedActivity.length > 0
      ? selectedActivity.join(",")
      : null;
  const apiUrl = `${
    API_URL_CONSTANTS.COACHES_LIST
  }?pageNumber=${pageNumber}&pageSize=${pageSize}${
    selectedActivityString ? `&categories=${selectedActivityString}` : ""
  }`;

  const [showDetails, setShowDetails] = useState(false);
  const [expandedAbout, setExpandedAbout] = useState({}); // State to track expanded about text

  useEffect(() => {
    const fetchCoachList = async () => {
      const res = await getApis(apiUrl);
      if (res && !res.error) {
        if (res.data && res.data.content) {
          setCoachList(res.data.content);
        }
      }
    };
    fetchCoachList();
  }, [apiUrl]);

  const handleToggleDetails = () => {
    setShowDetails(!showDetails);
  };

  const handleToggleAbout = (refId) => {
    setExpandedAbout((prev) => ({
      ...prev,
      [refId]: !prev[refId], // Toggle the specific about text based on refId
    }));
  };

  return (
    <div className="coach_card_desktop">
      {coachList.map((data) => {
        const isExpanded = expandedAbout[data.refId]; // Check if the about section is expanded
        const aboutText = isExpanded
          ? data.about
          : data.about && data.about.length > 0
          ? `${data.about.slice(0, 500)}`
          : "No information available."; // Default message if about is null or empty

        return (
          <div
            key={data.refId}
            className="bg-[var(--white)] w-[95%] mx-auto my-4 shadow p-2"
          >
            {/* Card body start */}
            <div className="flex pt-4 px-4">
              <div className="flex flex-col items-start py-2 w-[20%]">
                <img
                  className="rounded-[50%] w-[8rem] h-[8rem] object-cover"
                  src={`${API_URL_CONSTANTS.DOMAIN_NAME}${data.img}`}
                  alt=""
                />
                <div className="flex flex-col">
                  <h6 className="text-[1.3rem] text-[var(--blue)] font-normal pt-2">
                    {data.name}
                  </h6>
                  <span className="text-[.7rem]">
                    ( Coaching Since {data.coaching_since} )
                  </span>
                  <img className="w-[5rem]" src={FiveStar} alt="" />
                  <span className="text-[.7rem]">
                    ( Athlete trained {data.athlete_trained} )
                  </span>
                </div>
              </div>
              <div className="flex flex-col p-2 w-[80%]">
                <div
                  className=" text-[1.1rem] text-[var(--light-blue)] ql-editor"
                  dangerouslySetInnerHTML={{ __html: aboutText }}
                />

                {/* Show More / Show Less for about text */}
                {data.about && data.about.length > 500 && (
                  <div
                    className="cursor-pointer text-[var(--link)]"
                    onClick={() => handleToggleAbout(data.refId)}
                  >
                    {isExpanded ? "Show Less" : "... Show More"}
                  </div>
                )}

                <div className="flex justify-between ">
                  <div className="flex pt-[0.6rem] ">
                    {/* Coaches section */}
                    <div className=" flex items-center justify-center">
                      <h6 className="text-[var(--blue)] font-normal mr-2">
                        Coaches
                      </h6>
                      <div
                        className={` 2xl:flex items-center ${
                          showDetails ? "2xl:flex" : "2xl:flex"
                        }`}
                      >
                        <ul
                          className={`viewCoah_li  grid lg:grid-cols-2 !mb-0 ${
                            showDetails ? "2xl:flex" : "2xl:flex"
                          }`}
                        >
                          {data.coaches?.map((coach, index) => (
                            <>
                              {coach.value && !showDetails ? (
                                <li className=" ml-4" key={`coaches- ${index}`}>
                                  {coach.category}
                                </li>
                              ) : (
                                <>
                                  {coach.value && (
                                    <li className=" ml-4" key={index}>
                                      {coach.category}: {coach.value}
                                    </li>
                                  )}
                                </>
                              )}
                            </>
                          ))}
                        </ul>
                        {/* More Details section */}
                        <div
                          className=" flex items-center justify-end pl-4   cursor-pointer text-[var(--green)] text-[13px] underline "
                          onClick={handleToggleDetails}
                        >
                          {showDetails ? (
                            <>
                              <img
                                className="rotate-180 pl-2"
                                src={Arow}
                                alt=""
                              />
                              <span>Less Details</span>
                            </>
                          ) : (
                            <>
                              <span>More Details</span>
                              <img className="pl-2 w-5 h-5" src={Arow} alt="" />
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex justify-end pt-2 ">
              <p
                className={`text-[var(--blue)] flex text-[1rem] md:grid sm:grid lg:grid xl:grid justify-end ${
                  showDetails ? "2xl:!grid" : "2xl:flex"
                }`}
              >
                To View Profile
                <div className={`flex ${showDetails ? "ml-0" : "ml-2"}`}>
                  <Link
                    to="/coachdetails"
                    state={data.refId}
                    className="text-[0.9rem] text-[#007c3c] sm:ml-0 md:ml-0 xl:ml-0 mt-[2px]"
                  >
                    Click Here
                  </Link>
                  <img src={Arows} className="w-3 h-3 ml-1 mt-[6px]" />
                </div>
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ViewCoachCard;
