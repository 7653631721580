import React from "react";
import Logo from "components/logo/Logo";
import playStore from "assets/img/play-store.png";
import appStore from "assets/img/apple-store.png";

function PhoneFooter() {
  function handleClickScrollCoach() {
    const element = document.getElementById("coach");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }
  function handleClickScrollAbout() {
    const element = document.getElementById("about");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }
  return (
    <div
      className="phone_footer_section bg-[var(--blue)] px-[2rem] "
      id="footerP"
    >
      <div className="phone_footer_logo_div flex-col justify-center items-center h-[10rem] mt-[1rem]">
        <Logo />
        <div className="flex items-center">
          <a
            href="https://play.google.com/store/apps/details?id=com.isf.trackofit&pli=1"
            className="w-[5rem]"
          >
            <img className="w-screen" src={playStore} alt="playStore" />
          </a>
          <a
            href="https://apps.apple.com/in/app/trackofit/id6469041232"
            className="w-[4rem]"
          >
            <img className="w-screen" src={appStore} alt="appStore" />
          </a>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="footer_athletes_div">
          <h5>For An Athletes</h5>
          <a>Sign Up</a>
        </div>
        <div className="footer_coaches_div">
          <h5>Become a coach</h5>
          <a onClick={handleClickScrollCoach}>Sign Up</a>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="footer_support_div flex flex-col mb-[0.5rem]">
          <h5>Support</h5>
          <a href="mailto:support@trackofit.com">Help</a>
          <a href="mailto:support@trackofit.com">Contact Us</a>
          <a href="https://www.trackofit.com/privacypolicy.html">
            Privacy Policy
          </a>
        </div>
        <div className="footer_company_div">
          <h5>Company</h5>
          <a onClick={handleClickScrollAbout}>About</a>
        </div>
      </div>
    </div>
  );
}

export default PhoneFooter;
