import { useContext, useEffect, useRef, useState } from "react";
import ResetPassword from "components/resetPassword/ResetPassword";
import { verifyOtp } from "api/Signup";
import { Button } from "rsuite";
import OtpInput from "components/inputs/OtpInputs";
import { AppProps } from "utility/constants/useContext";

const ForgotPasswordOtp = ({
  length = 6,
  onOtpSubmit = () => {},

  setForgotPassword,
  email,
}) => {
  const [otp, setOtp] = useState(new Array(length).fill(""));
  const inputRefs = useRef([]);
  const [otpVerify, setOtpVerify] = useState(false);
  const [invalidOtp, setInvalidOtp] = useState(false);
  const [load, setLoad] = useState(false);
  const otpNo = otp[0] + otp[1] + otp[2] + otp[3] + otp[4] + otp[5];
  const AppData = useContext(AppProps);

  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  function handleCancel() {
    setForgotPassword(false);
    AppData.setOpen(false);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    if (load === true) {
      return;
    }
    setLoad(true);
    const res = await verifyOtp(email, otpNo);
    if (res === true) {
      setOtpVerify(true);
      setInvalidOtp(false);
      setLoad(false);
    } else {
      setOtpVerify(false); // true
      setInvalidOtp(true);
      setLoad(false);
    }
  }

  return (
    <>
      {!otpVerify ? (
        <div>
          {" "}
          <form onSubmit={handleSubmit}>
            <div className=" text-center">
              <span onClick={handleCancel} className="popup_cancel_btn">
                Cancel
              </span>
              <h3 className="forgotPassword_heading">Enter OTP</h3>
              <p className="form_guide_text">
                A One-time-password has been sent to your registered email
                address. Check your Inbox, including Spam/Junk.
              </p>
              {otp.map((value, index) => {
                return (
                  <OtpInput
                    otp={otp}
                    setOtp={setOtp}
                    index={index}
                    value={value}
                    onOtpSubmit={onOtpSubmit}
                    inputRefs={inputRefs}
                    length={length}
                  />
                );
              })}
              <div
                className="form_guide_text"
                style={{
                  marginBottom: ".8rem",
                  display: "flex",
                  justifyContent: "space-between",
                  width: "90%",
                  margin: "0 auto",
                }}
              >
                <p className="">Didn’t receive OTP? Resend</p>
                {invalidOtp && (
                  <span
                    className=""
                    style={{
                      color: "#e00000",
                      fontSize: ".7rem",
                      textAlign: "right",
                    }}
                  >
                    Invalid OTP
                  </span>
                )}
              </div>

              <div
                className="flex_center_div"
                style={{ paddingBottom: ".8rem" }}
              >
                {load ? (
                  <>
                    <div className="orange_btn">
                      <Button
                        loading
                        className="load_btn"

                      ></Button>
                    </div>
                  </>
                ) : (
                  <button className="orange_btn margin_top_8">Done</button>
                )}
              </div>
            </div>
          </form>
        </div>
      ) : (
        <div>
          <ResetPassword setForgotPassword={setForgotPassword} email={email} />
        </div>
      )}
    </>
  );
};
export default ForgotPasswordOtp;
