import React from "react";
import { useNavigate } from "react-router-dom";

function ErrorPopup(props) {
  const { message, setErrorPopup } = props;
  const navigate = useNavigate();
  return (
    <div>
      <div className="error_popup">
        <div className="heading_popup">
          <p>{message.heading}</p>
        </div>
        <div className="error_message">
          <p>{message.message}</p>
        </div>
        <div className="action_btns">
          <span
            onClick={() => {
              setErrorPopup(false);
            }}
          >
            Try Again
          </span>
        </div>
      </div>
    </div>
  );
}

export default ErrorPopup;
