import React from "react";
import ReactDOM from "react-dom/client";
import { HashRouter } from "react-router-dom";
import "./components.css";
import "./common.css";
import "./common_pages.css";
import "./mediaQuery.css";
import App from "./App";
import AuthReducer from "state/AppState";
import ViewAccountreducer from "state/ViewAccountState";
import CoachFromReducer from "state/CoachFormData";
import setCoachFormReducer from "state/SetCoachFormData";
import { configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";

// import {
//   persistStore,
//   persistReducer,
//   FLUSH,
//   REHYDRATE,
//   PAUSE,
//   PERSIST,
//   PURGE,
//   REGISTER
// } from "redux-persist"
// import storage from "redux-persist/lib/storage"
// import { PersistGate } from 'redux-persist/integration/react';

// change the app-auth the portal Name
// const persistConfig = { key: "app-data", storage, version: 1 };
// const persistreducer = persistReducer(persistConfig, AuthReducer);

const store = configureStore({
  reducer: {
    appData: AuthReducer,
    viewAccount: ViewAccountreducer,
    getCoachFromData: CoachFromReducer,
    setCoachFromData: setCoachFormReducer,
  },
  // middleware: (getDefaultMiddleware) =>
  // getDefaultMiddleware({
  //   serializableCheck: {
  //     ignoreActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
  //   },
  // }),
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    {/* <PersistGate loading={null} persistor={persistStore(store)}> */}
    <HashRouter>
      <App />
    </HashRouter>
    {/* </PersistGate> */}
  </Provider>
  // </React.StrictMode>
);
