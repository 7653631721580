import { clearLocalStorage } from "./basic";

export function throwError(error) {
  switch (error.response.status) {
    case 400: // Bad request/
      return "400";
    case 401: //Unauthorized
      clearLocalStorage();
      return "401";
    case 403: //Forbidden
      return "403";
    case 404: //Resource Not Found
      return "[Page Not Found, 3]";
    case 500: //Internal Server Error
      return "500";
    default:
      return error;
  }
}
