import React from "react";
import { Button } from "rsuite";
import "./popup.css";

function ActionPopup(props) {
  const { message, setDeletePopup, handleRemove, load } = props;

  return (
    <div className="action_popup">
      <div className="heading_popup">
        <p>{message.heading}</p>
      </div>
      <div className="action_message">
        <p>{message.message}</p>
      </div>
      <div className="action_btns">
        <span className="cancel_btn" onClick={() => setDeletePopup(false)}>
          Cancel
        </span>

        {load ? (
          <Button
            loading
            className="submit_btn"
            style={{ backgroundColor: "#a51319" }}
          ></Button>
        ) : (
          <span className="detele_btn" onClick={handleRemove}>
            Delete
          </span>
        )}
      </div>
    </div>
  );
}

export default ActionPopup;
