import { useState, useEffect } from "react";

export function useMobileOperatingSystem() {
  const [mobileOS, setMobileOS] = useState("unknown");

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setMobileOS("iOS");
    } else if (/android/i.test(userAgent)) {
      setMobileOS("Android");
    } else {
      setMobileOS("unknown");
    }
  }, []);

  return mobileOS;
}
