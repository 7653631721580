// photoHandlers.js

import { useState } from "react";
import { SubmitProfilePhoto, SubmitCoachForm } from "api/CoachFormController";
import { updateForm } from "state/SetCoachFormData";
import { ERROR_CONSTANTS } from "utility/constants/errorConstants.js";
import { EditProfilePhoto } from "api/CoachFormController";
import { useSelector, useDispatch } from "react-redux";
import { API_URL_CONSTANTS } from "utility/constants/apiUrlConstants";
import { getApis } from "hooks/api";
import { addCoachFormData } from "state/CoachFormData";
export const usePhotoHandlers = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.setCoachFromData);
  const [profilePhotoURL, setProfilePhotoURL] = useState(null);
  const [profilePhotoPath, setProfilePhotoPath] = useState(null);
  const [errorHeader, setErrorHeader] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorPopup, setErrorPopup] = useState(false);

  async function checkStatusProfilePhoto(file, actionType, isCoach) {
    if (actionType === "submit") {
      const req = await SubmitProfilePhoto(file);
      if (req.success === true) {
        setProfilePhotoURL(URL.createObjectURL(file));
        setProfilePhotoPath(req.path);

        dispatch(updateForm({ profilePhotoPath: req.path }));
        dispatch(updateForm({ getProfile: URL.createObjectURL(file) }));
      } else {
        setErrorHeader(ERROR_CONSTANTS.NetworkError.Heading);
        setErrorMessage(ERROR_CONSTANTS.NetworkError.Message);
        setErrorPopup(true);
      }
    } else if (actionType === "edit" && isCoach === "") {
      const req = await EditProfilePhoto(file);
      if (req !== false) {
        const profileRes = await getApis(API_URL_CONSTANTS.GET_PROFILE_IMG);

        setProfilePhotoURL(profileRes.data.message);
        window.location.reload();
      }
    } else if (isCoach === "editCoach" && actionType === "edit") {
      const req = await SubmitProfilePhoto(file);

      if (req.success === true) {
        let profilePath = req.path;
        dispatch(addCoachFormData({ profilePhotoPath: req.path }));
        await SubmitProfile(profilePath);
      } else {
        setErrorHeader(ERROR_CONSTANTS.NetworkError.Heading);
        setErrorMessage(ERROR_CONSTANTS.NetworkError.Message);
        setErrorPopup(true);
      }
    }
  }

  const handleProfilePhoto = async (e, actionType, isCoach) => {
    const file = e.target.files[0];

    if (file) {
      let fi = document.getElementById("profilePhoto");
      // Check if any file is selected.
      if (fi.files.length > 0) {
        for (let i = 0; i <= fi.files.length - 1; i++) {
          let fsize = fi.files.item(i).size;
          let fileSizeKB = Math.round(fsize / 1024);

          // The size of the file.
          if (fileSizeKB >= 103) {
            setErrorHeader("Profile Photo Error");
            setErrorMessage(
              "File too Big, please select a file less than 100kb"
            );
            setErrorPopup(true);
          } else {
            if (file && file.type.startsWith("image/")) {
              // If the selected file is an image
              const profilePhotoFileURL = URL.createObjectURL(file); // Create a URL for the selected file
              const img = new Image();

              img.onload = async () => {
                let imageWidth = img.width;
                let imageHeight = img.height;

                // Add your validation logic here based on imageWidth and imageHeight
                if (imageWidth === 320 && imageHeight === 320) {
                  await checkStatusProfilePhoto(file, actionType, isCoach);
                } else {
                  setErrorHeader("Profile Photo Error");
                  setErrorMessage("Image dimension should be 320px X 320px");
                  setErrorPopup(true);
                }
              };

              img.src = profilePhotoFileURL; // Set the source of the Image object to the file URL
            }
          }
        }
      }
    }
  };

  // edit coach form profile photo

  async function SubmitProfile(profilePath) {
    const TAC = true;
    const selectedDistancesRun = [];
    const selectedDistancesSwim = [];
    const selectedDistancesCycle = [];
    const selectedDistancesTriathlon = [];
    if (
      data.name === "" ||
      data.tagline === "" ||
      data.phone === "" ||
      data.location === "" ||
      data.gender === "" ||
      data.about === ""
    ) {
      setErrorHeader("Error");
      setErrorMessage(
        "Fill up Name, Tagline, Phone Number, Location, Gender, About"
      );
      setErrorPopup(true);
    } else {
      try {
        const req = await SubmitCoachForm(
          data.name,
          data.tagline,
          data.email,
          data.mobile,
          data.location,
          data.gender,
          data.about,
          selectedDistancesRun,
          selectedDistancesSwim,
          selectedDistancesCycle,
          selectedDistancesTriathlon,
          data.cover_photo,
          profilePath,
          data.video || null,
          data.certifications,
          data.coaching_since,
          data.athlete_trained,
          data.languages,
          data.athlete_level,
          data.coaches,
          TAC
        );

        if (req === true) {
          window.location.reload(true);
        } else {
          setErrorHeader(ERROR_CONSTANTS.NetworkError.Heading);
          setErrorMessage(ERROR_CONSTANTS.NetworkError.Message);
          setErrorPopup(true);
        }
      } catch {
        setErrorHeader(ERROR_CONSTANTS.NetworkError.Heading);
        setErrorMessage(ERROR_CONSTANTS.NetworkError.Message);
        setErrorPopup(true);
      }
    }
  }

  return {
    handleProfilePhoto,
    profilePhotoURL,
    profilePhotoPath,
    errorHeader,
    errorMessage,
    errorPopup,
    setErrorPopup,
  };
};
