import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import RightArrow from "assets/icons/right-arrow.png";
import PhoneFormStep4 from "./PhoneFormStep4";
import ErrorPopup from "components/popups/ErrorPopup";
import { useVideoHandlers } from "utility/utility_functions/videos";
import { SubmitCoachFormData } from "utility/constants/useContext";
import { updateForm } from "state/SetCoachFormData";
import ReactQuill from "react-quill";
import BackArrow from "assets/icons/back.png";
import PhoneFormStep2 from "./PhoneFormStep2";

function PhoneFormStep3() {
  const Props = useContext(SubmitCoachFormData);
  const [nextClicked, setNextClicked] = useState(false);
  const [popper, setPopper] = useState(false);
  const [placeHolder, setPlaceHolder] = useState(Props.formState.aboutYourSelf);
  const navigate = useNavigate();
  const [backClicked, setBackClicked] = useState(false);
  const [wordCountAbout, setWordCountAbout] = useState(0);

  const {
    handleFileChange,
    videoFileURL,
    errorHeader,
    errorMessage,
    errorPopup,
    setErrorPopup,
  } = useVideoHandlers();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // word counter

  function handleAbout(e, delta, source, editor) {
    setPlaceHolder(e);

    Props.dispatch(updateForm({ aboutYourSelf: e }));

    // Use unprivileged editor methods
    const plainText = editor.getText(); // Get plain text (without HTML tags)
    const html = editor.getHTML(); // Get full HTML content
    const length = editor.getLength(); // Get character count (excluding HTML tags)
    setWordCountAbout(length - 1);
  }
  const handleBackClick = () => {
    setBackClicked(true);
  };
  return (
    <div>
      {backClicked ? (
        <PhoneFormStep2 />
      ) : (
        <>
          {nextClicked ? (
            <PhoneFormStep4 />
          ) : (
            <>
              {errorPopup && (
                <>
                  <div className="blur"></div>
                  <ErrorPopup
                    message={{
                      heading: errorHeader,
                      message: errorMessage,
                    }}
                    setErrorPopup={setErrorPopup}
                  />
                </>
              )}
              <div className="coach_form">
                <div className="form_section">
                  <form action="" method="post" className="mt-16 m-0">
                    <div className="phone_form_step3 p-4">
                      <div className="about_div">
                        <h4 className="text-[var(--blue)] font-normal">
                          Write something about yourself so that your athletes
                          can understand you well.{" "}
                        </h4>
                        <div className="yourself">
                          <label htmlFor="input" className="text z-10">
                            About Yourself:
                            {Props.formState.aboutYourSelf ===
                              "<p><br></p>" && (
                              <sup className="text-[var(--red)]"> *</sup>
                            )}
                          </label>

                          <ReactQuill
                            theme="snow"
                            value={placeHolder}
                            onChange={handleAbout}
                          />
                          {wordCountAbout > 900 && (
                            <label className="name_counter text-[var(--red)]">
                              ({wordCountAbout}/1000)
                            </label>
                          )}
                        </div>
                      </div>
                      <div className="video_div ">
                        <h4 className="text-[var(--blue)] font-normal">
                          You can upload a 1min video talking about yourself.
                        </h4>
                        <video
                          id="video"
                          src={Props.formState.getVideo}
                          controls
                        ></video>
                        <input
                          id="file-input"
                          type="file"
                          className="mt-4 mb-12"
                          accept="video/*"
                          onChange={(event) =>
                            handleFileChange(event, Props.setVideoPath)
                          }
                        />
                      </div>
                      <div className="Phone_next_steps_btn">
                        {popper && (
                          <div className="popper">
                            <p className="text-[var(--red)] ">
                              Fill up the fields
                            </p>
                          </div>
                        )}
                        <p
                          className="statusBarPhone2"
                          onClick={() => {
                            if (Props.aboutYourSelf === "") {
                              setNextClicked(false);
                              setPopper(true);
                            } else {
                              setNextClicked(true);
                              setPopper(false);
                            }
                          }}
                        >
                          <div className="flex justify-between">
                            <p
                              className="mr-[10px] mt-[10px] cancelButton"
                              onClick={handleBackClick}
                            >
                              <div className=" flex items-center">
                                <img
                                  src={BackArrow}
                                  alt=""
                                  className="next_img mr-1 ml-[-3px]"
                                />
                                Back
                              </div>
                            </p>
                            <div className="coach_cancel_Div flex">
                              <p
                                className="mr-[10px] mt-[10px] cancelButton"
                                onClick={() => {
                                  navigate("/");
                                }}
                              >
                                Cancel
                              </p>
                              <div className="nextButton">
                                Next{" "}
                                <img
                                  src={RightArrow}
                                  alt="Next"
                                  className="next_img mt-[6px]"
                                />
                              </div>
                            </div>
                          </div>
                        </p>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default PhoneFormStep3;
