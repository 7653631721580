import React from "react";

function OtpInput(props) {
  const { otp, setOtp, index, value, onOtpSubmit, inputRefs, length } = props;

  const handleChange = (index, e) => {
    const value = e.target.value;
    if (isNaN(value)) return;

    const newOtp = [...otp];
    // allow only one input
    newOtp[index] = value.substring(value.length - 1);
    setOtp(newOtp);

    // submit trigger
    const combinedOtp = newOtp.join("");
    if (combinedOtp.length === length) onOtpSubmit(combinedOtp);

    // Move to next input if current field is filled
    if (value && index < length - 1 && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleClick = (index) => {
    inputRefs.current[index].setSelectionRange(1, 1);

    // optional
    if (index > 0 && !otp[index - 1]) {
      inputRefs.current[otp.indexOf("")].focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (
      e.key === "Backspace" &&
      !otp[index] &&
      index > 0 &&
      inputRefs.current[index - 1]
    ) {
      // Move focus to the previous input field on backspace
      inputRefs.current[index - 1].focus();
    }
  };
  return (
    <>
      <input
        key={index}
        type="text"
        ref={(input) => (inputRefs.current[index] = input)}
        value={value}
        onChange={(e) => handleChange(index, e)}
        onClick={() => handleClick(index)}
        onKeyDown={(e) => handleKeyDown(index, e)}
        className="otpInput"
      />
    </>
  );
}

export default OtpInput;
