import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateCertificatesViewAccount } from "state/ViewAccountState";
import { addViewAccountData } from "state/ViewAccountState";
import { updateForm } from "state/SetCoachFormData";
import { updateCertificates } from "state/SetCoachFormData";
import cancels from "assets/img/cancels.png";
import Ribbin from "assets/img/ribbon.png";
import redCancel from "assets/img/redCancel.png";
import AddIcon from "assets/icons/add.svg";

function AccountCertificates(props) {
  const { isEdit, setEdit, data, type } = props;
  const dispatch = useDispatch();
  const addDataTo = type === "account" ? addViewAccountData : updateForm;
  const addCertificatesTo =
    type === "account" ? updateCertificatesViewAccount : updateCertificates;

  // Initialize state with existing certificates from Redux
  const [certificates, setCertificates] = useState(data.certifications || []);

  // Add a new empty certificate to the state
  const handleAddCertificate = () => {
    setCertificates([
      ...certificates,
      { name: "", issued_by: "", issued_on: "" },
    ]);
    setEdit(true);
  };

  // Remove a certificate by index
  const handleRemoveCertificate = (index) => {
    const updatedCertificates = certificates.filter((_, i) => i !== index);
    setCertificates(updatedCertificates);
    dispatch(addDataTo({ certifications: updatedCertificates }));
  };

  // Handle change in certificate input fields
  const handleChange = (e, index, key) => {
    const updatedCertificates = [...certificates];
    updatedCertificates[index] = {
      ...updatedCertificates[index],
      [key]: e.target.value,
    };
    setCertificates(updatedCertificates);
  };

  // Handle date change and format it to "dd-mm-yyyy"
  const handleDate = (e, index) => {
    const date = e.target.value;

    // Create a new copy of the certificates array
    const updatedCertificates = certificates.map(
      (cert, i) =>
        i === index
          ? {
              ...cert, // spread the properties of the certificate
              issued_on: date ? date.split("-").reverse().join("-") : "", // update only the issued_on field
            }
          : cert // return the certificate as-is if it's not the one being updated
    );

    // Update the state with the new array
    setCertificates(updatedCertificates);
  };

  // Save changes to Redux when the component unmounts or certificates change
  useEffect(() => {
    dispatch(addDataTo({ certifications: certificates }));
  }, [certificates, dispatch]);

  const today = new Date().toISOString().split("T")[0];

  return (
    <div className="setting_box border-none">
      {isEdit ? (
        <>
          <div>
            {certificates.map((certificate, index) => (
              <>
                <div
                  className="flex flex-col -ml-2 pr-[0.7rem] text-[1rem] border-dotted border-t border-[#023047b3] bg-white text-[--edit-blue] font-normal w-[90%]"
                  key={index}
                >
                  <div className="flex input_info_box_coach">
                    <div className="relative inline-block mt-8">
                      <img src={Ribbin} alt="" className="w-10 h-10" />
                      <span className="absolute top-1.5 right-4 text-black font-bold text-sm">
                        {index + 1}
                      </span>
                    </div>
                    <div className="ml-4 w-full">
                      <div
                        className="mb-4 mt-[-20px] bg-white w-8 pl-1 ml-auto cursor-pointer"
                        onClick={() => handleRemoveCertificate(index)}
                      >
                        <div className="relative w-6 h-6">
                          <img
                            src={cancels}
                            alt="cancel"
                            className="w-6 h-6 mt-2"
                          />
                          <img
                            src={redCancel}
                            alt="cancel hover"
                            className="w-6 h-6 absolute top-0 left-0 opacity-0 duration-300 hover:opacity-100"
                          />
                        </div>
                      </div>
                      <div className="certificate_of input_lable_div flex flex-col -mt-6">
                        <label className="certificate_of relative top-[1.2rem] py-0 px-1 left-[3%] w-fit bg-white">
                          Certificate Of
                        </label>
                        <input
                          type="text"
                          className="mt-2"
                          value={certificate.name}
                          onChange={(e) => handleChange(e, index, "name")}
                        />
                      </div>

                      <div className="issued_by input_lable_div flex flex-col mt-[-12px]">
                        <label className="issued_by relative top-[0.8rem] py-0 px-1 left-[3%] w-fit bg-white">
                          Issued By
                        </label>
                        <input
                          type="text"
                          value={certificate.issued_by}
                          onChange={(e) => handleChange(e, index, "issued_by")}
                        />
                      </div>

                      <div className="issued_on input_lable_div flex flex-col mt-[-12px]">
                        <label className="issued_on relative top-[0.7rem] py-0 px-1 left-[3%] w-fit bg-white">
                          Issued On
                        </label>
                        <input
                          max={today}
                          type="date"
                          value={certificate.issued_on
                            .split("-")
                            .reverse()
                            .join("-")}
                          className="date-picker"
                          onChange={(e) => handleDate(e, index)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ))}
            <div className="add_div pb-4">
              <p className="add_btn" onClick={handleAddCertificate}>
                Add Certificates <img src={AddIcon} alt="Add Icon" />
              </p>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="">
            {data.certifications && (
              <div className="flex flex-col ">
                {data.certifications.map((item, index) => (
                  <div
                    key={`${index} certificates`}
                    className="flex border-dotted border-b border-[#023047b3] py-4 "
                  >
                    <div className="mr-4">
                      <div className="flex relative">
                        <img src={Ribbin} alt="" className="w-10 h-10" />
                        <span className="absolute left-4 top-1 text-black">
                          {index + 1}
                        </span>
                      </div>
                    </div>

                    <div className="">
                      <div className="mb-1">
                        <p className="lable_tag  ">Certificate Of</p>
                        <p className="lable_value !w-full ml-3">
                          {item.name} certified coach
                        </p>
                      </div>
                      <div className="mb-1">
                        <p className="lable_tag  ">Issued by</p>
                        <p className="lable_value !w-full ml-3">
                          {item.issued_by}
                        </p>
                      </div>
                      <div className="mb-1">
                        <p className="lable_tag  ">Issued on</p>
                        <p className="lable_value !w-full ml-3">
                          {item.issued_on}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default AccountCertificates;

{
  /* <div className="first_half mb-4 px-1">
<p className="lable_tag">name</p>
<p className="lable_value">{item.name} certified coach</p>
</div>
<div className="first_half mb-4 px-1">
<p className="lable_tag">Issued by</p>
<p className="lable_value">{item.issued_by}</p>
</div>
<div className="first_half mb-4 px-1">
<p className="lable_tag">Issued on</p>
<p className="lable_value">{item.issued_on}</p>
</div> */
}
