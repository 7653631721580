import React, { useState } from "react";
import { Button } from "rsuite";
import { useSelector } from "react-redux";
import DateInput from "components/inputs/DateInput";
function MyEvent() {
  const data = useSelector((state) => state.viewAccount);

  const [addEventPopup, setAddEventPopup] = useState(false);
  const [load, setLoad] = useState(false);
  const [eventName, setEventName] = useState("");
  const [eventdate, setEventDate] = useState("");

  const hasUpcomingEvents =
    data.upcoming_events && data.upcoming_events.length > 0;

  // async function handleAdd() {
  //   if (load === true) {
  //     return;
  //   }
  //   setLoad(true);
  //   // const res = await AddCoachingPlans();
  //   if (res === true) {
  //     setLoad(false);
  //     setAddEventPopup(false);
  //     console.log(res);
  //   } else {
  //     alert(res.message);
  //     setLoad(false);
  //   }
  // }

  return (
    <div className="setting_box">
      <div className="profile_box_heading_div">
        <h2>My Event</h2>
        <div>
          <p className="edit_button" onClick={() => setAddEventPopup(true)}>
            Add
          </p>
        </div>
      </div>
      <div className="input_info_box">
        {hasUpcomingEvents ? (
          <>
            <div className="first_half">
              <p className="lable_tag">Event</p>
              <p className="lable_value ">
                {data.upcoming_events.event_name === undefined ? (
                  <p className="null_message">No Up Comming Events</p>
                ) : (
                  `${data.event_name}`
                )}
              </p>
            </div>
            <div className="second_half">
              <p className="lable_tag">Event date</p>
              <p className="lable_value ">
                {data.upcoming_events.event_date === undefined ? (
                  <p className="null_message">No Up Comming Events</p>
                ) : (
                  `${data.event_date}`
                )}
              </p>
            </div>
          </>
        ) : (
          <>
            <p className="null_message">No Up Comming Events</p>
          </>
        )}
      </div>

      {/* add event popup */}
      {addEventPopup && (
        <div className="popup_box">
          <div className="popup_heading">
            <h4>Add Event</h4>
          </div>
          <div className="inputs_box">
            <div className="input_lable_div">
              <label>Event name</label>
              <input
                type="text"
                onChange={(e) => setEventName(e.target.value)}
              />
            </div>
            <div className="input_lable_div mb-0">
              <label htmlFor="">Event Date</label>

              <input
                type="date"
                onChange={(e) => setEventDate(e.target.value)}
              />
            </div>
            <div className="add_btn_poup">
              <span
                className="plan_cancel"
                onClick={() => setAddEventPopup(false)}
              >
                Cancel
              </span>
              <span
                className="plan_action_btn"
                onClick={() => setAddEventPopup(false)}
              >
                Save
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default MyEvent;
