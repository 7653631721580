import React, { useState, useEffect } from "react";
import { EditPoolength } from "api/UserAccountController";
import { getUserDetails } from "api/UserAccountController";
import ErrorPopup from "components/popups/ErrorPopup";
import { useSelector, useDispatch } from "react-redux";
import { addViewAccountData } from "state/ViewAccountState";
function Setting(props) {
  const { setEditSetting, editProfile, editAout } = props;
  const dispatch = useDispatch();
  const data = useSelector((state) => state.viewAccount);

  const [isEdit, setEdit] = useState(false);
  const [poolLength, setPoolLength] = useState();
  const [poolT, setPoolT] = useState();

  const [errorPopup, setErrorPopup] = useState(false); // error popup
  const [errorHeader, setErrorHeader] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [editSettingDisable, setEditSettingDisable] = useState(false);

  useEffect(() => {
    setPoolLength(data.pool_length || "");
  }, [data]);

  useEffect(() => {
    if (editAout || editProfile) {
      setEditSettingDisable(true);
    } else {
      setEditSettingDisable(false);
    }
  }, [editAout, editProfile]);

  async function handleSave() {
    setEdit(false);
    setEditSetting(false);
    if (poolLength === "" || poolLength < 14 || poolLength > 150) {
      setPoolLength(data.pool_length);
      setErrorHeader("Error");
      setErrorMessage("Pool Length between 14-150");
      setErrorPopup(true);
    } else {
      const res = await EditPoolength(poolLength);
      if (res === true) {
        const pool = {
          pool_length: poolLength,
        };
        dispatch(addViewAccountData(pool));
        setEdit(false);
        if (poolLength === "") {
          const fetchData = async () => {
            try {
              const res = await getUserDetails();

              setPoolLength(res.data.pool_length);
            } catch (error) {}
          };
          fetchData();
        }
      }
    }
  }

  return (
    <>
      {errorPopup && (
        <>
          <div className="blur"></div>
          <ErrorPopup
            message={{
              heading: errorHeader,
              message: errorMessage,
            }}
            setErrorPopup={setErrorPopup}
          />
        </>
      )}

      <div className={`setting_box ${isEdit ? "edit_mode" : ""}`}>
        <div className="profile_box_heading_div">
          <h2>Activity Settings</h2>
          {isEdit ? (
            <div className="flex">
              <p
                className="cancelButton"
                onClick={() => {
                  setEdit(false);
                  setEditSetting(false);
                }}
              >
                Cancel
              </p>
              <p className="saveButton" onClick={handleSave}>
                Save
              </p>
            </div>
          ) : (
            <button
              disabled={editSettingDisable}
              className="edit_button"
              onClick={() => {
                setEdit(true);
                setEditSetting(true);
              }}
            >
              Edit
            </button>
          )}
        </div>
        <div className="input_info_box">
          <div className="first_half">
            <p className="lable_tag">Pool length</p>
            {isEdit ? (
              <input
                type="number"
                value={poolLength}
                onChange={(e) => setPoolLength(e.target.value)}
              />
            ) : (
              <p className="lable_value ">{poolLength}m</p>
            )}
          </div>
          {/* <div className="second_half">
          <p className="lable_tag">Metric</p>
          <p className="lable_value ">M</p>
        </div> */}
        </div>
      </div>
    </>
  );
}

export default Setting;
