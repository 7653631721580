import React, { useEffect, useMemo, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateForm } from "state/SetCoachFormData.js";
import { API_URL_CONSTANTS } from "utility/constants/apiUrlConstants";
import { useNavigate } from "react-router-dom";
import Nav from "components/navbar/Nav";
import ErrorPopup from "components/popups/ErrorPopup.jsx";
import PhoneNavBar from "components/navbar/PhoneNavBar";
import InputBox from "./coachFormComponents/InputBox";
import RightArrow from "assets/icons/right-arrow.png";
import FormHeaderLeft from "./coachFormComponents/FormHeaderLeft";
import FormHeaderRight from "./coachFormComponents/FormHeaderRight";
import FormStep2 from "./desktopCoachFormSteps/FormStep2";
import PhoneFormStep1 from "./phoneCoachFormSteps/PhoneFormStep1.jsx";
import PhoneFormStep2 from "./phoneCoachFormSteps/PhoneFormStep2.jsx";
import PopUpLoginP from "components/signin/PhonePopUpLogin.jsx";
import { usePhotoHandlers } from "utility/utility_functions/profilePhotos";
import { useCoverHandlers } from "utility/utility_functions/coverPhoto";
import { SubmitCoachFormData } from "utility/constants/useContext";
import { fetchCity } from "hooks/basic";
import { getApis } from "hooks/api";

function CoachForm() {
  // Redux consts
  const dispatch = useDispatch();
  const formState = useSelector((state) => state.setCoachFromData);
  const userData = useSelector((state) => state.viewAccount);

  // Cover photo and profile photo consts
  const {
    handleProfilePhoto,
    profilePhotoURL,
    profilePhotoPath,
    errorPopup: profileErrorPopup,
    errorMessage: profileErrorMessage,
    errorHeader: profileErrorHeader,
    setErrorPopup: setProfileErrorPopup,
  } = usePhotoHandlers();
  const {
    photoURL,
    handleFileChange,
    coverPhotoPath,
    errorPopup: coverErrorPopup,
    errorMessage: coverErrorMessage,
    errorHeader: coverErrorHeader,
    setErrorPopup: setCoverErrorPopup,
  } = useCoverHandlers();

  // navigate
  const navigate = useNavigate();

  // useStates
  const [videoPath, setVideoPath] = useState("");
  const [city, setCity] = useState("");
  const Email =
    localStorage.getItem("email") ?? sessionStorage.getItem("email");
  const [certificates, setCertificates] = useState([]);
  const [formValidation, setFormValidation] = useState(false);
  const [formValidationP, setFormValidationP] = useState(false);
  const [nextClicked, setNextClicked] = useState(false);
  const [email, setEmail] = useState(Email);
  const [gender, setGender] = useState("");
  const [popper, setPopper] = useState(false);
  const [isValidNumber, setIsValidNumber] = useState(true);
  const [errors, setErrors] = useState([
    { nameError: "", issuedByError: "", issuedOnError: "" },
  ]);

  // certificate validation
  const validateCertificates = () => {
    if (!formState.certifications) {
      return true;
    }
    let isValid = true;
    const newErrors = formState.certifications.map((cert) => {
      let certErrors = {
        nameError: "",
        issuedByError: "",
        issuedOnError: "",
      };

      if (cert.name === "") {
        certErrors.nameError = "Required";
        isValid = false;
      } else if (cert.name.length < 4) {
        certErrors.nameError = "Value should be more then 4 letter";
        isValid = false;
      }

      if (cert.issued_by === "") {
        certErrors.issuedByError = "Required";
        isValid = false;
      } else if (cert.issued_by.length < 4) {
        certErrors.issuedByError = "Value should be more then 4 letter";
        isValid = false;
      }

      if (cert.issued_on === "") {
        certErrors.issuedOnError = "Required";
        isValid = false;
      }

      return certErrors;
    });

    setErrors(newErrors);

    return isValid;
  };

  const coachFormProps = useMemo(
    () => ({
      email,
      setEmail,
      gender,
      setGender,
      isValidNumber,
      setIsValidNumber,
      certificates,
      setCertificates,
      city,
      setCity,
      validateCertificates,
      errors,
      setErrors,
      dispatch,
      formState,
      setVideoPath,
      videoPath,
    }),
    [
      email,
      gender,
      isValidNumber,
      certificates,
      city,
      validateCertificates,
      errors,
      videoPath,
      formState,
    ]
  );

  // email validation
  const [isValidEmail, setIsValidEmail] = useState(true);

  // get cover and profile img \

  useEffect(() => {
    window.scrollTo(0, 0);

    async function getPhotos() {
      const coverRes = await getApis(API_URL_CONSTANTS.GET_COVER_IMG);

      dispatch(updateForm({ getCover: coverRes.data.message }));

      const profileRes = await getApis(API_URL_CONSTANTS.GET_PROFILE_IMG);

      dispatch(updateForm({ getProfile: profileRes.data.message }));
    }
    getPhotos();

    const getCity = async () => {
      const cityName = await fetchCity();
      // setCity(cityName.region);

      dispatch(
        updateForm({
          location: `${cityName.city}, ${cityName.region}, ${cityName.country}`,
        })
      );
    };

    getCity();
  }, []);

  useEffect(() => {
    setGender(userData.gender);
    dispatch(updateForm({ name: userData.name }));
    dispatch(updateForm({ number: userData.mobile }));
  }, [userData.gender]);

  // add certificate validation logi

  // Validation logic -------------------------------
  useEffect(() => {
    if (
      formState.number === "" ||
      formState.location === "" ||
      formState.name === "" ||
      formState.aboutYourSelf === "" ||
      formState.tagline === "" ||
      isValidNumber === false ||
      formState.coaching_since === ""
    ) {
      setFormValidation(false);
    } else {
      setFormValidation(true);
    }

    if (
      formState.number === "" ||
      formState.location === "" ||
      formState.name === "" ||
      formState.tagline === "" ||
      formState.coaching_since === ""
    ) {
      setFormValidationP(false);
    } else {
      setFormValidationP(true);
    }
  }, [gender, formState]);

  return (
    <SubmitCoachFormData.Provider value={coachFormProps}>
      {(profileErrorPopup || coverErrorPopup) && (
        <>
          <div className="blur"></div>
          <ErrorPopup
            message={{
              heading: profileErrorPopup
                ? profileErrorHeader
                : coverErrorHeader,
              message: profileErrorPopup
                ? profileErrorMessage
                : coverErrorMessage,
            }}
            setErrorPopup={
              profileErrorPopup ? setProfileErrorPopup : setCoverErrorPopup
            }
          />
        </>
      )}

      <>
        {(formValidation && nextClicked) || (formValidationP && nextClicked) ? (
          <>
            <FormStep2 />
            <PhoneFormStep2 />
          </>
        ) : (
          <div className="coach_form desk-phone 1">
            {/* <CoachFormNavBar isNavBar={isNavBar} /> */}

            <div className="form_section">
              <form action="" method="post" className="mt-16 m-0">
                <>
                  {/* Cover photo DIV */}
                  <div className="cover_photo">
                    <img
                      className="cover_photo_img"
                      id="photo"
                      src={
                        photoURL
                          ? photoURL
                          : `${API_URL_CONSTANTS.DOMAIN_NAME}${formState.getCover}`
                      }
                      alt="Uploaded"
                    />

                    <input
                      id="file-input"
                      type="file"
                      accept="image/*"
                      onChange={(event) => handleFileChange(event, "submit")}
                      className="invisible"
                    />
                    <label className="cover_photo_input" htmlFor="file-input">
                      Add Cover Photo
                    </label>

                    {/* PORFILE PHOTO DIV */}
                    <div className="profile_input_box">
                      <div className={`profile_photo `}>
                        <img
                          className="profile_photo_img"
                          src={
                            profilePhotoURL
                              ? profilePhotoURL
                              : `${API_URL_CONSTANTS.DOMAIN_NAME}${formState.getProfile}`
                          }
                          alt=""
                        />
                        <input
                          type="file"
                          accept="image/*"
                          name=""
                          placeholder="+"
                          id="profilePhoto"
                          onChange={(event) =>
                            handleProfilePhoto(event, "submit")
                          }
                          className="invisible"
                        />
                        <label
                          className="profile_photo_input"
                          htmlFor="profilePhoto"
                        ></label>
                      </div>
                      {/* INPUTS box */}
                      <InputBox className="nameBox" />
                    </div>
                  </div>

                  <div className="flex flex-row justify-between ">
                    <FormHeaderLeft />
                    <FormHeaderRight />
                  </div>

                  <PhoneFormStep1
                    isValidEmail={isValidEmail}
                    setIsValidEmail={setIsValidEmail}
                  />

                  <div className="next_steps_btn">
                    {popper && (
                      <div className="mb-[0.5rem] mt-[-70px] text-[var(--red)] mr-8">
                        <p>Fill up the fileds</p>
                      </div>
                    )}
                    <div className="statusBar">
                      <div className="coach_cancel_Div flex justify-end items-center">
                        <div className="flex items-center">
                          <p
                            className="cancelButton mr-[10px] mt-[10px]"
                            onClick={() => {
                              navigate("/");
                            }}
                          >
                            Cancel
                          </p>
                          <div
                            className="nextButton flex items-center"
                            onClick={() => {
                              const certificateValidation =
                                validateCertificates();

                              if (
                                formValidation === true &&
                                certificateValidation === true
                              ) {
                                setNextClicked(true);

                                setPopper(false);
                              } else {
                                if (
                                  formValidation === false ||
                                  certificateValidation === false
                                ) {
                                  validateCertificates();
                                  setPopper(true);
                                  window.scrollTo(0, 0);
                                }
                              }
                            }}
                          >
                            <p>Next</p>
                            <img
                              src={RightArrow}
                              alt=""
                              className="next_img ml-2"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* PHONE BACK BTN */}
                  <div className="Phone_next_steps_btn">
                    <div className="statusBarPhone next_btn_align">
                      <div className="coach_cancel_Div">
                        {popper && (
                          <p className="absolute bottom-12 mr-4 text-[var(--red)]">
                            Fill up the fileds
                          </p>
                        )}
                        <p
                          className="cancelButton mr-[10px] mt-[10px]"
                          onClick={() => {
                            navigate("/");
                          }}
                        >
                          Cancel
                        </p>
                        <div
                          className="nextButton"
                          onClick={() => {
                            if (formValidationP === true) {
                              setNextClicked(true);
                              setPopper(false);
                            } else {
                              if (formValidationP === false) {
                                setPopper(true);
                              }
                            }
                          }}
                        >
                          Next
                          <img
                            src={RightArrow}
                            alt=""
                            className="next_img mt-[6px]"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              </form>
            </div>
          </div>
        )}
      </>
    </SubmitCoachFormData.Provider>
  );
}

export default CoachForm;
