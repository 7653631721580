import React, { useContext } from "react";
import Alert from "assets/icons/RedAlert.svg";
import { AppProps } from "utility/constants/useContext";
import { getlocalStorageData } from "hooks/basic";
import { Link } from "react-router-dom";

function FormBtn({ formRespones, isRejected, formattedDate }) {
  const AppData = useContext(AppProps);
  const localStorageData = getlocalStorageData();
  return (
    <div>
      <div className="join_box flex justify-between">
        <div className="join_button  ">
          {AppData.isSignedIn ? (
            <>
              {localStorageData.role === "Coach" &&
              formRespones.request_submitted === false ? (
                <a
                  onClick={() => {
                    AppData.SwitchAccountBtn(localStorageData.ref_id);
                  }}
                >
                  Switch Account
                </a>
              ) : (
                <>
                  {localStorageData.role === "Athlete" && (
                    <>
                      {AppData.coachSignUpStatus === "null" ? (
                        <>
                          <Link to="/coachform"> Apply to become a coach</Link>
                        </>
                      ) : (
                        <>
                          {AppData.coachSignUpStatus ===
                            "Pending Verification" && (
                            <>
                              <Link to="/viewcoachform">
                                Pending Verification
                              </Link>
                              <p className="text-center">
                                Your coach account verification is pending,
                                Click to View coach request
                              </p>
                            </>
                          )}

                          {AppData.coachSignUpStatus === "2" && (
                            <>
                              {localStorageData.role === "Athlete" && (
                                <>
                                  <a
                                    onClick={() => {
                                      AppData.SwitchAccountBtn(
                                        localStorageData.ref_id
                                      );
                                    }}
                                  >
                                    Switch Account
                                  </a>
                                </>
                              )}
                            </>
                          )}

                          {AppData.coachSignUpStatus === "3" && (
                            <>
                              {isRejected ? (
                                <Link to="/coachform">Get Started</Link>
                              ) : (
                                <a>Coach request Rejected </a>
                              )}
                            </>
                          )}

                          {AppData.coachSignUpStatus === "4" && (
                            <>
                              <p className="text-center">
                                {formattedDate ? (
                                  <> last updated on {formattedDate} </>
                                ) : (
                                  ""
                                )}
                              </p>
                              <Link to="/editcoachform">
                                Need Additional Information
                              </Link>
                              <div className="flex bg-[#FFCEC6] justify-center  items-center mt-5 px-2 rounded-[5px] ">
                                <img
                                  src={Alert}
                                  className="mr-2"
                                  alt="alert icon"
                                />
                                <p className="!my-1 ">{formRespones.message}</p>
                              </div>
                            </>
                          )}

                          {AppData.coachSignUpStatus === "5" && (
                            <>
                              <Link to="/coachform">Get Started</Link>
                              <p className="text-center">{""}</p>
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              <a
                onClick={() => {
                  AppData.setOpen(true);
                  AppData.setSignInOpen(true);
                }}
              >
                Apply to become a coach
              </a>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default FormBtn;
