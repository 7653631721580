import React from "react";
import DesktopFooter from "./DesktopFooter";
import PhoneFooter from "./PhoneFooter";

function FooterSection() {
  return (
    <>
      <DesktopFooter />
      <PhoneFooter />
    </>
  );
}

export default FooterSection;
