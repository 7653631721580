import React from "react";
import Selected_about from "assets/icons/sub_menu_icons/selected/about.svg";
import not_selected_about from "assets/icons/sub_menu_icons/not_selected/about.svg";
import Selected_video from "assets/icons/sub_menu_icons/selected/video.svg";
import not_selected_video from "assets/icons/sub_menu_icons/not_selected/video.svg";
import Selected_youTrain from "assets/icons/sub_menu_icons/selected/youTrain.svg";
import not_selected_youTrain from "assets/icons/sub_menu_icons/not_selected/youTrain.svg";
import Selected_certificate from "assets/icons/sub_menu_icons/selected/certificate.svg";
import not_selected_certificate from "assets/icons/sub_menu_icons/not_selected/certificate.svg";
import Selected_billing from "assets/icons/sub_menu_icons/selected/billing.svg";
import not_selected_billing from "assets/icons/sub_menu_icons/not_selected/billing.svg";
import Selected_setting from "assets/icons/sub_menu_icons/selected/setting.svg";
import not_selected_setting from "assets/icons/sub_menu_icons/not_selected/setting.svg";

function SubMenu(props) {
  const { activeMenu, setActiveMenu, type } = props;
  const coachMenu =
    type === "editForm"
      ? [
          {
            id: "about",
            label: "About",
            selected_img: Selected_about,
            not_selected_img: not_selected_about,
          },
          {
            id: "video",
            label: "Video",
            selected_img: Selected_video,
            not_selected_img: not_selected_video,
          },
          {
            id: "youTrain",
            label: "Your Train",
            selected_img: Selected_youTrain,
            not_selected_img: not_selected_youTrain,
          },
          {
            id: "certificates",
            label: "Certificates",
            selected_img: Selected_certificate,
            not_selected_img: not_selected_certificate,
          },
        ]
      : [
          {
            id: "about",
            label: "About",
            selected_img: Selected_about,
            not_selected_img: not_selected_about,
          },
          {
            id: "video",
            label: "Video",
            selected_img: Selected_video,
            not_selected_img: not_selected_video,
          },
          {
            id: "youTrain",
            label: "Your Train",
            selected_img: Selected_youTrain,
            not_selected_img: not_selected_youTrain,
          },
          {
            id: "certificates",
            label: "Certificates",
            selected_img: Selected_certificate,
            not_selected_img: not_selected_certificate,
          },
          // {
          //   id: "billing",
          //   label: "My Billing",
          //   selected_img: Selected_billing,
          //   not_selected_img: not_selected_billing,
          // },
          // {
          //   id: "settings",
          //   label: "Settings",
          //   selected_img: Selected_setting,
          //   not_selected_img: not_selected_setting,
          // },
        ];
  return (
    <div className="bg-[#F0F4F8] w-[12rem]">
      <div className="flex flex-col mt-[3rem]">
        {coachMenu.map((item) => (
          <div
            key={item.id}
            className="flex py-2 cursor-pointer"
            onClick={() => setActiveMenu(item.id)}
          >
            {/* This div is conditionally rendered on the active item */}
            <div
              className={`bg-[var(--blue)] w-1 transition-all duration-300 ease-in-out ${
                activeMenu === item.id ? "h-auto" : "h-0"
              }`}
            ></div>
            <div className="flex ml-4">
              {activeMenu === item.id && <img src={item.selected_img} alt="" />}
              {activeMenu !== item.id && (
                <img src={item.not_selected_img} alt="" />
              )}

              <p
                className={`ml-2 text-[1rem] ${
                  activeMenu === item.id
                    ? "text-[var(--blue)] font-medium"
                    : "text-[var(--s-blue)]"
                }`}
              >
                {item.label}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SubMenu;
