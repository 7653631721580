import React, { useState, useContext, useEffect } from "react";
import banner from "assets/img/athlete_banner.png";
import SignUpPopUp from "components/signup/SignUpPopUp";
import Nav from "components/navbar/Nav";
import PhoneNavBar from "components/navbar/PhoneNavBar";
import PopUpLoginP from "components/signin/PhonePopUpLogin";
import PopUpLogin from "components/signin/PopUpLogin";
import { Link } from "react-router-dom";
import FooterSection from "components/footer/FooterSection";
import { AppProps } from "utility/constants/useContext";
import SEO from "hooks/Seo";
import { HelmetProvider } from "react-helmet-async";
import { motion } from "framer-motion";
import watches from "assets/img/watches.png";
import athletePhone from "assets/img/athlete_phone.png";

import {
  boxVariants1,
  boxVariants2,
  boxVariants3,
} from "utility/utility_functions/animation";

function Athele() {
  const AppData = useContext(AppProps);
  let changeCard = (index) => {
    const newPlusState = [...isPlus];
    newPlusState[index] = !newPlusState[index];
    setPlus(newPlusState);
  };
  let [isPlus, setPlus] = useState([false, false, false, false]);
  const arr = [
    {
      id: "0",
      plus: "+",
      sub: "-",
      heding: "Why should I train with a Coach?",
      para: "Each athlete is unique. Your body, exercise history, and goals are yours alone. Train to improve your own performance.",
    },
    {
      id: "1",
      plus: "+",
      sub: "-",
      heding: "How can I ensure I'm training effectively?",
      para: "With the help of custom training tailored for you by an accredited coach, fits to your schedule and race goals, with personlised workouts.",
    },
    {
      id: "2",
      plus: "+",
      sub: "-",
      heding: "How will I communicate with my Coach?",
      para: "You will receive your workouts from your coach through the TRACKOFIT app. You can use the in-app messaging feature to communicate with your coach.",
    },
  ];
  const [isSignUp, setSignUp] = useState(false);
  // FORGET PASSWORD
  const [isForgotPassword, setForgotPassword] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    const timer = setTimeout(() => {
      if (AppData.isSignedIn === false) {
        AppData.setOpen(true);
        AppData.setSignInOpen(true);
      }
    }, 10000);

    // Cleanup function to clear the timer when component unmounts or when the modals are closed
    return () => clearTimeout(timer);
  }, []);

  return (
    <HelmetProvider className="w-full">
      <SEO
        title="For An Athlete | Trackofit"
        descriptionName="Athlete"
        description="Get expert help every step of the way. Connect with an accredited coach tailored to your needs or explore our coach directory."
        canonicalUrl="https://www.trackofit.com/become-a-coach"
      />
      {/* <Nav setSignUp={setSignUp} isSignUp={isSignUp} />
      <PhoneNavBar />
      <PopUpLogin isSignUp={isSignUp} setSignUp={setSignUp} />
      <SignUpPopUp setSignUp={setSignUp} isSignUp={isSignUp} />
      <PopUpLoginP
        isForgotPassword={isForgotPassword}
        setForgotPassword={setForgotPassword}
        isSignUp={isSignUp}
        setSignUp={setSignUp}
      /> */}

      <div className="athlete_section" id="athlete">
        <div className="athlete_section_header_img">
          <img className="w-full h-full" src={banner} />
        </div>
      </div>

      <div className="athlete_page ">
        {/* <h5>For An Athlete</h5> */}
        <div className="athlete_info_box">
          <motion.div
            className="box"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.2 }}
            variants={boxVariants1}
          >
            <h6>Personalized Training Plans</h6>
            <div className="para">
              Coaches provide personalized training plans tailored to help you
              achieve your specific goals, whether it's improving endurance,
              building strength, or enhancing overall performance.
            </div>
          </motion.div>
          <motion.div
            className="box"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.2 }}
            variants={boxVariants2}
          >
            <h6>Expert Support and Guidance </h6>
            <div className="para">
              Enjoy virtual consultations and support from expert coaches who
              are just a message away for guidance, motivation, and plan
              adjustments—no in-person visit needed.
            </div>
          </motion.div>
          <motion.div
            className="box"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.2 }}
            variants={boxVariants3}
          >
            <h6>Connect</h6>
            <div className="para">
              Connect with coaches in our community to achieve your fitness
              goals whether you're a marathon runner, triathlete, cyclist, or
              swimmer.
            </div>
          </motion.div>
        </div>
      </div>
      <div className="unlock_Potentail py-20">
        {/* <h5>Unlock Your Full Potential with Our Platform</h5> */}
        <div className="findCoach">
          <div className="w-[35%] subtitle">
            <h4 className="mb-12 sm:mt-5">Find a Coach</h4>
            <div className="coach_info bg-[] text-center ">
              {arr.map((item) => (
                <div
                  key={item.id}
                  className="coach_infoDetails w-[90%] text-left mx-auto mb-4 p-4 bg-[var(--light-grey)]"
                >
                  <div className="heading_box flex justify-between items-center">
                    <h3 className="text-[var(--ligh-blue)] text-[1.3rem] font-normal ">
                      {item.heding}
                    </h3>
                    <span
                      className="text-[2rem] cursor-pointer"
                      onClick={() => changeCard(item.id)}
                    >
                      {isPlus[item.id] ? item.sub : item.plus}
                    </span>
                  </div>
                  {isPlus[item.id] && (
                    <p className="my-2 text-[var(--light-blue)] font-light text-[1rem]">
                      {item.para}
                    </p>
                  )}
                </div>
              ))}
            </div>
            {AppData.isSignedIn ? (
              <>
                {/* <Link className="coach_btn" to="/viewCoaches">
                  Get Started
                </Link> */}
                <a href="" className="coach_btn">
                  {" "}
                  Get Started
                </a>
              </>
            ) : (
              <Link
                className="coach_btn"
                onClick={() => {
                  AppData.setOpen(true);
                  AppData.setSignInOpen(true);
                }}
              >
                Get Started
              </Link>
            )}
          </div>
          <div className="mb-8 desktop_img grid ml-auto">
            <div className="flex">
              <img
                src="https://isoftwarefactory.com/wp-content/uploads/2024/10/run.jpg"
                className="h-[42vh] w-[66%]"
                alt="Run"
              />
              <div className="flex flex-col ml-2 w-[33%]">
                <img
                  src="https://isoftwarefactory.com/wp-content/uploads/2024/10/cycle.jpg"
                  className="h-[20.35vh] w-full mb-2"
                  alt="Cycling"
                />
                <img
                  src="https://isoftwarefactory.com/wp-content/uploads/2024/10/swim-scaled.jpg"
                  className="h-[20.35vh] w-full"
                  alt="Swim"
                />
              </div>
            </div>
            <div className="flex mt-2">
              <img
                src="https://isoftwarefactory.com/wp-content/uploads/2024/10/strech-scaled.jpg"
                className="h-[174px] w-[33.3%] mr-2"
                alt="Cycling"
              />
              <img
                src="https://isoftwarefactory.com/wp-content/uploads/2024/10/jog-scaled.jpg"
                className="h-[174px] w-[32.1%] mr-2"
                alt="Swim"
              />
              <img
                src="https://isoftwarefactory.com/wp-content/uploads/2024/10/run.jpg"
                className="h-[174px] w-[33.3%]"
                alt="Run"
              />
            </div>
          </div>

          <div className=" h-auto phone_img  ">
            <img
              src="https://isoftwarefactory.com/wp-content/uploads/2024/10/cycle.jpg"
              className="h-[174px] w-[16rem] image"
              alt=""
            />
            <img
              src="https://isoftwarefactory.com/wp-content/uploads/2024/10/swim-scaled.jpg"
              className="mt-2 h-[173px]"
              alt=""
            />
            <img
              src="https://isoftwarefactory.com/wp-content/uploads/2024/10/run.jpg"
              className="mt-2 h-[174px]"
              alt=""
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col items-center mb-12 w-full bg-[#eaeaea]">
        <div className=" appSection">
          <div className="widths ">
            <img src={watches} className=" object-cover watchImg" />
          </div>
          <div className="watchDiv">
            <h2 className="Wearable">Wearable Devices Supported</h2>
            <div className="grid justify-center items-center">
              <p className="">
                Athletes have the ability to connect various smartwatches, such
                as Apple, Garmin, and Coros, to the TRACKOFIT App in order to
                sync workouts and receive detailed performance insights.
              </p>
              <p className="mt-6">
                The App automatically tracks metrics like heart rate, pace,
                distance, and speed, providing a comprehensive view of training
                progress.
              </p>
              <p className="mt-6">
                Additionally, the App offers in-depth analysis of workout data
                to assist the coaches in optimizing training for better results.
                The integration supports multiple device types, ensuring a
                seamless experience across different brands.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col items-center mb-4 ">
        <div className="appSection">
          <div className="watchDiv  mr-14">
            <h2 className="Wearable">Training with TRACKOFIT App</h2>
            <div className="grid justify-center items-center">
              <p className="">
                Athletes can access their training plans in the TRACKOFIT App
                calendar, which includes detailed steps for each workout along
                with specific training zones. This ensures that athletes have
                clear guidance on what to do each day.
              </p>
              <p className=" mt-6">
                If an athlete misses a workout or wants to do it later, the app
                provides the option to reschedule the workout. This feature
                allows athletes to adjust their training schedules as needed.
              </p>
              <p className=" mt-6">
                This flexibility ensures they can stay on track and supports
                consistent progress without feeling pressured by a rigid plan.
              </p>
            </div>
          </div>
          <div className="widths">
            <img src={athletePhone} className="h-[61vh] object-cover" />
          </div>
        </div>
      </div>

      <FooterSection />
    </HelmetProvider>
  );
}

export default Athele;
