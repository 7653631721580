import axios from "axios";
import {Header} from 'api/Signin'
import {API_URL_CONSTANTS} from 'utility/constants/apiUrlConstants'

export async function getSessionId() {
  const header = Header();
  return axios
    .post(
      API_URL_CONSTANTS.CASHFREE_API,
      {
        id: "qtnxn6rKnDhEyoKaxzo/J8xhjMKCHsYg+XcR6GYpGrZf",
      },
      {
        headers: header,
      }
    )
    .then((res) => {
      if (res.status === 200) {
        return res.data.message;
      }
    })
    .catch((error) => {
      return error;
    });
}

export const PaynowBox = {
  id: "TS2425-0011",
  billing_period_from: "1-04-2024",
  billing_period_to: "08-05-2024",
  payment_status_code: 1,
  discount: 0.0,
  taxes: 957.63,
  total: 3000.0,
  status: "Unpaid",
  "paid on": "07-04-2024 10:10:10",
};

// logic for OVER DUE REMAINING DAYS
export function compareDate() {
  const convertToDate = (dateString) => {
    const [day, month, year] = dateString.split("-");
    return new Date(year, month - 1, day);
  };
  const currentDate = new Date();
  const dateFromData = PaynowBox.billing_period_from;
  const dateObject = convertToDate(dateFromData);
  if (dateObject < currentDate) {
    const differenceInMs = dateObject.getTime() - currentDate.getTime();
    const differenceInDays = differenceInMs / (1000 * 60 * 60 * 24);
    const overDueDate = -Math.ceil(differenceInDays);
    return overDueDate;
  } else {
    return false;
  }
}
