import React, { useContext, useEffect, useState } from "react";
import { ERROR_CONSTANTS } from "utility/constants/errorConstants";
import { SubmitCoachForm } from "api/CoachFormController";
import { useNavigate } from "react-router-dom";
import { SubmitCoachFormData } from "utility/constants/useContext";
import BackArrow from "assets/icons/back.png";
import { updateForm } from "state/SetCoachFormData";
import ErrorPopup from "components/popups/ErrorPopup";
import SuccessPopup from "components/popups/SuccessPopup";
import { AppProps } from "utility/constants/useContext";
import PhoneFormStep8 from "./PhoneFormStep8";

function PhoneFormStep9() {
  const Props = useContext(SubmitCoachFormData);
  const AppData = useContext(AppProps);

  const [validation, setValidation] = useState(false);
  const [nextClicked, setNextClicked] = useState(false);
  const [backClicked, setBackClicked] = useState(false);
  const [isSignupDone, setIsSignupDone] = useState(false);
  const [popper, setPopper] = useState(false);
  const [TAC, setTAC] = useState(false);
  const [disable, setDisable] = useState(false);
  const navigate = useNavigate();
  const [errorPopup, setErrorPopup] = useState(false); // error popup
  const [errorHeader, setErrorHeader] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const coaches = [];

  if (
    Props.formState.isRun === "yes" &&
    Props.formState.selectedDistancesRun.length > 0
  ) {
    coaches.push({
      category: "Run",
      value: Props.formState.selectedDistancesRun.join(", "),
    });
  }

  if (
    Props.formState.isSwim === "yes" &&
    Props.formState.selectedDistancesSwim.length > 0
  ) {
    coaches.push({
      category: "Swim",
      value: Props.formState.selectedDistancesSwim.join(", "),
    });
  }

  if (
    Props.formState.isCycle === "yes" &&
    Props.formState.selectedDistancesCycle.length > 0
  ) {
    coaches.push({
      category: "Cycle",
      value: Props.formState.selectedDistancesCycle.join(", "),
    });
  }

  if (
    Props.formState.isTri === "yes" &&
    Props.formState.selectedDistancesTriathlon.length > 0
  ) {
    coaches.push({
      category: "Triathlon",
      value: Props.formState.selectedDistancesTriathlon.join(", "),
    });
  }
  //
  // useEffect(() => {
  //   if (Props.formState.athlete_level === "" || TAC === false) {
  //     setDisable(true);
  //   } else {
  //     setDisable(false);
  //   }
  // }, [Props.formState.athlete_level, TAC]);

  // useEffect(() => {
  //   if (Array.isArray(Props.formState.selectedDistancesCycle)) {
  //     if (Props.formState.selectedDistancesCycle.length === 0) {
  //       Props.dispatch(updateForm({ selectedDistancesCycle: "" }));
  //     } else {
  //       Props.dispatch(
  //         updateForm({
  //           selectedDistancesCycle:
  //             Props.formState.selectedDistancesCycle.join(", "),
  //         })
  //       );
  //     }
  //   }

  //   if (Array.isArray(Props.formState.selectedDistancesRun)) {
  //     if (Props.formState.selectedDistancesRun.length === 0) {
  //       Props.dispatch(updateForm({ selectedDistancesRun: "" }));
  //     } else {
  //       Props.dispatch(
  //         updateForm({
  //           selectedDistancesRun:
  //             Props.formState.selectedDistancesRun.join(", "),
  //         })
  //       );
  //     }
  //   }

  //   if (Array.isArray(Props.formState.selectedDistancesSwim)) {
  //     if (Props.formState.selectedDistancesSwim.length === 0) {
  //       Props.dispatch(updateForm({ selectedDistancesSwim: "" }));
  //     } else {
  //       Props.dispatch(
  //         updateForm({
  //           selectedDistancesSwim:
  //             Props.formState.selectedDistancesSwim.join(", "),
  //         })
  //       );
  //     }
  //   }

  //   if (Array.isArray(Props.formState.selectedDistancesTriathlon)) {
  //     if (Props.formState.selectedDistancesTriathlon.length === 0) {
  //       Props.dispatch(updateForm({ selectedDistancesTriathlon: "" }));
  //     } else {
  //       Props.dispatch(
  //         updateForm({
  //           selectedDistancesTriathlon:
  //             Props.formState.selectedDistancesTriathlon.join(", "),
  //         })
  //       );
  //     }
  //   }
  // }, []);

  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleBackClick = () => {
    setBackClicked(true);
  };

  const handleCheckboxChange = (value) => {
    // If the value is already selected, remove it from the array
    const updatedSelectedDistancesRun = Props.formState.athlete_level.includes(
      value
    )
      ? Props.formState.athlete_level.filter((item) => item !== value)
      : [...Props.formState.athlete_level, value];

    Props.dispatch(updateForm({ athlete_level: updatedSelectedDistancesRun }));
  };

  // Form Submit
  async function handleSubmit(e) {
    e.preventDefault();
    try {
      const req = await SubmitCoachForm(
        Props.formState.name,
        Props.formState.tagline,
        Props.email,
        Props.formState.number,
        Props.formState.location,
        Props.gender,
        Props.formState.aboutYourSelf,
        Props.formState.selectedDistancesRun,
        Props.formState.selectedDistancesSwim,
        Props.formState.selectedDistancesCycle,
        Props.formState.selectedDistancesTriathlon,
        Props.formState.coverPhotoPath,
        Props.formState.profilePhotoPath,
        Props.formState.videoPath,
        Props.formState.certifications,
        Props.formState.coaching_since,
        Props.formState.athlete_trained,
        Props.formState.languages.join(", "),
        Props.formState.athlete_level.join(", "),
        coaches,
        TAC
      );

      if (req === true) {
        AppData.setCoachFormSubmit(true);
        navigate("/");
      } else {
        setErrorHeader(ERROR_CONSTANTS.NetworkError.Heading);
        setErrorMessage(ERROR_CONSTANTS.NetworkError.Message);
        setErrorPopup(true);
      }
    } catch (error) {
      setErrorHeader(ERROR_CONSTANTS.NetworkError.Heading);
      setErrorMessage(ERROR_CONSTANTS.NetworkError.Message);
      setErrorPopup(true);
    }
  }

  return (
    <div>
      {errorPopup && (
        <>
          <div className="blur"></div>
          <ErrorPopup
            message={{
              heading: errorHeader,
              message: errorMessage,
            }}
            setErrorPopup={setErrorPopup}
          />
        </>
      )}
      {backClicked ? (
        <PhoneFormStep8 />
      ) : (
        <>
          {nextClicked && validation ? (
            <></>
          ) : (
            <>
              <SuccessPopup
                setIsSignupDone={setIsSignupDone}
                isSignupDone={isSignupDone}
                message={{
                  heading: "Coach Request Is Submitted successfully!",
                  message:
                    "your request will get verified in 5-6 working days.",
                }}
              />
              <div className="coach_form">
                <div className="form_section">
                  <form action="" method="post" className="mt-16 m-0">
                    <div className="step2_div">
                      <div className="question_1 ">
                        <h4>Which level athlete you train ? </h4>
                      </div>

                      <div className="question_2">
                        <div className="yes_no_div swim ">
                          <label>
                            <input
                              type="checkbox"
                              name="radio"
                              value="Beginner"
                              onChange={() => handleCheckboxChange("Beginner")}
                              checked={Props.formState.athlete_level.includes(
                                "Beginner"
                              )}
                            />
                            <span>Beginner</span>
                          </label>

                          <label>
                            <input
                              type="checkbox"
                              name="radio"
                              id=""
                              value="Intermediate"
                              onChange={() =>
                                handleCheckboxChange("Intermediate")
                              }
                              checked={Props.formState.athlete_level.includes(
                                "Intermediate"
                              )}
                            />
                            <span>Intermediate</span>
                          </label>

                          <label>
                            <input
                              type="checkbox"
                              name="radio"
                              id=""
                              value="Professional"
                              onChange={() =>
                                handleCheckboxChange("Professional")
                              }
                              checked={Props.formState.athlete_level.includes(
                                "Professional"
                              )}
                            />
                            <span>Professional</span>
                          </label>
                        </div>
                        <div className="text-center mt-5">
                          <input
                            type="checkbox"
                            id="terms"
                            value="TAC"
                            onChange={() => setTAC(!TAC)}
                            className="border-black mr-2"
                          />
                          <label htmlFor="terms">
                            I agree to the Terms of use,{" "}
                            <a
                              className="text-[var(--link)]"
                              href="https://www.trackofit.com/privacypolicy.html"
                            >
                              Privacy Policy{" "}
                            </a>
                            and{" "}
                            <a className="text-[var(--link)]" href="">
                              Refund Policy
                            </a>
                          </label>
                        </div>

                        <div className="flex justify-between statusBarPhone">
                          {popper && (
                            <div className="popper absolute bottom-11 right-0">
                              <p>Fill up the fileds</p>
                            </div>
                          )}
                          <div
                            className="mr-[10px] mt-[10px] cancelButton"
                            onClick={handleBackClick}
                          >
                            <div className=" flex items-center">
                              <img
                                src={BackArrow}
                                alt=""
                                className="next_img mr-1 ml-[-3px]"
                              />
                              Back
                            </div>
                          </div>
                          <div className="coach_cancel_Div flex ">
                            <p
                              className="mr-[10px] mt-[10px] cancelButton"
                              onClick={() => {
                                navigate("/");
                              }}
                            >
                              Cancel
                            </p>

                            <button
                              className="Coach_from_submit nextButton"
                              type="submit"
                              onClick={handleSubmit}
                              disabled={disable}
                            >
                              <p>Submit</p>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default PhoneFormStep9;
