import React, { useState, useEffect } from "react";
import { API_URL_CONSTANTS } from "utility/constants/apiUrlConstants";
import Edit from "assets/icons/edit.png";
import ErrorPopup from "components/popups/ErrorPopup";
import { ERROR_CONSTANTS } from "utility/constants/errorConstants.js";
import { EditVideo, SubmitVideo } from "api/CoachFormController";
import { useSelector, useDispatch } from "react-redux";
import { addViewAccountData } from "state/ViewAccountState";
import { updateForm } from "state/SetCoachFormData";
import { SubmitCoachForm } from "api/CoachFormController";
import { getApis } from "hooks/api";
import { getFormData } from "api/CoachFormController";

function AccountVideo(props) {
  const { isEdit, setEdit, data, type } = props;
  // const data = useSelector((state) => state.viewAccount);
  // const { videoUrl, setVideoPath, handleVideo } = props;

  const dispatch = useDispatch();
  const [videoFileURL, setVideoFileURL] = useState("");
  const [errorHeader, setErrorHeader] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorPopup, setErrorPopup] = useState(false);

  const TAC = true;
  const selectedDistancesRun = [];
  const selectedDistancesSwim = [];
  const selectedDistancesCycle = [];
  const selectedDistancesTriathlon = [];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // get data for coach form
  const fetchCoachFormData = async () => {
    const res = await getFormData();

    if (res.data.cover_photo) {
      // setCoverImgSrc(res.data.cover_photo);
    } else {
      const resCoverPhoto = await getApis(API_URL_CONSTANTS.GET_COVER_IMG);
      // setCoverImgSrc(resCoverPhoto.data.message);
    }
    if (res.data.profile_photo) {
      // setProfileImgSrc(res.data.profile_photo);
    } else {
      const resProfilePhoto = await getApis(API_URL_CONSTANTS.GET_PROFILE_IMG);
      // setProfileImgSrc(resProfilePhoto.data.message);
    }
    if (res.data.video) {
      // setVideoUrl(res.data.video);
    }

    // dispatch(CoachFormData({ getCoachFromData: res.data }));
    dispatch(updateForm(res.data));
  };

  const checkStatusVideo = async (file) => {
    if (type === "account") {
      const req = await EditVideo(file);
      if (req) {
        dispatch(addViewAccountData({ video: req.data.path }));
        dispatch(addViewAccountData({ getVideo: URL.createObjectURL(file) }));
      } else {
        setErrorHeader(ERROR_CONSTANTS.NetworkError.Heading);
        setErrorMessage(ERROR_CONSTANTS.NetworkError.Message);
        setErrorPopup(true);
      }
    } else if (type === "editForm") {
      const res = await SubmitVideo(file);
      // dispatch(updateForm({ video: URL.createObjectURL(file) }));
      // dispatch(updateForm({ getVideo: res.path }));
      dispatch(updateForm({ video: res.path }));
      const req = await SubmitCoachForm(
        data.name,
        data.tagline,
        data.email,
        data.mobile,
        data.location,
        data.gender,
        data.about,
        selectedDistancesRun,
        selectedDistancesSwim,
        selectedDistancesCycle,
        selectedDistancesTriathlon,
        data.cover_photo,
        data.profile_photo,
        res.path || null,
        data.certifications,
        data.coaching_since,
        data.athlete_trained,
        data.languages,
        data.athlete_level,
        data.coaches,
        TAC
      );
      if (req) {
        setEdit(false);
        fetchCoachFormData();
      } else {
        alert("res.data.message");
      }
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0]; // Get the selected file

    if (file) {
      // Validate file size
      const fileSize = file.size;

      if (fileSize < 10000 || fileSize > 5000000) {
        setErrorHeader("Video Error");
        setErrorMessage(
          "Video size must be between 10,000 bytes and 50,000 bytes."
        );
        setErrorPopup(true);
        return; // Exit the function if size is not valid
      }

      // Validate file type
      if (file.type.startsWith("video/")) {
        const videoElement = document.createElement("video");

        const handleMetadataLoaded = () => {
          const durationInSeconds = Math.round(videoElement.duration);

          videoElement.removeEventListener(
            "loadedmetadata",
            handleMetadataLoaded
          );

          if (durationInSeconds <= 60) {
            checkStatusVideo(file);
          } else {
            setErrorHeader("Video Error");
            setErrorMessage(
              "Video duration exceeds the maximum allowed duration. Video should be less than 60s."
            );
            setErrorPopup(true);
          }
        };

        videoElement.addEventListener("loadedmetadata", handleMetadataLoaded);
        videoElement.src = URL.createObjectURL(file);
      } else {
        setErrorHeader("Video Error");
        setErrorMessage("Please select a valid video file.");
        setErrorPopup(true);
      }
    } else {
      setErrorHeader("Video Error");
      setErrorMessage("No file selected.");
      setErrorPopup(true);
    }
  };

  return (
    <>
      {errorPopup && (
        <>
          <div className="blur"></div>
          <ErrorPopup
            message={{
              heading: errorHeader,
              message: errorMessage,
            }}
            setErrorPopup={setErrorPopup}
          />
        </>
      )}
      <div className="profile_box border-none mt-0">
        <div className="input_info_box">
          <div className="video_box p-0">
            <div className="video_div m-0">
              {type === "editForm" ? (
                <>
                  <video
                    id="video"
                    // src={
                    //   data?.video
                    //     ? data.getVideo
                    //       ? ` ${data.video}`
                    //       : `${API_URL_CONSTANTS.DOMAIN_NAME}${data.video}`
                    //     : ""
                    // }
                    src={`${API_URL_CONSTANTS.DOMAIN_NAME}${data.video}`}
                    controls
                  ></video>
                </>
              ) : (
                <>
                  <video
                    id="video"
                    src={
                      data?.video
                        ? `${API_URL_CONSTANTS.DOMAIN_NAME}${data.video}`
                        : ""
                    }
                    controls
                  ></video>
                </>
              )}

              <div className="">
                <div className="edit_cricle">
                  <input
                    id="video-input"
                    type="file"
                    accept="video/*"
                    onChange={(event) => handleFileChange(event)}
                    className="hidden"
                  />
                  <label className="c" htmlFor="video-input">
                    <img src={Edit} alt="" />
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AccountVideo;
