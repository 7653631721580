import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getFormData } from "api/CoachFormController";
import { getApis } from "hooks/api";
import { API_URL_CONSTANTS } from "utility/constants/apiUrlConstants";
import { useDispatch, useSelector } from "react-redux";
import { updateForm } from "state/SetCoachFormData";
import EditSection from "components/viewAccount/EditSection";
import PhoneEditCoach from "./PhoneEditCoach";

function EditCoachForm() {
  const data = useSelector((state) => state.setCoachFromData);
  const dispatch = useDispatch();
  const [activeMenu, setActiveMenu] = useState("about"); // Tracks active menu item
  const [phoneActivMenu, setPhoneActivMenu] = useState(""); // Tracks active menu item for phone

  const [isEdit, setEdit] = useState(false);

  if (activeMenu === "settings") {
    window.location.href = "https://www.trackofit.com/privacypolicy.html";
  }

  useEffect(() => {
    try {
      const fetchData = async () => {
        const res = await getFormData();

        if (res.data.cover_photo) {
          // setCoverImgSrc(res.data.cover_photo);
        } else {
          const resCoverPhoto = await getApis(API_URL_CONSTANTS.GET_COVER_IMG);
          // setCoverImgSrc(resCoverPhoto.data.message);
        }
        if (res.data.profile_photo) {
          // setProfileImgSrc(res.data.profile_photo);
        } else {
          const resProfilePhoto = await getApis(
            API_URL_CONSTANTS.GET_PROFILE_IMG
          );
          // setProfileImgSrc(resProfilePhoto.data.message);
        }
        if (res.data.video) {
          // setVideoUrl(res.data.video);
        }

        // dispatch(CoachFormData({ getCoachFromData: res.data }));
        dispatch(updateForm(res.data));
      };

      fetchData();
    } catch (error) {
      return error;
    }
  }, []);

  return (
    <>
      <div className=" sm:hidden  bg-[var(--light-grey)]">
        <EditSection
          activeMenu={activeMenu}
          setActiveMenu={setActiveMenu}
          isEdit={isEdit}
          setEdit={setEdit}
          data={data}
          type="editForm"
          isCoach="editCoach"
        />
      </div>

      <div className=" sm:!block" style={{ display: "none" }}>
        <PhoneEditCoach
          phoneActivMenu={phoneActivMenu}
          setPhoneActivMenu={setPhoneActivMenu}
          isEdit={isEdit}
          setEdit={setEdit}
          data={data}
          type="editForm"
          isCoach="editCoach"
        />
      </div>
    </>
  );
}

export default EditCoachForm;
