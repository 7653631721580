import React, { useContext, useEffect, useState, useRef } from "react";
import FormStep3 from "./FormStep3";
import RightArrow from "assets/icons/right-arrow.png";
import { useNavigate } from "react-router-dom";
import { SubmitCoachFormData } from "utility/constants/useContext";
import BackArrow from "assets/icons/back.png";
import CoachForm from "../CoachForm";
import Nav from "components/navbar/Nav";
import PhoneNavBar from "components/navbar/PhoneNavBar";
import PopUpLoginP from "components/signin/PhonePopUpLogin";
import { updateForm } from "state/SetCoachFormData";

function FormStep2() {
  const [validation, setValidation] = useState(false);
  const [popper, setPopper] = useState(false);
  const [nextClicked, setNextClicked] = useState(false);
  const navigate = useNavigate();
  const Props = useContext(SubmitCoachFormData);
  const [backClicked, setBackClicked] = useState(false);

  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (Props.formState.isRun === "yes" || Props.formState.isRun === "no") {
      setValidation(true);
    } else {
      setValidation(false);
    }
  }, [Props.formState.isRun]);

  const handleCheckboxChange = (value) => {
    // If the value is already selected, remove it from the array
    const updatedSelectedDistancesRun =
      Props.formState.selectedDistancesRun.includes(value)
        ? Props.formState.selectedDistancesRun.filter((item) => item !== value)
        : [...Props.formState.selectedDistancesRun, value];

    Props.dispatch(
      updateForm({ selectedDistancesRun: updatedSelectedDistancesRun })
    );
  };

  return (
    <>
      {backClicked ? (
        <CoachForm />
      ) : (
        <>
          {nextClicked && validation ? (
            <FormStep3 />
          ) : (
            <>
              <div className="coach_form  form-step-2">
                <div className="form_section">
                  <form action="" method="post" className="mt-16 m-0">
                    <div className="form_step2 ">
                      <div className="step2_div">
                        <div className="question_1 swim">
                          <h4>Do you train athletes for running ?</h4>
                          <div className="yes_no_div swim">
                            <label>
                              <input
                                type="radio"
                                name="radio"
                                onChange={() =>
                                  Props.dispatch(updateForm({ isRun: "yes" }))
                                }
                                checked={Props.formState.isRun === "yes"}
                              />
                              <span>Yes</span>
                            </label>

                            <label>
                              <input
                                type="radio"
                                name="radio"
                                onChange={() => {
                                  Props.dispatch(
                                    updateForm({ selectedDistancesRun: [] })
                                  );
                                  Props.dispatch(updateForm({ isRun: "no" }));
                                }}
                                checked={Props.formState.isRun === "no"}
                              />
                              <span>No</span>
                            </label>
                          </div>
                        </div>

                        {Props.formState.isRun === "yes" ? (
                          <>
                            <div className="question_2">
                              <h4>Select distances</h4>
                              <div className="yes_no_div swim pb-[2rem]">
                                <label>
                                  <input
                                    type="checkbox"
                                    name="radio"
                                    id=""
                                    value="5k"
                                    onChange={() => handleCheckboxChange("5k")}
                                    checked={Props.formState.selectedDistancesRun.includes(
                                      "5k"
                                    )}
                                  />
                                  <span>5k</span>
                                </label>

                                <label>
                                  <input
                                    type="checkbox"
                                    name="radio"
                                    id=""
                                    value="7k"
                                    onChange={() => handleCheckboxChange("7k")}
                                    checked={Props.formState.selectedDistancesRun.includes(
                                      "7k"
                                    )}
                                  />
                                  <span>7k</span>
                                </label>

                                <label>
                                  <input
                                    type="checkbox"
                                    name="radio"
                                    id=""
                                    value="10k"
                                    onChange={() => handleCheckboxChange("10k")}
                                    checked={Props.formState.selectedDistancesRun.includes(
                                      "10k"
                                    )}
                                  />
                                  <span>10k</span>
                                </label>

                                <label>
                                  <input
                                    type="checkbox"
                                    name="radio"
                                    id=""
                                    value="16k"
                                    onChange={() => handleCheckboxChange("16k")}
                                    checked={Props.formState.selectedDistancesRun.includes(
                                      "16k"
                                    )}
                                  />
                                  <span>16k</span>
                                </label>

                                <label>
                                  <input
                                    type="checkbox"
                                    name="radio"
                                    id=""
                                    value="21k"
                                    onChange={() => handleCheckboxChange("21k")}
                                    checked={Props.formState.selectedDistancesRun.includes(
                                      "21k"
                                    )}
                                  />
                                  <span>21k</span>
                                </label>

                                <label>
                                  <input
                                    type="checkbox"
                                    name="radio"
                                    id=""
                                    value="42k"
                                    onChange={() => handleCheckboxChange("42k")}
                                    checked={Props.formState.selectedDistancesRun.includes(
                                      "42k"
                                    )}
                                  />
                                  <span>42k</span>
                                </label>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                        <div className="next_steps_btn">
                          {popper && (
                            <div className=" ">
                              <p className=" text-[var(--red)] absolute bottom-12 right-40">
                                fill up the fileds
                              </p>
                            </div>
                          )}

                          <p
                            className="statusBar "
                            onClick={() => {
                              if (validation === true) {
                                setNextClicked(true);
                                setPopper(false);
                              } else {
                                if (validation === false) {
                                  setPopper(true);
                                }
                              }
                            }}
                          >
                            <div className="flex justify-between">
                              <div
                                className="mr-[10px] mt-[10px] cancelButton"
                                // onClick={handleBackClick}
                              >
                                <div
                                  className=" flex items-center"
                                  onClick={() => setBackClicked(true)}
                                >
                                  <img
                                    src={BackArrow}
                                    alt=""
                                    className="next_img mr-1 ml-[-3px]"
                                  />
                                  Back
                                </div>
                              </div>
                              <div className="coach_cancel_Div flex">
                                <p
                                  className="mr-[10px] mt-[10px] cancelButton"
                                  onClick={() => {
                                    navigate("/");
                                  }}
                                >
                                  Cancel
                                </p>
                                <div className="nextButton">
                                  Next{" "}
                                  <img
                                    src={RightArrow}
                                    alt=""
                                    className="next_img mt-[6px]"
                                  />
                                </div>
                              </div>
                            </div>
                          </p>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}
export default FormStep2;
