import React from "react";
import Logo from "assets/img/tofAppIcon1.png";
import { useMobileOperatingSystem } from "hooks/checkUserdevice";

function Redirect(props) {
  const mobileOS = useMobileOperatingSystem(); // check user Device (iOS/Android)
  return (
    <div className="fixed top-[35%]  w-[80%] bg-[var(--white)] z-[150] py-4 rounded-[5px]">
      <div className="flex flex-col px-4 ">
        <img className="h-[3rem] w-[5rem]" src={Logo} alt="" />
        <p className="py-4 text-[var(--light-blue)]">
          To view workout details open the APP.
        </p>
      </div>

      <div className=" border-t-[.5px] border-solid border-[#DFDFDF] px-4">
        <div className="flex justify-end items-center pt-4 ">
          <button
            className="bg-[#D5D5D5] px-2 rounded-[2px] mr-4"
            onClick={() => props.setRedirect(false)}
          >
            Cancel
          </button>

          {/* ANDROID */}
          {mobileOS === "Android" && (
            <button className="bg-[#ffb703b3] px-2 rounded-[2px] text-black">
              <a href="https://play.google.com/store/apps/details?id=com.isf.trackofit&pli=1">
                Open
              </a>
            </button>
          )}

          {/* iOS */}
          {mobileOS === "iOS" && (
            <button className="bg-[#ffb703b3] px-2 rounded-[2px] text-black">
              <a href="https://apps.apple.com/in/app/trackofit/id6469041232">
                Open
              </a>
            </button>
          )}

          {/* unknown */}
          {mobileOS === "unknown" && (
            <button className="bg-[#ffb703b3] px-2 rounded-[2px] text-black">
              <a href="https://play.google.com/store/apps/details?id=com.isf.trackofit&pli=1">
                Open
              </a>
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default Redirect;
