import React, { useContext, useEffect, useState } from "react";
import PhoneFormStep7 from "./PhoneFormStep7";
import PhoneFormStep5 from "./PhoneFormStep5";
import RightArrow from "assets/icons/right-arrow.png";
import { useNavigate } from "react-router-dom";
import { SubmitCoachFormData } from "utility/constants/useContext";
import BackArrow from "assets/icons/back.png";
import { updateForm } from "state/SetCoachFormData";

function PhoneFormStep6() {
  const Props = useContext(SubmitCoachFormData);
  const navigate = useNavigate();
  const [backClicked, setBackClicked] = useState(false);
  const [isYes, setYes] = useState("");
  const [validation, setValidation] = useState(false);
  const [nextClicked, setNextClicked] = useState(false);
  const [popper, setPopper] = useState(false);

  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (Props.formState.isCycle === "yes" || Props.formState.isCycle === "no") {
      setValidation(true);
    } else {
      setValidation(false);
    }
  });
  const handleBackClick = () => {
    setBackClicked(true);
  };
  const handleCheckboxChange = (value) => {
    // If the value is already selected, remove it from the array
    const updatedSelectedDistancesRun =
      Props.formState.selectedDistancesCycle.includes(value)
        ? Props.formState.selectedDistancesCycle.filter(
            (item) => item !== value
          )
        : [...Props.formState.selectedDistancesCycle, value];

    Props.dispatch(
      updateForm({ selectedDistancesCycle: updatedSelectedDistancesRun })
    );
  };

  return (
    <div>
      {backClicked ? (
        <PhoneFormStep5 />
      ) : (
        <>
          {nextClicked && validation ? (
            <PhoneFormStep7 />
          ) : (
            <>
              {" "}
              <div className="coach_form">
                <div className="form_section">
                  <form action="" method="post" className="mt-16 m-0">
                    <div className="phone_form_step6 mt-16">
                      <div className="step2_div">
                        <div className="question_1 ">
                          <h4>Do you train athletes for Cycling ?</h4>
                          <div className="yes_no_div swim">
                            <label>
                              <input
                                type="radio"
                                name="radio"
                                onChange={() =>
                                  Props.dispatch(updateForm({ isCycle: "yes" }))
                                }
                                checked={Props.formState.isCycle === "yes"}
                              />
                              <span>Yes</span>
                            </label>

                            <label>
                              <input
                                type="radio"
                                name="radio"
                                onChange={() => {
                                  Props.dispatch(
                                    updateForm({ selectedDistancesCycle: [] })
                                  );
                                  Props.dispatch(updateForm({ isCycle: "no" }));
                                }}
                                checked={Props.formState.isCycle === "no"}
                              />
                              <span>No</span>
                            </label>
                          </div>
                        </div>

                        {Props.formState.isCycle === "yes" ? (
                          <>
                            <div className="question_2">
                              <h4>Select distances</h4>
                              <div className="yes_no_div swim pb-[4rem]">
                                <label>
                                  <input
                                    type="checkbox"
                                    name="radio"
                                    onChange={() => handleCheckboxChange("40k")}
                                    checked={Props.formState.selectedDistancesCycle.includes(
                                      "40k"
                                    )}
                                  />
                                  <span>40k</span>
                                </label>

                                <label>
                                  <input
                                    type="checkbox"
                                    name="radio"
                                    id=""
                                    onChange={() => handleCheckboxChange("90k")}
                                    checked={Props.formState.selectedDistancesCycle.includes(
                                      "90k"
                                    )}
                                  />
                                  <span>90k</span>
                                </label>

                                <label>
                                  <input
                                    type="checkbox"
                                    name="radio"
                                    id=""
                                    onChange={() =>
                                      handleCheckboxChange("100k")
                                    }
                                    checked={Props.formState.selectedDistancesCycle.includes(
                                      "100k"
                                    )}
                                  />
                                  <span>100k</span>
                                </label>

                                <label>
                                  <input
                                    type="checkbox"
                                    name="radio"
                                    id=""
                                    onChange={() =>
                                      handleCheckboxChange("160k")
                                    }
                                    checked={Props.formState.selectedDistancesCycle.includes(
                                      "160k"
                                    )}
                                  />
                                  <span>160k</span>
                                </label>

                                <label>
                                  <input
                                    type="checkbox"
                                    name="radio"
                                    id=""
                                    onChange={() =>
                                      handleCheckboxChange("200k")
                                    }
                                    checked={Props.formState.selectedDistancesCycle.includes(
                                      "200k"
                                    )}
                                  />
                                  <span>200k</span>
                                </label>

                                <label>
                                  <input
                                    type="checkbox"
                                    name="radio"
                                    id=""
                                    onChange={() =>
                                      handleCheckboxChange("Time Trail")
                                    }
                                    checked={Props.formState.selectedDistancesCycle.includes(
                                      "Time Trail"
                                    )}
                                  />
                                  <span>Time Trail</span>
                                </label>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                        <div className="Phone_next_steps_btn">
                          {popper && (
                            <div className="popper absolute bottom-11 right-0">
                              <p>Fill up the fileds</p>
                            </div>
                          )}
                          <div
                            className="statusBarPhone"
                            onClick={() => {
                              if (validation === true) {
                                setNextClicked(true);
                                setPopper(false);
                              } else {
                                if (validation === false) {
                                  setPopper(true);
                                }
                              }
                            }}
                          >
                            <div className="flex justify-between">
                              <p
                                className="mr-[10px] mt-[10px] cancelButton"
                                onClick={handleBackClick}
                              >
                                <div className=" flex items-center">
                                  <img
                                    src={BackArrow}
                                    alt=""
                                    className="next_img mr-1 ml-[-3px]"
                                  />
                                  Back
                                </div>
                              </p>
                              <div className="coach_cancel_Div flex">
                                <p
                                  className="mr-[10px] mt-[10px] cancelButton"
                                  onClick={() => {
                                    navigate("/");
                                  }}
                                >
                                  Cancel
                                </p>
                                <div className="nextButton">
                                  Next{" "}
                                  <img
                                    src={RightArrow}
                                    alt=""
                                    className="next_img mt-[6px]"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}
export default PhoneFormStep6;
