import React, { useEffect } from "react";
import CoverPhoto from "components/coverPhoto/CoverPhoto";
import SubMenu from "components/viewAccount/SubMenu";
import AccountAbout from "components/viewAccount/AccountAbout";
import YouTrainViewAccount from "components/viewAccount/YouTrainViewAccount";
import EditHeader from "components/viewAccount/EditHeader";
import AccountVideo from "components/viewAccount/AccountVideo";
import AccountCertificates from "components/viewAccount/AccountCertificates";
import { useNavigate } from "react-router-dom";

function EditSection(props) {
  const { activeMenu, setActiveMenu, isEdit, setEdit, data, type, isCoach } =
    props;
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="flex flex-col width_70_div min-h-screen !mt-0 !pb-0 pt-[4rem]">
      <CoverPhoto isCoach={isCoach} />
      <div className="flex flex-grow h-full">
        {/* Left Submenu */}
        <SubMenu
          activeMenu={activeMenu}
          setActiveMenu={setActiveMenu}
          type={type}
        />

        {/* Right side content */}
        <div className="w-full">
          <EditHeader
            isEdit={isEdit}
            setEdit={setEdit}
            data={data}
            type={type}
          />
          {activeMenu === "about" && (
            <AccountAbout
              isEdit={isEdit}
              setEdit={setEdit}
              data={data}
              type={type}
            />
          )}
          {activeMenu === "video" && (
            <AccountVideo
              isEdit={isEdit}
              setEdit={setEdit}
              data={data}
              type={type}
            />
          )}
          {activeMenu === "certificates" && (
            <AccountCertificates
              isEdit={isEdit}
              setEdit={setEdit}
              data={data}
              type={type}
            />
          )}
          {activeMenu === "youTrain" && (
            <YouTrainViewAccount
              isEdit={isEdit}
              setEdit={setEdit}
              data={data}
              type={type}
            />
          )}
          {activeMenu === "billing" && navigate("/mybilling")}
        </div>
      </div>
    </div>
  );
}

export default EditSection;
