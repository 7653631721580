import React, { useEffect, useRef, useState } from "react";
import { format, toDate } from "date-fns";
import RightArrow from "assets/icons/right-arrow.png";
import MonthIcon from "assets/icons/month_icon.svg";
import WeekIcon from "assets/icons/week_icon.svg";
import MonthView from "../../components/calendar/compo/MonthView";
import TrainingSlider from "../../components/calendar/compo/TrainingSlider";
import PhoneView from "components/calendar/compo/PhoneView";
import { CalendarData } from "utility/constants/useContext";
import SEO from "hooks/Seo";
import { HelmetProvider } from "react-helmet-async";
import { getApis } from "hooks/api";
import { API_URL_CONSTANTS } from "utility/constants/apiUrlConstants";
function TrainingCalendar() {
  const FromDate = useRef("");
  const ToDate = useRef("");
  const [click, setClick] = useState(false);
  const [mView, setMview] = useState(true);
  const [refId, setRefId] = useState();
  const [calendarData, setCalendarData] = useState([]);
  const [stepsData, setStpesData] = useState([]);
  const [trainigSliderDate, setTrainingSilderDate] = useState("");

  // get  calendar data from api
  useEffect(() => {
    const fetchCalendarList = async () => {
      const res = await getApis(API_URL_CONSTANTS.GET_USER_CALENDAR_LIST);

      if (res && !res.error) {
        FromDate.current = res.data.from;
        ToDate.current = res.data.to;
        if (res.data.status_code === 1) {
          setCalendarData(res.data.day_workouts);
        } else if (res.data.status_code === 2) {
        }
      } else {
        alert("Error fetching calendar list:", res.error);
      }
    };
    fetchCalendarList();
  }, []);

  // UseContext object
  const calendarPoprs = {
    click,
    setClick,
    mView,
    setMview,
    calendarData,
    refId,
    setRefId,
    stepsData,
    setStpesData,
    FromDate,
    ToDate,
    trainigSliderDate,
    setTrainingSilderDate,
  };

  const currentDay = new Date();

  // toggle to week/month
  const handleSelectChange = (e) => {
    const value = e.target.value;

    if (value === "Week") {
      setMview(false);
    }

    if (value === "Month") {
      setMview(true);
    }
  };

  return (
    <HelmetProvider>
      <SEO
        title="Calendar | Trackofit"
        descriptionName="Calendar"
        description="Training Calendar"
        canonicalUrl="https://www.trackofit.com"
      />
      <CalendarData.Provider value={calendarPoprs}>
        {/* Close Steps slider  */}
        {click && (
          <div
            className="fixed w-full h-[100vh] bg-transparent z-10"
            onClick={() => setClick(false)}
          ></div>
        )}

        {/* PHONE CALENDAR */}
        <div className="phone_calendar">
          <PhoneView />
        </div>
        {/* DEKSTOP CALENDAR */}
        <div className="pt-[4rem] md:hidden">
          <div
            className={`flex  py-8  mx-auto my-0 ${
              click ? "w-full" : "w-[full"
            }`}
          >
            {/* Calendar div with week and month selector */}
            <div className="flex flex-col w-full px-[1rem]">
              <div className="flex justify-between mb-3">
                <div className="flex items-center border-[#DBDBDB] border-[.5px] border-solid p-1 rounded-[5px]">
                  {/* <img src={RightArrow} className="rotate-180 h-3" alt="" /> */}
                  <span className="text-[var(--blue)] px-1">
                    {format(currentDay, "MMMM yyyy")}
                  </span>
                  {/* <img src={RightArrow} className="h-3" alt="" /> */}
                </div>
                <div className="flex items-center calendar_border rounded-[5px] p-1">
                  {mView ? (
                    <img className="h-3" src={MonthIcon} alt="" />
                  ) : (
                    <img className="h-3" src={WeekIcon} alt="" />
                  )}

                  <select
                    onChange={handleSelectChange}
                    className="outline-none"
                    name=""
                    id=""
                  >
                    <option value="Month"> Month</option>

                    <option value="Week">Week</option>
                  </select>
                </div>
              </div>
              <MonthView />
            </div>
            {/* SLIDER  */}
            <TrainingSlider />
          </div>
        </div>
      </CalendarData.Provider>
    </HelmetProvider>
  );
}

export default TrainingCalendar;
