import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addViewAccountData } from "state/ViewAccountState";
import { updateForm } from "state/SetCoachFormData";

import Cancel from "assets/icons/cancel.svg";

function CategorySelect({ categoryName, options, data, type }) {
  const dispatch = useDispatch();
  const viewAccountData = useSelector((state) => state.viewAccount);
  const [selectedValues, setSelectedValues] = useState([]);

  const addDataTo = type === "account" ? addViewAccountData : updateForm;

  // Prefill values on component mount
  useEffect(() => {
    const coachData = data.coaches.find(
      (coach) => coach.category === categoryName
    );
    if (coachData) {
      setSelectedValues(coachData.value.split(", ")); // Split string into array
    }
  }, [data, categoryName]);

  // Filter options to exclude selected values
  const filteredOptions = options.filter(
    (option) => !selectedValues.includes(option)
  );

  // Handle value selection
  const handleValueChange = (event) => {
    const value = event.target.value;
    if (value && !selectedValues.includes(value)) {
      const updatedValues = [...selectedValues, value];
      setSelectedValues(updatedValues);

      updateCoachesArray(updatedValues);
    }
  };

  // Handle value deletion
  const handleDeleteValue = (valueToDelete) => {
    const updatedValues = selectedValues.filter(
      (value) => value !== valueToDelete
    );
    setSelectedValues(updatedValues);

    updateCoachesArray(updatedValues);
  };

  // Update coaches array in Redux
  const updateCoachesArray = (updatedValues) => {
    let updatedCoaches;

    if (updatedValues.length > 0) {
      // If there are values, update the existing category
      updatedCoaches = data.coaches.map((coach) => {
        if (coach.category === categoryName) {
          return { ...coach, value: updatedValues.join(", ") }; // Join array into string
        }
        return coach;
      });
    } else {
      // If no values, remove the category object from the array
      updatedCoaches = data.coaches.filter(
        (coach) => coach.category !== categoryName
      );
    }

    // Dispatch the updated coaches array
    dispatch(addDataTo({ coaches: updatedCoaches }));
  };

  return (
    <div className="first_half mb-4 px-1">
      <p className="lable_tag">{categoryName}</p>
      <div className="flex flex-wrap ">
        {selectedValues.map((value, index) => (
          <div
            key={index}
            className="flex justify-around items-center bg-[#EDEDEB] text-[var(--light-blue)] px-1 my-1 mr-2"
          >
            <span className="pr-1">{value}</span>
            <img
              src={Cancel}
              className="h-2 w-2"
              alt="cancel btn"
              onClick={() => handleDeleteValue(value)}
            />
          </div>
        ))}
      </div>
      <select
        className="p-2 font-normal text-base border-2 border-[var(--light-grey)] bg-white text-[var(--light-blue)] w-4/5 appearance-none outline-none"
        onChange={handleValueChange}
        value="" // Reset dropdown to show placeholder
      >
        <option value="">Select Distance</option>
        {filteredOptions.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
}

export default CategorySelect;
