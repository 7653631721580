import React from "react";
import { Helmet } from "react-helmet-async";

const SEO = ({ title, description, canonicalUrl, descriptionName }) => {
  return (
    <Helmet>
      {title && <title>{title}</title>}
      {description && <meta name={descriptionName} content={description} />}
      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
    </Helmet>
  );
};

export default SEO;
