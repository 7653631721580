import { useState } from "react";
import {
  SubmitCoverPhoto,
  EditCoverPhoto,
  SubmitCoachForm,
} from "api/CoachFormController";
import { useSelector, useDispatch } from "react-redux";
import { updateForm } from "state/SetCoachFormData";
import { ERROR_CONSTANTS } from "utility/constants/errorConstants.js";
import { API_URL_CONSTANTS } from "utility/constants/apiUrlConstants";
import { getApis } from "hooks/api";
import { addCoachFormData } from "state/CoachFormData";

export const useCoverHandlers = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.setCoachFromData);

  const [photoURL, setPhotoURL] = useState(null);
  const [errorHeader, setErrorHeader] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorPopup, setErrorPopup] = useState(false);
  const [coverPhotoPath, setCoverPhotoPath] = useState(null);

  let coverPhotoFileURL = "";

  async function checkStatusCoverPhoto(file, actionType, isCoach) {
    if (actionType === "submit") {
      const req = await SubmitCoverPhoto(file);

      if (req.success === true) {
        setCoverPhotoPath(req.path);
        setPhotoURL(coverPhotoFileURL);
        dispatch(updateForm({ coverPhotoPath: req.path }));
        dispatch(updateForm({ getCover: coverPhotoFileURL }));
      } else {
        setErrorHeader(ERROR_CONSTANTS.NetworkError.Heading);
        setErrorMessage(ERROR_CONSTANTS.NetworkError.Message);
        setErrorPopup(true);
      }
    } else if (actionType === "edit" && isCoach === "") {
      const req = await EditCoverPhoto(file);
      if (req !== false) {
        const resCoverPhoto = await getApis(API_URL_CONSTANTS.GET_COVER_IMG);
        setPhotoURL(resCoverPhoto.data.message);
        window.location.reload();
      }
    } else if (isCoach === "editCoach" && actionType === "edit") {
      const req = await SubmitCoverPhoto(file);

      if (req.success === true) {
        let coverPath = req.path;
        dispatch(updateForm({ coverPhotoPath: req.path }));
        await SubmitCover(coverPath);
      } else {
        setErrorHeader(ERROR_CONSTANTS.NetworkError.Heading);
        setErrorMessage(ERROR_CONSTANTS.NetworkError.Message);
        setErrorPopup(true);
      }
    }
  }

  const handleFileChange = (event, actionType, isCoach) => {
    let fi = document.getElementById("file-input");
    if (fi.files.length > 0) {
      for (let i = 0; i <= fi.files.length - 1; i++) {
        let fsize = fi.files.item(i).size;
        let file = Math.round(fsize / 1024);
        if (file >= 418) {
          setErrorHeader("Cover Photo Error");
          setErrorMessage("File too Big, please select a file less than 410kb");
          setErrorPopup(true);
        } else {
          const file = event.target.files[0]; // Get the selected file

          if (file && file.type.startsWith("image/")) {
            coverPhotoFileURL = URL.createObjectURL(file); // Create a URL for the selected file

            const img = new Image();
            img.onload = () => {
              if (img.width === 1235 && img.height === 338) {
                checkStatusCoverPhoto(file, actionType, isCoach);
              } else {
                setErrorHeader("Cover Photo Error");
                setErrorMessage("Image dimension should be 1235px X 338px");
                setErrorPopup(true);
              }
            };
            img.src = coverPhotoFileURL;
          }
        }
      }
    }
  };

  // edit coach form cover photo

  async function SubmitCover(coverPath) {
    const TAC = true;
    const selectedDistancesRun = [];
    const selectedDistancesSwim = [];
    const selectedDistancesCycle = [];
    const selectedDistancesTriathlon = [];
    if (
      data.name === "" ||
      data.tagline === "" ||
      data.phone === "" ||
      data.location === "" ||
      data.gender === "" ||
      data.about === ""
    ) {
      setErrorHeader("Error");
      setErrorMessage(
        "Fill up Name, Tagline, Phone Number, Location, Gender, About"
      );
      setErrorPopup(true);
    } else {
      try {
        const req = await SubmitCoachForm(
          data.name,
          data.tagline,
          data.email,
          data.mobile,
          data.location,
          data.gender,
          data.about,
          selectedDistancesRun,
          selectedDistancesSwim,
          selectedDistancesCycle,
          selectedDistancesTriathlon,
          coverPath,
          data.profile_photo,
          data.video || null,
          data.certifications,
          data.coaching_since,
          data.athlete_trained,
          data.languages,
          data.athlete_level,
          data.coaches,
          TAC
        );

        if (req === true) {
          // window.location.reload(true);
        } else {
          setErrorHeader(ERROR_CONSTANTS.NetworkError.Heading);
          setErrorMessage(ERROR_CONSTANTS.NetworkError.Message);
          setErrorPopup(true);
        }
      } catch {
        setErrorHeader(ERROR_CONSTANTS.NetworkError.Heading);
        setErrorMessage(ERROR_CONSTANTS.NetworkError.Message);
        setErrorPopup(true);
      }
    }
  }

  return {
    handleFileChange,
    errorHeader,
    errorMessage,
    errorPopup,
    setErrorPopup,
    photoURL,
    coverPhotoPath,
  };
};
