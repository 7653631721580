import React, { useState } from "react";
import EditSection from "components/viewAccount/EditSection";
import PhoneEditCoach from "pages/coachForm/editCoachForm/PhoneEditCoach";
import { useSelector } from "react-redux";

function CoachAccount() {
  const data = useSelector((state) => state.viewAccount);
  const [activeMenu, setActiveMenu] = useState("about"); // Tracks active menu item
  const [phoneActivMenu, setPhoneActivMenu] = useState(""); // Tracks active menu item for phone
  const [isEdit, setEdit] = useState(false);

  if (activeMenu === "settings") {
    window.location.href = "https://www.trackofit.com/privacypolicy.html";
  }

  if (phoneActivMenu === "settings") {
    window.location.href = "https://www.trackofit.com/privacypolicy.html";
  }

  return (
    <>
      <div className="sm:hidden  bg-[var(--light-grey)]">
        <EditSection
          activeMenu={activeMenu}
          setActiveMenu={setActiveMenu}
          isEdit={isEdit}
          setEdit={setEdit}
          data={data}
          type="account"
          isCoach=""
        />
      </div>

      <div className=" sm:!block" style={{ display: "none" }}>
        <PhoneEditCoach
          phoneActivMenu={phoneActivMenu}
          setPhoneActivMenu={setPhoneActivMenu}
          isEdit={isEdit}
          setEdit={setEdit}
          data={data}
          type="account"
          isCoach=""
        />
      </div>
    </>
    // <div className="flex flex-col width_70_div min-h-screen !mt-0 !pb-0 pt-[4rem]">
    //   <CoverPhoto />
    //   <div className="flex flex-grow h-full">
    //     {/* Left Submenu */}
    //     <SubMenu activeMenu={activeMenu} setActiveMenu={setActiveMenu} />

    //     {/* Right side content */}
    //     <div className="w-full">
    //       <EditHeader isEdit={isEdit} setEdit={setEdit} />
    //       {activeMenu === "about" && (
    //         <AccountAbout isEdit={isEdit} setEdit={setEdit} type="account" />
    //       )}
    //       {activeMenu === "video" && <AccountVideo />}
    //       {activeMenu === "certificates" && (
    //         <AccountCertificates isEdit={isEdit} setEdit={setEdit} />
    //       )}
    //       {activeMenu === "youTrain" && (
    //         <YouTrainViewAccount isEdit={isEdit} setEdit={setEdit} />
    //       )}
    //       {activeMenu === "billing" && navigate("/mybilling")}
    //     </div>
    //   </div>
    // </div>
  );
}

export default CoachAccount;
