import { useEffect, useRef, useState } from "react";
import { sendOtp } from "api/Signup";
import { verifyOtp } from "api/Signup";
import { Button } from "rsuite";
import ErrorPopup from "components/popups/ErrorPopup";
// import "rsuite/dist/styles/rsuite-default.min.css";
import "components/popups/popup.css";

import OtpInput from "components/inputs/OtpInputs";

const SendOtp = ({
  length = 6,
  onOtpSubmit = () => {},
  setDone,
  email,
  setBlur,
  setDisable,
  setSendOtp,
  setCount,
  count,
  setOtpVerify,
  otpVerify,
}) => {
  const [otp, setOtp] = useState(new Array(length).fill(""));
  const inputRefs = useRef([]);
  const [errorPopup, setErrorPopup] = useState(false); // error popup
  const [errorHeader, setErrorHeader] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [load, setLoad] = useState(false);
  const [invalidOtp, setInvalidOtp] = useState(false);
  const [resendOTP, setResendOTP] = useState(false);
  const [counter, setCounter] = useState(120);
  const otpNo = otp[0] + otp[1] + otp[2] + otp[3] + otp[4] + otp[5];

  useEffect(() => {
    setTimeout(() => setCounter(counter - 1), 1000);
    if (counter === 0) {
      setResendOTP(true);
      setCounter(120);
    }
  }, [counter]);

  async function handleResendOTP() {
    if (count > 3) {
      setResendOTP(true);
      setErrorHeader("Error");
      setErrorMessage("Try after some time");
      setErrorPopup(true);
      setDone(true);
      setBlur(false);
    } else {
      await sendOtp(email);
      setResendOTP(false);
      setCount(count + 1);
    }
  }

  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  async function handleSubmit(e) {
    e.preventDefault();
    if (load === true) {
      return;
    }
    setLoad(true);
    const res = await verifyOtp(email, otpNo);
    if (res === true) {
      setLoad(false);
      setOtpVerify(true);
      setInvalidOtp(false);
      setDone(true);
      setBlur(false);
      setDisable(false);
    } else {
      setLoad(false);
      setOtpVerify(false);
      setInvalidOtp(true);
    }
  }

  return (
    <>
      {errorPopup && (
        <>
          <div className="blur"></div>
          <ErrorPopup
            message={{
              heading: errorHeader,
              message: errorMessage,
            }}
            setErrorPopup={setErrorPopup}
          />
        </>
      )}
      {!otpVerify ? (
        <div className="fixed z-[150] top-[25%] right-0 min-h-[100px] w-[300px] bg-[var(--white)] shadow-sm">
          <form>
            <div className="text-center w-[90%] mx-auto">
              <span
                className="popup_cancel_btn"
                onClick={() => {
                  setSendOtp(false);
                  setBlur(false);
                }}
              >
                Cancel
              </span>
              <h3 className="forgotPassword_heading">Enter OTP</h3>
              <p className="form_guide_text text-center w-full !m-0">
                A One-time-password has been sent to your registered email
                address. Check your Inbox, including Spam/Junk.
              </p>
              <div className="flex justify-center">
                {otp.map((value, index) => {
                  return (
                    <OtpInput
                      otp={otp}
                      setOtp={setOtp}
                      index={index}
                      value={value}
                      onOtpSubmit={onOtpSubmit}
                      inputRefs={inputRefs}
                      length={length}
                    />
                  );
                })}
              </div>
              <div className=" flex justify-between">
                <p className="form_guide_text  w-auto !m-0">
                  Didn’t receive OTP?
                  {resendOTP ? (
                    <span onClick={handleResendOTP}>Resend</span>
                  ) : (
                    <span> {counter}</span>
                  )}
                </p>
                {invalidOtp && (
                  <span className="text-[0.7rem] text-[var(--red)]">
                    Invalid OTP
                  </span>
                )}
              </div>

              <div className="text-center py-[.8rem]">
                {load ? (
                  <Button
                    loading
                    className="submit_box bg-[var(--orange)]"
                  ></Button>
                ) : (
                  <button className="orange_btn" onClick={handleSubmit}>
                    Done
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};
export default SendOtp;
