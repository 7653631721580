import React, { useState, useEffect, useContext } from "react";
import { Button } from "rsuite";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import { SubmitData } from "api/Signup";
import playStore from "assets/img/play-store.png";
import appStore from "assets/img/apple-store.png";
import { getCoachingPlans } from "api/CoachingPlansController";
// import "rsuite/dist/styles/rsuite-default.min.css";
import { logout } from "api/Signin";
import { getlocalStorageData } from "hooks/basic";
import { AppProps } from "utility/constants/useContext";

function PopUpLoginP(props) {
  const { isForgotPassword, setForgotPassword, setSignUp, isSignUp } = props;
  const navigate = useNavigate();
  const localStorageData = getlocalStorageData();
  const AppData = useContext(AppProps);
  const [showLi, setShowLi] = useState(true);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isViewAccount, setViewAccount] = useState(false);
  const [load, setLoad] = useState(false);
  const [fillEmail, setFillEmail] = useState(""); // gives any error message when email is empty
  const [fillPassword, setFillPassword] = useState(""); // gives any error message when password is empty
  const [isErrorMessage, setErrorMessage] = useState("");

  // Hide Input
  const [inputE, setInputE] = useState(false);
  const [inputP, setInputP] = useState(false);

  // Making invalid email and password error display non
  useEffect(() => {
    if (email === "" || password === "") {
      setErrorMessage("");
    }
  }, [email, password]);

  function handleClick() {
    AppData.setOpenP(false);
    setTimeout(() => {
      AppData.setSignInOpen(!AppData.isSignInOpen);
    }, 500);
  }

  function hanleClose() {
    AppData.setOpenP(false);
    AppData.setSignInOpen(false);
    setViewAccount(false);
  }

  async function handleSubmit(e) {
    if (load === true) {
      return;
    }
    e.preventDefault();
    if (email === "" || password === "") {
      if (email === "") {
        setFillEmail("Fill up email");
      } else if (password === "") {
        setFillPassword("Fill up password");
      }
    } else {
      setLoad(true);
      const req = await SubmitData(email, password, AppData.RememberMe, e);
      if (req === true) {
        setLoad(false);
        AppData.setSignInOpen(false);
        AppData.setIsSignedIn(true);
      } else {
        setLoad(false);
        setErrorMessage("Please enter a valid Email Address and Password.");
      }
    }
  }

  function handleClickScrollCoach() {
    const element = document.getElementById("coach");
    if (location.pathname === "/athlete") {
      navigate("/");
      setTimeout(() => {
        const homeElement = document.getElementById("coach");
        homeElement.scrollIntoView({ behavior: "smooth" });
      }, 100);
    } else if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    AppData.setOpenP(false);
  }

  function handleClickScrollAbout() {
    const element = document.getElementById("about");
    // If use is  on the athlete page, navigate to home then scroll
    if (location.pathname === "/athlete") {
      navigate("/");
      setTimeout(() => {
        const homeElement = document.getElementById("about");
        if (homeElement) {
          homeElement.scrollIntoView({ behavior: "smooth" });
        }
      }, 100); // Delay to wait for the navigation to complete
    } else if (element) {
      // If already on the home page, just scroll smoothly to the "about" section
      element.scrollIntoView({ behavior: "smooth" });
    }
    AppData.setOpenP(false);
  }

  function handleClickScrollSupport() {
    // navigate("/viewCoaches");
    const element = document.getElementById("footerP");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    AppData.setOpenP(false);
  }

  function handleClickScrollAthele() {
    const element = document.getElementById("athlete");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    AppData.setOpenP(false);
  }

  const location = useLocation();
  useEffect(() => {
    // Check if the user is on the specified URL
    if (
      location.pathname === "/mybilling" ||
      location.pathname === "/viewaccount" ||
      location.pathname === "/viewcoachform" ||
      location.pathname === "/mycoachingplans" ||
      location.pathname === "/calendar" ||
      location.pathname === "/coachform" ||
      location.pathname === "/editcoachform" ||
      location.pathname === "/coachdetails"
    ) {
      setShowLi(false);
      AppData.setOpenP(false);
    } else {
      setShowLi(true);
    }
  }, [location]);

  const [isCoachingPlan, setCoachingPlan] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getCoachingPlans();

        if (res.data.coaching_plans) {
          if (res.data.coaching_plans.length > 0) {
            setCoachingPlan(true);
          } else {
            setCoachingPlan(false);
          }
        } else {
          setCoachingPlan(false);
        }
      } catch (error) {
        console.error(error);
      }
    };
    if (localStorageData.role === "Coach") {
      fetchData();
    }
  }, []);

  return (
    <>
      <div
        className={`phone_menu_bar ${AppData.isOpenP ? "visible_menu" : ""}`}
      >
        <div className=" flex justify-between text-[var(--orange)] text-center h-[5vh] m-4">
          {AppData.isSignedIn && !isViewAccount ? (
            <div className="phone_Profile_logo">
              <span>{AppData.userInitial}</span>
            </div>
          ) : (
            <div></div>
          )}
          <svg
            onClick={hanleClose}
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            fill="currentColor"
            className="bi bi-x-circle"
            viewBox="0 0 16 16"
          >
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
          </svg>
        </div>
        {/* --------------------- UL -------------------- */}
        <div className="flex flex-col justify-between">
          <div>
            <ul className="m-0 px-4 h-[50vh]">
              {showLi && (
                <>
                  <li>
                    <Link
                      className="text-white hover:text-white "
                      to="/becomeacoach"
                    >
                      Become a Coach
                    </Link>
                  </li>
                  <li>
                    <Link onClick={() => AppData.setOpenP(false)} to="/athlete">
                      For an Athlete
                    </Link>
                  </li>
                  <li onClick={handleClickScrollAbout}>About us</li>
                  <li onClick={handleClickScrollSupport}>Support</li>
                  {!AppData.isSignedIn && (
                    <li className="!text-[var(--orange)]" onClick={handleClick}>
                      Sign In
                    </li>
                  )}
                </>
              )}

              {AppData.isSignedIn && (
                <>
                  {localStorageData.role === "Athlete" && (
                    <li>
                      <Link className="text-[var(--white)]" to="/calendar">
                        Training Calendar
                      </Link>
                    </li>
                  )}
                  <li>
                    <Link className="text-[var(--white)]" to="/viewaccount">
                      View Account
                    </Link>
                  </li>
                  <li>
                    <Link className="text-[var(--white)]" to="/mybilling">
                      My Billing
                    </Link>
                  </li>
                  {localStorageData.role === "Coach" && (
                    <li>
                      <div className="flex items-center">
                        <Link to="/mycoachingplans">My Coaching Plans</Link>
                        {isCoachingPlan === false && (
                          <>
                            <span className="bg-[var(--red)] w-1 h-1 rounded-lg ml-1 "></span>
                          </>
                        )}
                      </div>
                    </li>
                  )}

                  {AppData.isSwitchBtn && (
                    <li onClick={() => AppData.SwitchAccountBtn()}>
                      Switch Account
                    </li>
                  )}
                  <li onClick={logout}>Logout</li>
                </>
              )}
            </ul>
          </div>

          {/*----------- FOOTER NAV -----------*/}

          <div className="flex flex-col justify-between items-end text-center text-[var(--white)] font-light h-[40vh]">
            {!AppData.isSignedIn && (
              <div className="menu_footer text-right">
                <li>
                  <a href="mailto:support@trackofit.com">Help</a>
                </li>
                <li>
                  <a href="https://www.trackofit.com/privacypolicy.html">
                    Privacy policy
                  </a>
                </li>
                <li>
                  <a href="mailto:support@trackofit.com">Contact us</a>
                </li>
                <li onClick={handleClickScrollAbout}>About</li>
              </div>
            )}

            {/*----------- DOWNLOAD ICONS -----------*/}
            {AppData.isSignedIn ? (
              <div></div>
            ) : (
              <div>
                <h4 className="m-0">Download App</h4>
                <div className="flex_center_div">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.isf.trackofit&pli=1"
                    className=" w-[30%]"
                  >
                    <img src={playStore} alt="playStore" />
                  </a>
                  <a
                    href="https://apps.apple.com/in/app/trackofit/id6469041232"
                    className="w-[22%]"
                  >
                    <img src={appStore} alt="appStore" />
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>

        {AppData.isSignInOpen && (
          <>
            <form
              onSubmit={handleSubmit}
              className={`login  ${
                AppData.isSignInOpen ? "visible_login" : ""
              }`}
            >
              <div className=" flex justify-between items-center px-[5%] mt-4 text-[var(--orange)]">
                <h2 className="text-[1.5rem]">SIGN IN</h2>
                <svg
                  onClick={hanleClose}
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  fill="currentColor"
                  className="bi bi-x-circle"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                </svg>
              </div>

              {/* ------------ FORM DIV ----------- */}
              <div>
                <input
                  onMouseEnter={() => setInputE(!inputE)}
                  onMouseOut={() => setInputE(false)}
                  type="email"
                  name="email"
                  placeholder={`  ${inputE ? "" : "Email"}`}
                  autoComplete="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {fillEmail && <div className="error_message">{fillEmail}</div>}
                <input
                  onMouseEnter={() => setInputP(!inputP)}
                  onMouseOut={() => setInputP(false)}
                  type="password"
                  placeholder={`  ${inputP ? "" : "Password"}`}
                  minLength="8"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              {fillPassword && (
                <div className="error_message">{fillPassword}</div>
              )}
              <div className="error_message">{isErrorMessage}</div>
              <div className="checkBox margin_bottom_8">
                <div>
                  <input
                    type="checkbox"
                    onChange={() => AppData.setRememberMe(!AppData.RememberMe)}
                  />
                  <span>Remember me</span>
                </div>
                <div>
                  <a
                    href="#"
                    className="text-[var(--link)]"
                    onClick={(e) => {
                      e.preventDefault();
                      setForgotPassword(!isForgotPassword);
                      AppData.setSignInOpen(false);
                    }}
                  >
                    Forgot password?
                  </a>
                </div>
              </div>
              <div className="signup_btn w-[90%] mx-auto text-left text-[0.8rem]">
                <p className="text-[var(--grey)] cursor-pointer">
                  Don’t have a account?{" "}
                  <span
                    className="text-[var(--link)]"
                    onClick={() => {
                      setSignUp(!isSignUp);
                      AppData.setSignInOpen(false);
                    }}
                  >
                    Sign up
                  </span>
                </p>
              </div>
              <div className="submit_box padding_top_8">
                {load ? (
                  <Button
                    loading
                    className="orange_btn"
                    style={{
                      backgroundColor: "#fdc027",
                      padding: "1.1rem 3rem",
                    }}
                  ></Button>
                ) : (
                  <button className="orange_btn" type="submit">
                    SIGN UP
                  </button>
                )}
              </div>
            </form>
          </>
        )}
      </div>
    </>
  );
}

export default PopUpLoginP;
