import React from "react";
import Logo from "components/logo/Logo";
import playStore from "assets/img/play-store.png";
import appStore from "assets/img/apple-store.png";

export default function DesktopFooter() {
  function handleClickScrollCoach() {
    const element = document.getElementById("coach");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }
  function handleClickScrollAbout() {
    const element = document.getElementById("about");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }
  return (
    <div
      className="footer_section flex justify-around bg-[var(--blue)] items-start py-[2rem] "
      id="footer"
    >
      <div className="flex flex-col justify-center items-center ">
        <Logo />
        <div className="flex items-center">
          <a
            href="https://play.google.com/store/apps/details?id=com.isf.trackofit&pli=1"
            className="w-[5rem] "
          >
            <img className="w-screen" src={playStore} alt="playStore" />
          </a>
          <a
            href="https://apps.apple.com/in/app/trackofit/id6469041232"
            className="w-[4rem]"
          >
            <img className="w-screen" src={appStore} alt="appStore" />
          </a>
        </div>
      </div>
      <div className="footer_athletes_div flex flex-col mb-[0.5rem] ">
        <h5>For An Athletes</h5>
        <a>Sign Up</a>
      </div>
      <div className="footer_coaches_div">
        <h5>Become a coach</h5>
        <a onClick={handleClickScrollCoach}>Sign Up</a>
      </div>
      <div className="footer_support_div flex flex-col mb-[0.5rem]">
        <h5>Support</h5>
        <a href="mailto:support@trackofit.com">Help</a>
        <a href="mailto:support@trackofit.com">Contact Us</a>
        <a href="https://www.trackofit.com/privacypolicy.html">
          Privacy Policy
        </a>
      </div>
      <div className="footer_company_div flex flex-col mb-[0.5rem] ">
        <h5>Company</h5>
        <a onClick={handleClickScrollAbout}>About</a>
      </div>
    </div>
  );
}
