import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addViewAccountData } from "state/ViewAccountState";
import { updateForm } from "state/SetCoachFormData";

import Cancel from "assets/icons/cancel.svg";

function AthleteLevelSelect(props) {
  const { data, type } = props;
  const dispatch = useDispatch();

  const addDataTo = type === "account" ? addViewAccountData : updateForm;

  const [selectedLevels, setSelectedLevels] = useState([]);

  // Prefill athlete level values from Redux
  useEffect(() => {
    if (data.athlete_level) {
      setSelectedLevels(data.athlete_level.split(", ")); // Convert string to array
    }
  }, [data]);

  // Filter out selected levels from options
  const allLevels = ["Beginner", "Intermediate", "Professional"];
  const filteredLevels = allLevels.filter(
    (level) => !selectedLevels.includes(level)
  );

  // Handle adding new athlete level
  const handleLevelChange = (event) => {
    const value = event.target.value;
    if (value && !selectedLevels.includes(value)) {
      const updatedLevels = [...selectedLevels, value];
      setSelectedLevels(updatedLevels); // Update local state

      // Dispatch updated athlete_level to Redux
      dispatch(addDataTo({ athlete_level: updatedLevels.join(", ") }));
    }
  };

  // Handle deleting a level
  const handleDeleteLevel = (levelToDelete) => {
    const updatedLevels = selectedLevels.filter(
      (level) => level !== levelToDelete
    );
    setSelectedLevels(updatedLevels); // Update local state

    // Dispatch updated athlete_level to Redux
    dispatch(addDataTo({ athlete_level: updatedLevels.join(", ") }));
  };

  return (
    <div className="athlete_level_select mb-4 px-1">
      <p className="label_tag">Athlete Level</p>
      <div className="flex flex-wrap">
        {selectedLevels.map((level, index) => (
          <div
            key={index}
            className="flex justify-around items-center bg-[#EDEDEB] text-[var(--light-blue)] px-1 my-1 mr-2"
          >
            <span className="pr-1">{level}</span>
            <img
              src={Cancel}
              className="h-2 w-2"
              alt="cancel btn"
              onClick={() => handleDeleteLevel(level)}
            />
          </div>
        ))}
      </div>
      <select
        className="p-2 font-normal text-base border-2 border-[var(--light-grey)] bg-white text-[var(--light-blue)] w-4/5 appearance-none outline-none"
        onChange={handleLevelChange}
        value="" // Reset dropdown to show placeholder
      >
        <option value="">Select Athlete Level</option>
        {filteredLevels.map((level, index) => (
          <option key={index} value={level}>
            {level}
          </option>
        ))}
      </select>
    </div>
  );
}

export default AthleteLevelSelect;
