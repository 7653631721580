import React from "react";

function PasswordInput(props) {
  const { password, setPassword, showPassword, validatePassword, isDisable } =
    props;
  return (
    <>
      <input
        className="form_inputs"
        value={password}
        type={showPassword ? "text" : "password"}
        placeholder="Enter password"
        onChange={(e) => {
          setPassword(e.target.value);
          validatePassword(e.target.value);
        }}
        disabled={isDisable}
      />
    </>
  );
}

export default PasswordInput;
