import React from "react";
import AppleStore from "assets/img/apple-store.png";
import GooglePlay from "assets/img/play-store2.png";
import logo from "assets/img/tofAppIcon 1.png";
import TrainingImg from "assets/img/training_photo.jpg";

function AboutUs() {
  return (
    <>
      <div
        className=" py-20 flex flex-col justify-center items-center"
        id="about"
      >
        <div className="flex flex-col justify-evenly items-center pb-20">
          <h2 className="text-[var(--blue)] font-normal text-center mb-4">
            Welcome To, <span className="font-bold">TRACKOFIT</span>
          </h2>
          <p className=" w-[70%] text-[1.2rem] text-[var(--light-blue)] text-center mb-4">
            Your ultimate destination for achieving your training goals with
            excellence for activities like running, biking, swimming, duathlon,
            and triathlon.
          </p>
          <div className="flex flex-col items-center">
            <div className=" flex ">
              <a href="https://apps.apple.com/in/app/trackofit/id6469041232">
                <img className=" w-[6rem]" src={AppleStore} alt="" />
              </a>

              <a href="https://play.google.com/store/apps/details?id=com.isf.trackofit&pli=1">
                <img className="w-[7rem] ml-[1rem]" src={GooglePlay} alt="" />
              </a>
              <img className="logo_phone" src={logo} alt="" />
            </div>
            <p className="text-[var(--blue)] font-medium">
              Download TRACKOFIT App
            </p>
          </div>
        </div>

        <div className="relative w-full  about_bg overflow-hidden">
          <img
            className="absolute top-0 right-0 h-full"
            src={TrainingImg}
            alt=""
          />
          <div className=" absolute top-0 left-0 about_bg_color">
            <ul className="flex flex-col   list-disc w-[80%] mx-auto my-0">
              <li className="text-[1.2rem] text-white ">
                Whether you're looking to boost your performance or inspire
                others as a coach, you'll find support and motivation in our
                training community.
              </li>
              <li className="text-[1.2rem] text-white">
                Train anytime and anywhere, using our platform on your desktop
                or mobile device.
              </li>
              <li className="text-[1.2rem] text-white">
                No matter your experience level, we can help you enhance your
                performance and reach your fitness goals.
              </li>
            </ul>

            {/* <div className="flex items-start mb-4 ]">
              <span className="w-[1rem] h-[1rem] rounded-full bg-white mr-4 mt-2"></span>
              <p className="text-[1.2rem] text-white">
                Whether you're looking to boost your performance or inspire
                others as a coach, you'll find support and motivation in our
                training community.
              </p>
            </div>

            <div className="flex items-start mb-4">
              <span className="w-[10px] h-[10px] rounded-full bg-white mr-4 mt-2"></span>
              <p className="text-[1.2rem] text-white">
                Train anytime and anywhere, using our platform on your desktop
                or mobile device.
              </p>
            </div>

            <div className="flex items-start">
              <span className="w-[10px] h-[10px] rounded-full bg-white mr-4 mt-2"></span>
              <p className="text-[1.2rem] text-white">
                No matter your experience level, we can help you enhance your
                performance and reach your fitness goals.
              </p>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutUs;
