import React from "react";

function CoachSteps() {
  return (
    <div className="flex flex-col mb-4  text-[1.2rem] text-[var(--light-blue)]  lg:mb-4">
      {/* <img src={img9} alt="" /> */}
      <div className="flex items-start pb-2">
        <div className="flex flex-col items-center mr-4">
          <span className="flex justify-center items-center w-6 h-6 bg-[#04b801] rounded-full">
            <span className="text-[.7rem]  text-white">1</span>
          </span>
          <span className="w-[.15rem] h-6 bg-[var(--blue)] mt-2"></span>
        </div>
        <p>Submit the Coach Application Form. </p>
      </div>

      <div className="flex items-start pb-2">
        <div className="flex flex-col items-center mr-4">
          <span className="flex justify-center items-center w-6 h-6 bg-[#04b801] rounded-full">
            <span className="text-[.7rem] text-white ">2</span>
          </span>
          <span className="w-[.15rem] h-6 bg-[var(--blue)] mt-2"></span>
        </div>
        <p>
          We will review it and let you know if additional information is
          needed.
        </p>
      </div>

      <div className="flex items-start pb-2">
        <div className="flex flex-col items-center mr-4">
          <span className="flex justify-center items-center w-6 h-6 bg-[#04b801] rounded-full">
            <span className="text-[.7rem] text-white ">3</span>
          </span>
          <span className="w-[.15rem] h-6 bg-[var(--blue)] mt-2"></span>
        </div>
        <p>Please allow 7-9 business days for processing.</p>
      </div>

      <div className="flex items-start pb-4">
        <div className=" flex flex-col items-center mr-4 justify-center">
          <div className="dot_div">
            <span className="overlay_dot flex justify-center items-center">
              <span className="text-white text-[.7rem]  ">4</span>
            </span>
          </div>
        </div>
        <p>Once accepted, you can add coaching plans and onboard athletes.</p>
      </div>
    </div>
  );
}

export default CoachSteps;
