import React, { useState, useEffect, useContext } from "react";
import PhoneFormStep3 from "./PhoneFormStep3";
import RightArrow from "assets/icons/right-arrow.png";
import AddCertificate from "../coachFormComponents/AddCertificate";
import { useNavigate } from "react-router-dom";
import { SubmitCoachFormData } from "utility/constants/useContext";
import BackArrow from "assets/icons/back.png";
import CoachForm from "../CoachForm";
import Nav from "components/navbar/Nav";
import PhoneNavBar from "components/navbar/PhoneNavBar";
import PopUpLoginP from "components/signin/PhonePopUpLogin";

function PhoneFormStep2() {
  const [nextClicked, setNextClicked] = useState(false);
  const navigate = useNavigate();
  const [backClicked, setBackClicked] = useState(false);
  const [popper, setPopper] = useState(false);
  const [certificateVaildation, setCertificateVaildation] = useState(false);

  const Props = useContext(SubmitCoachFormData);

  const handleBackClick = () => {
    setBackClicked(true);
  };
  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const validation = Props.validateCertificates();
    setCertificateVaildation(validation);
  }, [Props.certificates]);

  return (
    <>
      {backClicked ? (
        <CoachForm />
      ) : (
        <>
          {nextClicked ? (
            <PhoneFormStep3 />
          ) : (
            <>
              <div className="coach_form phone_form_2">
                <div className="form_section">
                  <form action="" method="post" className="mt-16 m-0">
                    <div className="phone_form_step2 p-4">
                      <AddCertificate />

                      <div className="Phone_next_steps_btn">
                        {popper && (
                          <p className="absolute bottom-12 right-4 text-[var(--red)]">
                            Fill up the fileds
                          </p>
                        )}
                        <p
                          className="statusBarPhone2 "
                          onClick={() => {
                            if (certificateVaildation === true) {
                              setNextClicked(true);
                              setPopper(false);
                            } else {
                              setNextClicked(false);
                              setPopper(true);
                            }
                          }}
                        >
                          <div className="flex justify-between">
                            <p
                              className="mr-[10px] mt-[10px] cancelButton"
                              onClick={handleBackClick}
                            >
                              <div className=" flex items-center">
                                <img
                                  src={BackArrow}
                                  alt=""
                                  className="next_img mr-1 ml-[-3px]"
                                />
                                Back
                              </div>
                            </p>
                            <div className="coach_cancel_Div flex">
                              <p
                                className="mr-[10px] mt-[10px] cancelButton"
                                onClick={() => {
                                  navigate("/");
                                }}
                              >
                                Cancel
                              </p>
                              <div className="nextButton">
                                Next{" "}
                                <img
                                  src={RightArrow}
                                  alt=""
                                  className="next_img mt-[6px]"
                                />
                              </div>
                            </div>
                          </div>
                        </p>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}

export default PhoneFormStep2;
