import React, { useContext, useEffect, useMemo, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { Home } from "pages/homePage/Home";
import { SwitchAccount } from "api/CoachFormController";
import { SetDataInlocalStorage } from "api/CoachFormController";
import { logout } from "api/Signin";
import { getUserDetails } from "api/UserAccountController";
import Billing from "pages/bilingPage/BillingPage";
import CoachForm from "pages/coachForm/CoachForm";
import ViewAccount from "pages/accountPage/ViewAccount";
import ViewCoachForm from "pages/coachForm/viewCoachForm/ViewCoachForm";
import MyCoachingPlans from "pages/coachingPlans/MyCoachingPlans";
import EditCoachForm from "pages/coachForm/editCoachForm/EditCoachForm";
import { AppProps } from "utility/constants/useContext";
import { useDispatch, useSelector } from "react-redux";
import { UserData } from "state/ViewAccountState";
import "./mediaQuery.css";
import { getApis } from "hooks/api";
import { API_URL_CONSTANTS } from "utility/constants/apiUrlConstants";
import Athele from "pages/homePage/Athlete/Athlete";
import ViewCoaches from "pages/viewCoaches/ViewCoaches";
import CoachDetails from "pages/viewCoaches/viewCoachComponents/CoachDetails";
import NotFound from "pages/notfound404/NotFound";
import TrainingCalendar from "pages/calendar/TrainingCalendar";
import Nav from "components/navbar/Nav";
import PhoneNavBar from "components/navbar/PhoneNavBar";
import PopUpLoginP from "components/signin/PhonePopUpLogin";
import { clearLocalStorage } from "hooks/basic";
import BecomeCoach from "pages/homePage/coach/BecomeCoach";
import PopUpLogin from "components/signin/PopUpLogin";
import SignUpPopUp from "components/signup/SignUpPopUp";
import FooterSection from "components/footer/FooterSection";
function App() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.viewAccount);

  //check if clicked  SIGN UP button (sign up popup will come)
  const [isSignUp, setSignUp] = useState(false);

  // FORGET PASSWORD
  const [isForgotPassword, setForgotPassword] = useState(false);

  // coach form submit
  const [coachFormSubmit, setCoachFormSubmit] = useState(false);

  // useState for Laptop sign in popup open
  const [isOpen, setOpen] = useState(false);

  // useState for Phone navbar open
  const [isOpenP, setOpenP] = useState(false);

  // sign in popup for phone (signin slider)
  const [isSignInOpen, setSignInOpen] = useState(false);

  // Checking the token in local storage
  const [isSignedIn, setIsSignedIn] = useState(null);

  // user initial
  const [userInitial, setUserInitial] = useState("");

  // view account get data
  // const [getData, setData] = useState([]);

  // Switch account display or not
  const [isSwitchBtn, setSwitchBtn] = useState(false);

  // coach signup status =>  Pending Verification, Verified ect..
  const [coachSignUpStatus, setCoachSignupStatus] = useState("");

  const [coachStatusMessage, setCoachStatusMessage] = useState("");

  //REMEMBER ME
  const [RememberMe, setRememberMe] = useState(false);

  //set refId
  const [refId, setRefId] = useState("");

  // when user close the tab => logout api is called
  useEffect(() => {
    const handleTabClose = async (event) => {
      event.preventDefault();
      if (localStorage.length === 0) {
        try {
          // Call the logout API
          await logout();
        } catch (error) {
          console.error("Error logging out:", error);
        }
      }

      // Add the event listener for tab close
      window.addEventListener("beforeunload", handleTabClose);

      // Cleanup the event listener when component unmounts
      return () => {
        window.removeEventListener("beforeunload", handleTabClose);
      };
    };
  }, []);

  // check if user is signed in or not
  useEffect(() => {
    const checkSignedStatus = () => {
      const token =
        localStorage.getItem("token") ?? sessionStorage.getItem("token");

      if (token) {
        setIsSignedIn(true);
      } else {
        setIsSignedIn(false);
      }
    };

    checkSignedStatus();
  }, []);

  // user initial logic
  const userName =
    localStorage.getItem("name") ?? sessionStorage.getItem("name");
  useEffect(() => {
    if (userName === null) {
      return;
    } else {
      const ArryName = userName.split(" ");
      const filteredArray = ArryName.filter((str) => str !== "");

      if (filteredArray.length === 1) {
        setUserInitial(filteredArray[0].charAt(0).toUpperCase());
      } else if (filteredArray.length === 2) {
        setUserInitial(
          (
            filteredArray[0].charAt(0) + filteredArray[1].charAt(0)
          ).toUpperCase()
        );
      } else {
        setUserInitial(
          (
            filteredArray[0].charAt(0) + filteredArray.reverse()[0].charAt(0)
          ).toUpperCase()
        );
      }
    }
  }, [isOpen, isOpenP, isSignedIn, userName, data]);

  // logic for Switch account btn
  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const res = await getApis(API_URL_CONSTANTS.GET_USER_DETAILS);
        dispatch(UserData({ viewAccountData: res.data }));

        if (res.data.profiles.length > 0) {
          // ref_id = res.data.profiles[0].ref;
          setRefId(res.data.profiles[0].ref);

          setSwitchBtn(true);
        } else {
          setSwitchBtn(false);
        }
      } catch (error) {}
    };
    fetchProfileData();
  }, [isSignedIn]);

  // API call for Switch account
  async function SwitchAccountBtn() {
    try {
      const getRef = await getUserDetails();
      if (getRef.data.profiles.length > 0) {
        let ref_id = getRef.data.profiles[0].ref;

        const res = await SwitchAccount(ref_id);

        if (res) {
          clearLocalStorage();
          SetDataInlocalStorage(res, RememberMe);
        }
      }
    } catch (error) {}
  }

  const AppPropsData = {
    isSignUp,
    setSignUp,
    isForgotPassword,
    setForgotPassword,
    isOpen,
    setOpen,
    isOpenP,
    setOpenP,
    isSignInOpen,
    setSignInOpen,
    isSignedIn,
    setIsSignedIn,
    userInitial,
    setUserInitial,
    isSwitchBtn,
    setSwitchBtn,
    coachSignUpStatus,
    setCoachSignupStatus,
    RememberMe,
    setRememberMe,
    SwitchAccountBtn,
    setCoachFormSubmit,
    coachFormSubmit,
    coachStatusMessage,
    setCoachStatusMessage,
  };

  return (
    <>
      <AppProps.Provider value={AppPropsData}>
        <Nav setSignUp={setSignUp} isSignUp={isSignUp} />
        <PhoneNavBar />
        <PopUpLogin isSignUp={isSignUp} setSignUp={setSignUp} />
        <SignUpPopUp setSignUp={setSignUp} isSignUp={isSignUp} />
        <PopUpLoginP
          isForgotPassword={isForgotPassword}
          setForgotPassword={setForgotPassword}
          isSignUp={isSignUp}
          setSignUp={setSignUp}
        />
        <Routes>
          <Route element={<Home />} path="/"></Route>

          <Route element={<Billing />} path="/mybilling"></Route>

          <Route element={<Athele />} path="/Athlete"></Route>

          <Route element={<ViewCoaches />} path="/viewCoaches"></Route>

          <Route element={<BecomeCoach />} path="/becomeacoach"></Route>

          {isSignedIn === false ? (
            <>
              <Route path="*" element={<NotFound />} />
            </>
          ) : (
            <>
              <Route element={<ViewAccount />} path="/viewaccount" />
              <Route element={<CoachForm />} path="/coachform"></Route>
              <Route element={<ViewCoachForm />} path="/viewcoachform"></Route>
              <Route element={<EditCoachForm />} path="/editcoachform"></Route>
              <Route element={<TrainingCalendar />} path="/calendar"></Route>
              <Route
                element={<MyCoachingPlans />}
                path="/mycoachingplans"
              ></Route>
              <Route element={<CoachDetails />} path="/coachdetails"></Route>
            </>
          )}

          <Route path="*" element={<NotFound />} />
        </Routes>
      </AppProps.Provider>
    </>
  );
}

export default App;
