import React, { useState, useEffect } from "react";
import Certificed from "assets/icons/certified.svg";
import FourStar from "assets/icons/four_star.svg";
import { Link } from "react-router-dom";
import Arows from "assets/icons/Group.svg";
import { getApis } from "hooks/api";
import { API_URL_CONSTANTS } from "utility/constants/apiUrlConstants";

function ViewCoachCardPhone({ selectedActivity }) {
  const [coachList, setCoachList] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [expandedAbout, setExpandedAbout] = useState({}); // State to track expanded about text
  const selectedActivityString =
    selectedActivity && selectedActivity.length > 0
      ? selectedActivity.join(",")
      : null;
  const apiUrl = `${
    API_URL_CONSTANTS.COACHES_LIST
  }?pageNumber=${pageNumber}&pageSize=${pageSize}${
    selectedActivityString ? `&categories=${selectedActivityString}` : ""
  }`;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchCoachList = async () => {
      const res = await getApis(apiUrl);
      if (res && !res.error) {
        if (res.data && res.data.content) {
          setCoachList(res.data.content);
        }
      }
    };
    fetchCoachList();
  }, [apiUrl]);

  const handleToggleAbout = (refId) => {
    setExpandedAbout((prev) => ({
      ...prev,
      [refId]: !prev[refId], // Toggle the specific about text based on refId
    }));
  };

  return (
    <div>
      <div className="coach_card_phone ">
        {coachList.map((data) => {
          const isExpanded = expandedAbout[data.refId]; // Check if the about section is expanded
          const aboutText = isExpanded
            ? data.about
            : data.about && data.about.length > 0
            ? `${data.about.slice(0, 500)}`
            : "No information available."; // Default message if about is null or empty

          return (
            <div
              key={data.refId}
              className="bg-[var(--white)] w-[95%] mx-auto my-4 shadow p-2"
            >
              {/* card body start */}
              <div className="flex flex-col pt-4 px-4">
                <div className="flex items-start py-2">
                  <img
                    className="rounded-[50%] w-[8rem] h-[8rem] object-cover"
                    src={`${API_URL_CONSTANTS.DOMAIN_NAME}${data.img}`}
                    alt=""
                  />
                  <div className="flex flex-col pl-[1rem] justify-center">
                    <h6 className="text-[1.3rem] text-[var(--blue)] font-normal pt-2">
                      {data.name}
                    </h6>
                    <span className="text-[.7rem]">
                      ( Coaching Since {data.coaching_since} )
                    </span>
                    <img className="pt-1 w-[5rem]" src={FourStar} alt="" />
                    <span className="text-[.7rem]">
                      ( Athlete trained {data.athlete_trained} )
                    </span>
                  </div>
                </div>
                <div className="flex flex-col p-2">
                  <div
                    className="text-[1.1rem] text-[var(--light-blue)] leading-7 tracking-wide break-words ql-editor list-disc list-inside"
                    dangerouslySetInnerHTML={{ __html: aboutText }}
                  />
                  {data.about &&
                    data.about.length > 500 && ( // Check if the about text length exceeds 700
                      <div
                        className="cursor-pointer text-[var(--link)]"
                        onClick={() => handleToggleAbout(data.refId)}
                      >
                        {isExpanded ? "Show Less" : "... Show More"}
                      </div>
                    )}
                  <div className="flex pt-[2rem] justify-between">
                    <div>
                      <h6 className="text-[var(--blue)] text-[1.2rem] font-normal pb-2">
                        Coaches :
                      </h6>
                      <ul className="viewCoah_li flex flex-col text-[1.2rem]">
                        {/* {data.coaches?.map((coach, index) => (
                          <li key={index}>
                            {coach.category.charAt(0).toUpperCase() +
                              coach.category.slice(1)}
                            : {coach.value}
                          </li>
                        ))} */}
                        {data.coaches?.map((coach, index) => (
                          <>
                            {coach.value && (
                              <li className=" ml-4" key={index}>
                                {coach.category}: {coach.value}
                              </li>
                            )}
                          </>
                        ))}
                      </ul>
                    </div>
                    <div>
                      <div className="pt-8 ">
                        <p className="text-[var(--blue)] flex text-[1rem] md:grid sm:grid lg:grid xl:grid">
                          To View Profile
                          <div className="flex ">
                            <Link
                              to="/coachdetails"
                              state={data.refId}
                              className="text-[0.9rem] text-[#007c3c] sm:ml-0 md:ml-0 xl:ml-0 mt-[2px]"
                            >
                              Click Here
                            </Link>
                            <img
                              src={Arows}
                              className="w-3 h-3  ml-1 mt-[6px]"
                            ></img>
                          </div>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default ViewCoachCardPhone;
